import { getQuoteTrades } from '~/api'
import {
  FUTURE_TRADE_MAX_COUNT,
  FUTURE_TRADE_UPDATE_INTERVAL
} from '~/config/throttleSetting'
import { useFutureStore } from '~/store/future'
import { useStrategyStore } from '~/store/strategy'
import type { QuoteWS } from '~/types/ws'
import { excludeRepeatArray } from '~/utils/helper'

// 历史成交相关
let cachedOrders = $shallowRef<QuoteWS.TradeInfo[]>([])
let tradeOrders = $shallowRef<QuoteWS.TradeInfo[]>([])
let lastExecute = $ref(0)

function addTradeOrders(orders: QuoteWS.TradeInfo[]) {
  cachedOrders.push(...orders)

  if (Date.now() - lastExecute > FUTURE_TRADE_UPDATE_INTERVAL) {
    lastExecute = Date.now()
    tradeOrders.unshift(...cachedOrders)
    cachedOrders = []
  }

  if (tradeOrders.length > FUTURE_TRADE_MAX_COUNT)
    tradeOrders = tradeOrders.slice(0, FUTURE_TRADE_MAX_COUNT)

  tradeOrders = excludeRepeatArray('v', tradeOrders)
  tradeOrders.sort((a, b) => b.t - a.t) // 时间降序

  triggerRef($$(tradeOrders))
  triggerRef($$(cachedOrders))
}

function clearOrders() {
  cachedOrders = []
  tradeOrders = []
}

export function useFutureTradeData() {
  const futureStore = useFutureStore()
  const tradeDataHandler = (data: QuoteWS.TradeData, isStrategy = false) => {
    const strategyStore = useStrategyStore()

    const { symbolId: futureSymbolId } = $(storeToRefs(futureStore))
    const { symbolId: strategySymbolId } = $(storeToRefs(strategyStore))
    const symbolId = isStrategy ? strategySymbolId : futureSymbolId

    if (data.topic !== 'trade' || data.symbol !== symbolId)
      return

    addTradeOrders(data.data)
  }

  const tradeHttpAction = async (isStrategy = false) => {
    const strategyStore = useStrategyStore()

    const { symbolId: futureSymbolId, exchangeId } = $(storeToRefs(futureStore))
    const { symbolId: strategySymbolId } = $(storeToRefs(strategyStore))
    const symbolId = isStrategy ? strategySymbolId : futureSymbolId

    let hasSymbolChange = false
    const cancelWatch = watch(() => isStrategy ? $$(strategySymbolId) : $$(futureSymbolId), (newVal, oldVal) => {
      if (newVal !== oldVal)
        hasSymbolChange = true
    })

    if (!symbolId || !exchangeId)
      return

    const { res, err } = await getQuoteTrades({
      symbol: `${exchangeId}.${symbolId}`,
      limit: FUTURE_TRADE_MAX_COUNT
    })

    if (err)
      return

    !hasSymbolChange && addTradeOrders(res.data)

    cancelWatch()
  }

  return {
    tradeOrders: $$(tradeOrders),
    tradeDataHandler,
    tradeHttpAction,
    clearOrders
  }
}
