<script setup lang="ts">import { toRef as _toRef, computed as _computed, ref as _ref } from 'vue';

import { Close } from '@element-plus/icons-vue'
import { useConfigStore } from '~/store/config'

const __$temp_1 = (useToggle(false)),
  visible = _toRef(__$temp_1, 0),
  toggleVisible = _toRef(__$temp_1, 1);
const { t } = useI18n()

const configStore = useConfigStore()
const { setLocale, changeUnit } = configStore
const __$temp_2 = (storeToRefs(configStore)),
  curUnit = _toRef(__$temp_2, 'curUnit'),
  webConfig = _toRef(__$temp_2, 'webConfig'),
  unitList = _toRef(__$temp_2, 'unitList');

const lang = (useStorage('lang', 'en-us', localStorage, {
  mergeDefaults: true
}))
const curLocale = _computed(() => {
  return webConfig.value?.supportLanguages.find(
    item => item.lang === lang.value
  )
})

enum TabType {
  LANGUAGE,
  UNIT
}

const curTab = _ref(TabType.LANGUAGE)

const tabs = [
  {

    name: t('语言'),
    value: TabType.LANGUAGE
  },
  {
    name: t('汇率'),
    value: TabType.UNIT
  }
]

defineExpose({
  toggleVisible: toggleVisible.value
})
</script>

<template>
  <el-dialog
    v-model="visible"
    class="future-dialog"
    :show-close="false"
    width="600px"
    :modal="false"
    append-to-body
  >
    <div px-9 py-8>
      <div flex justify-between>
        <Tab
          v-model="curTab"
          :data="tabs"
          tab-class="text-base c-$ex-title hover:!c-$ex-mainTitle !mr-10"
          active-tab-class="!c-$ex-mainTitle !b-$ex-primary text-lg"
        />
        <el-icon :size="24" cursor-pointer @click="toggleVisible(false)">
          <close />
        </el-icon>
      </div>
      <div mt-6>
        <el-row
          v-if="curTab === TabType.LANGUAGE"
          :gutter="20"
        >
          <el-col
            v-for="item in webConfig?.supportLanguages"
            :key="item.lang"
            :span="8"
            mb-4
          >
            <div
              cursor-pointer
              hover:bg="$ex-inputBg"
              rounded-md
              :class="[
                curLocale?.lang === item.lang
                  ? 'bg-$ex-inputBg'
                  : '',
              ]"
              py="2.5"
              px-2
              @click="setLocale(item.lang), toggleVisible(false)"
            >
              {{ item.text }}
            </div>
          </el-col>
        </el-row>

        <el-row
          v-else
          :gutter="20"
        >
          <el-col
            v-for="item in unitList"
            :key="item.lang"
            :span="8"
            mb-4
          >
            <div
              cursor-pointer
              hover:bg="$ex-inputBg"
              rounded-md
              :class="[
                curUnit?.lang === item.lang
                  ? 'bg-$ex-inputBg'
                  : '',
              ]"
              py="2.5"
              pl-2
              @click="changeUnit(item.lang), toggleVisible(false)"
            >
              {{ item.suffix }}
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </el-dialog>
</template>

<style scoped lang="scss">
:deep(.my-dialog) {
  background-color: #0ff !important;
}
</style>
