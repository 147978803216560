import { useExchangeOrderData } from '../composables/exchange/useExchangeOrder'
import { useExchangeBrokerData } from '~/composables/exchange/useExchangeBrokerData'
import { useExchangeSymbol } from '~/composables/exchange/useExchangeSymbol'
import { useExchangeTradeData } from '~/composables/exchange/useExchangeTradeData'
import { useExchangeDepthData } from '~/composables/exchange/useExchangeDepthData'
import { RESOLUTION_MAP } from '~/config/enums'
import type { KlineResolution } from '~/types/enums'
import { KlineTypes } from '~/types/kline'
import { useExchangeTrading } from '~/composables/exchange/useExchangeTrading'

export const useExchangeStore = defineStore(
  'exchange',
  () => {
    let resolution = $ref<KlineResolution>('15')
    const setResolution = (res: KlineResolution) => {
      resolution = res
    }
    const resolutionValue = computed(() => {
      return RESOLUTION_MAP[resolution as KlineResolution]
    })

    let chartStyle = $ref(KlineTypes.SeriesStyle.Candles)
    const setChartStyle = (type: KlineTypes.SeriesStyle) => {
      chartStyle = type
    }

    const [showPriceAlertModal, toggleShowPriceAlertModal] = $(useToggle(false))

    const exchangeSymbol = useExchangeSymbol()
    const exchangeBroker = useExchangeBrokerData()
    const exchangeTradeData = useExchangeTradeData()
    const exchangeDepthData = useExchangeDepthData()
    const exchangeTradingData = useExchangeTrading()
    const exchangeOrderData = useExchangeOrderData(ref(exchangeSymbol.symbolId))

    return {
      resolution: $$(resolution),
      chartStyle: $$(chartStyle),
      showPriceAlertModal: $$(showPriceAlertModal),
      resolutionValue,

      setResolution,
      setChartStyle,
      toggleShowPriceAlertModal,

      ...exchangeSymbol,
      ...exchangeBroker,
      ...exchangeTradeData,
      ...exchangeTradingData,
      ...exchangeDepthData,
      ...exchangeOrderData
    }
  },
  {
    persist: {
      paths: ['localFavoriteSymbolList', 'resolution']
    }
  }
)

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useExchangeStore, import.meta.hot))
