<script setup lang="ts">import { toRef as _toRef } from 'vue';

import { useConfigStore } from '~/store/config'

const checked = ref(false)
const toggle = ref((_: MouseEvent) => { })
let __$temp_1 = (storeToRefs(useConfigStore())),
  isDark = _toRef(__$temp_1, 'useDarkMode');

onMounted(() => {
  checked.value = document.documentElement.classList.contains('dark')
  // @ts-expect-error: Transition API
  const isAppearanceTransition = document.startViewTransition
    && !window.matchMedia('(prefers-reduced-motion: reduce)').matches

  function useAppearance() {
    const classList = document.documentElement.classList

    function toggle(event: MouseEvent) {
      if (!isAppearanceTransition) {
        setClass((isDark.value = !isDark.value))
        return
      }
      const x = event.clientX
      const y = event.clientY
      const endRadius = Math.hypot(
        Math.max(x, innerWidth - x),
        Math.max(y, innerHeight - y)
      )
      // @ts-expect-error: Transition API
      const transition = document.startViewTransition(() => {
        setClass((isDark.value = !isDark.value))
      })

      transition.ready.then(() => {
        const clipPath = [
          // 'polygon(50% 50%, 50% 50%, 50% 50%, 50% 50%)',
          // 'polygon(-50% 50%, 50% -50%, 150% 50%, 50% 150%',
          // 'polygon(0 0, 50% 50%, 100% 100%, 50% 50%)',
          // 'polygon(0 0, 0 100%, 100% 100%, 100% 0)'
          `circle(0px at ${x}px ${y}px)`,
          `circle(${endRadius}px at ${x}px ${y}px)`
        ]
        document.documentElement.animate(
          {
            clipPath: isDark.value ? clipPath : [...clipPath].reverse()
          },
          {
            duration: 500,
            easing: 'ease-in-out',
            pseudoElement: isDark.value ? '::view-transition-new(root)' : '::view-transition-old(root)'
          }
        )
      })
    }
    function setClass(dark: boolean): void {
      const css = document.createElement('style')
      css.appendChild(
        document.createTextNode(
          `:not(.VPSwitchAppearance):not(.VPSwitchAppearance *) {
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -o-transition: none !important;
    -ms-transition: none !important;
    transition: none !important;
  }`
        )
      )

      document.head.appendChild(css)
      checked.value = dark
      classList[dark ? 'add' : 'remove']('dark')
      // @ts-ignore
      const _ = window.getComputedStyle(css).opacity
      document.head.removeChild(css)
    }
    return toggle
  }
  toggle.value = useAppearance()
})
watch(checked, (isNewDark) => {
  isDark.value = isNewDark
})
function toggleDark(e: MouseEvent) {
  toggle.value?.(e)
}
</script>

<template>
  <button
    class="!outline-none" pt="0.8"
    pl-1
    @click="toggleDark"
  >
    <transition name="slide-fade" mode="out-in" :duration="150">
      <div
        v-if="isDark" class="iconfont icon-baitian"
        text="5.5"
        leading-none
      />
      <div
        v-else class="iconfont icon-heiye"
        leading-none
        text="5.5"
      />
    </transition>
  </button>
</template>

<style>
::view-transition-old(root),
::view-transition-new(root) {
  animation: none;
  mix-blend-mode: normal;
}

::view-transition-old(root) {
  z-index: 9999;
}

::view-transition-new(root) {
  z-index: 1;
}

.dark::view-transition-old(root) {
  z-index: 1;
}

.dark::view-transition-new(root) {
  z-index: 9999;
}
</style>
