import type { KlineResolution } from '~/types/enums'

export namespace KlineTypes {
  export enum SeriesStyle {
    Bars = 0,
    Candles = 1,
    Line = 2,
    Area = 3,
    HeikenAshi = 8,
    HollowCandles = 9,
    Renko = 4,
    Kagi = 5,
    PointAndFigure = 6,
    LineBreak = 7
  }

  export type ChartActionId =
    | 'chartProperties'
    | 'compareOrAdd'
    | 'scalesProperties'
    | 'tmzProperties'
    | 'paneObjectTree'
    | 'insertIndicator'
    | 'symbolSearch'
    | 'changeInterval'
    | 'timeScaleReset'
    | 'chartReset'
    | 'seriesHide'
    | 'studyHide'
    | 'lineToggleLock'
    | 'lineHide'
    | 'showLeftAxis'
    | 'showRightAxis'
    | 'scaleSeriesOnly'
    | 'drawingToolbarAction'
    | 'magnetAction'
    | 'stayInDrawingModeAction'
    | 'hideAllMarks'
    | 'showCountdown'
    | 'showSeriesLastValue'
    | 'showSymbolLabelsAction'
    | 'showStudyLastValue'
    | 'showStudyPlotNamesAction'
    | 'undo'
    | 'redo'
    | 'paneRemoveAllStudiesDrawingTools'

  export interface KlineTimeItem {
    text: string
    resolution: KlineResolution
    chartType: SeriesStyle
  }
}
