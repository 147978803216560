<script setup lang="ts">import { toRef as _toRef } from 'vue';

import { Close, Loading } from '@element-plus/icons-vue'
import { useUserStore } from '@/store/user'
import { useFutureStore } from '~/store/future'
import { PageColorType } from '~/types/enums'
import { useConfigStore } from '~/store/config'

const colorTypes = PageColorType

const { t } = useI18n()
const route = useRoute()
// 控制弹框的展示
const [dialogVisible, toggleDialogVisible] = useToggle(false)

const userStore = useUserStore()
const { setUserSetting } = userStore
const __$temp_1 = (storeToRefs(userStore)),
  isLogin = _toRef(__$temp_1, 'isLogin'),
  userSetting = _toRef(__$temp_1, 'userSetting');

const futureStore = useFutureStore()
const { resetLayout, setOrderSetting } = futureStore
const __$temp_2 = (storeToRefs(futureStore)),
  currentOrderSetting = _toRef(__$temp_2, 'currentOrderSetting'),
  symbolId = _toRef(__$temp_2, 'symbolId'),
  positionLineOpenStatus = _toRef(__$temp_2, 'positionLineOpenStatus'),
  delegateLineOpenStatus = _toRef(__$temp_2, 'delegateLineOpenStatus'),
  quickCreateOrderOpenStatus = _toRef(__$temp_2, 'quickCreateOrderOpenStatus');

const configStore = useConfigStore()
const { mergeTheme } = configStore

const __$temp_3 = (useToggle(false)),
  colorChangeLoading = _toRef(__$temp_3, 0),
  toggleColorChangeLoading = _toRef(__$temp_3, 1);
// 修改颜色设置
const changeColorSet = async (val: PageColorType) => {
  if (colorChangeLoading.value)
    return

  toggleColorChangeLoading.value(true)

  await setUserSetting({ up_down: val })
  // await getUserSetting()

  toggleColorChangeLoading.value(false)
  mergeTheme()
}

// 下单弹框确认
const __$temp_4 = (useToggle(false)),
  orderConfirmLoading = _toRef(__$temp_4, 0),
  toggleOrderConfirmLoading = _toRef(__$temp_4, 1);
const orderBeforeChange = async () => {
  toggleOrderConfirmLoading.value(true)
  // 这里进行接口调用，成功resolve 否则reject
  await setOrderSetting({
    symbol_id: symbolId.value,
    is_auto_add_margin: currentOrderSetting.value.orderSetting.isAutoAddMargin,
    is_confirm: !currentOrderSetting.value.orderSetting.isConfirm,
    time_in_force: currentOrderSetting.value.orderSetting.timeInForce
  })
  toggleOrderConfirmLoading.value(false)

  return Promise.reject(new Error('false'))
}

// 反手弹框确认
const __$temp_5 = (useToggle(false)),
  backhandLoading = _toRef(__$temp_5, 0),
  toggleBackhandLoading = _toRef(__$temp_5, 1);
const backhandBeforeChange = async () => {
  toggleBackhandLoading.value(true)
  // 这里进行接口调用，成功resolve 否则reject
  await setUserSetting({
    quickCloseConfirm: !userSetting.value.commonConfig.quickCloseConfirm
  })
  toggleBackhandLoading.value(false)

  return Promise.reject(new Error('false'))
}

// 暴露给父组件的变量
defineExpose({
  toggleDialogVisible
})
</script>

<template>
  <el-drawer
    v-model="dialogVisible"
    title=""
    direction="rtl"
    size="368px"
    :with-header="false"
    lock-scroll
  >
    <div class="user-set-drawer">
      <div
        class="title-row"
        flex
        items-center
        justify-between
        h-15
      >
        <div class="title-text">
          {{ t('偏好设置') }}
        </div>
        <el-icon
          cursor-pointer
          @click="toggleDialogVisible(false)"
        >
          <close />
        </el-icon>
      </div>

      <div
        class="color-set"
        py-2
      >
        <div class="set-title">
          <div>
            {{ t('颜色偏好设置') }}
          </div>
        </div>

        <div
          flex
          flex-col
          text-base
        >
          <div
            flex
            flex-1
            hover:bg="$ex-inputBg"
            :class="userSetting?.commonConfig?.up_down === colorTypes.GREEN_UP ? 'bg-$ex-inputBg c-$ex-primary' : ''"
            py-2
            items-center
            justify-center
            cursor-pointer
            @click="!colorChangeLoading && changeColorSet(colorTypes.GREEN_UP)"
          >
            <el-icon
              v-show="colorChangeLoading"
              class="is-loading mr-2"
            >
              <loading />
            </el-icon>
            <span>
              {{ t('绿涨红跌') }}
            </span>
            <img
              ml-2
              w-5
              h-5
              src="../assets/img/redUp.png"
              alt=""
            >
          </div>
          <div
            flex
            flex-1
            hover:bg="$ex-inputBg"
            :class="userSetting?.commonConfig?.up_down === colorTypes.RED_UP ? 'bg-$ex-inputBg c-$ex-primary' : ''"
            py-2
            items-center
            justify-center
            cursor-pointer
            @click="!colorChangeLoading && changeColorSet(colorTypes.RED_UP)"
          >
            <el-icon
              v-show="colorChangeLoading"
              class="is-loading mr-2"
            >
              <loading />
            </el-icon>
            <span>
              {{ t('红涨绿跌') }}
            </span>
            <img
              ml-2
              w-5
              h-5
              src="../assets/img/greenUp.png"
              alt=""
            >
          </div>
        </div>
      </div>

      <div
        v-if="route.name === 'futures-symbolId'"
        class="confirm-set"
        py-2
        :class="{ 'no-login-confirm-set': !isLogin }"
      >
        <div class="set-title">
          <div>
            {{ t('二次确认') }}
          </div>
        </div>
        <div
          class="each-set"
          flex
          items-center
          justify-between
          py-2
        >
          <div
            class="row-label"
            flex
            items-center
            w-50
          >
            <span>
              {{ t('下单弹窗确认') }}
            </span>
          </div>
          <el-switch
            v-if="currentOrderSetting"
            v-model="currentOrderSetting.orderSetting.isConfirm"
            :loading="orderConfirmLoading"
            size=""
            :before-change="orderBeforeChange"
            :disabled="!isLogin"
          />
        </div>
        <div
          class="each-set"
          flex
          items-center
          justify-between
          py-2
        >
          <div
            class="row-label"
            flex
            items-center
            w-50
          >
            <tooltips-text :content="t('闪电下单将送出市价单')">
              <span>
                {{ t('闪电下单') }}
              </span>
            </tooltips-text>
          </div>
          <el-switch
            v-model="quickCreateOrderOpenStatus"
            size=""
            :disabled="!isLogin"
          />
        </div>
        <div
          class="each-set"
          flex
          items-center
          justify-between
          py-2
        >
          <div
            class="row-label"
            flex
            items-center
            w-50
          >
            <span>
              {{ t('反手弹窗确认') }}
            </span>
          </div>
          <el-switch
            v-if="userSetting"
            v-model="userSetting.commonConfig.quickCloseConfirm"
            :loading="backhandLoading"
            size=""
            :before-change="backhandBeforeChange"
            :disabled="!isLogin"
          />
        </div>
      </div>

      <div
        v-if="route.name === 'futures-symbolId'"
        class="confirm-set"
        py-2
        :class="{ 'no-login-confirm-set': !isLogin }"
      >
        <div
          class="each-set"
          flex
          items-center
          justify-between
          h-50px
        >
          <div
            class="row-label"
            flex
            items-center
          >
            <span>
              {{ t('持仓辅助线') }}
            </span>
          </div>
          <el-switch
            v-model="positionLineOpenStatus"
            size=""
            :disabled="!isLogin"
          />
        </div>
        <div
          class="each-set"
          flex
          items-center
          justify-between
          h-50px
        >
          <div
            class="row-label"
            flex
            items-center
          >
            <span>
              {{ t('委托辅助线') }}
            </span>
          </div>
          <el-switch
            v-model="delegateLineOpenStatus"
            size=""
            :disabled="!isLogin"
          />
        </div>
      </div>
      <el-button
        v-if="route.name === 'futures-symbolId'"
        type="primary"
        size="large"
        link
        mt-4
        @click="resetLayout"
      >
        {{ t('重置布局') }}
      </el-button>
    </div>
  </el-drawer>
</template>

<style lang="scss" scoped>
* {
  white-space: pre-wrap;
}

.title-row {
  border-bottom: 1px solid var(--ex-splitLine);
}

.color-set,
.confirm-set {
  border-bottom: 1px solid var(--ex-splitLine);
}

.title-text {
  color: var(--ex-mainTitle);
  font-weight: 600;
  font-size: 16px;
}

.set-title {
  color: var(--ex-title);
  font-weight: 600;
  font-size: 14px;
  height: 50px;
  display: flex;
  align-items: center;
}

.row-label {
  font-size: 14px;
  color: var(--ex-mainTitle);
  // font-weight: 500;
}

.no-login-confirm-set {

  .set-title,
  .row-label {
    color: #b3bcc9;
  }
}

:deep(.el-radio-group) {
  font-size: 14px;
  color: var(--ex-mainTitle);
  font-weight: 400;
  display: block;

  .each-set {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;

    .el-radio {
      .el-radio__label {
        display: none;
      }
    }

    .el-radio.el-radio--large .el-radio__inner {
      width: 18px;
      height: 18px;
    }
  }
}

.el-button {
  border-radius: 6px;
}
</style>
