<script setup lang="ts">import { computed as _computed, toRef as _toRef } from 'vue';

import QRCode from 'qrcode'
import gsap from 'gsap'
import { useConfigStore } from '~/store/config'
import type { NewIndexConfig } from '~/types'
import type { Nullable } from '~/types/utils'

withDefaults(defineProps<{
  config: Nullable<NewIndexConfig.ConfigItem>
}>(), { config: null, })
const content = _computed(() => {
  return __props.config?.contentList?.[0]?.content
})

const { t } = useI18n()
const configStore = useConfigStore()
const __$temp_1 = (storeToRefs(configStore)),
  currentThemeGetter = _toRef(__$temp_1, 'currentThemeGetter');
const downloadUrlImg = _computed(() => {
  return (url: string) => {
    let downloadUrlDataImg = ''
    // @ts-expect-error
    QRCode.toDataURL(
      url,
      {
        margin: 0,
        width: 124,
        height: 124,
        color: {
          dark: currentThemeGetter.value.mainTitle,
          light: '#FFFFFF00'
        }
      },
      (err: Error, dataUrl: string) => {
        if (err)
          return

        downloadUrlDataImg = dataUrl
      }
    )

    return downloadUrlDataImg
  }
})

onMounted(() => {
  gsap.from('.home-download .qrcode', {
    duration: 0.5,
    opacity: 0,
    scrollTrigger: {
      trigger: '.home-download',
      start: 'top 30%'
    }
  })
  gsap.from('.home-download .download-logo', {
    duration: 0.5,
    opacity: 0,
    stagger: 0.15,
    y: 150,
    scrollTrigger: {
      trigger: '.home-download',
      start: 'top 70%'
    }
  })
  gsap.from('.home-download .right-image', {
    duration: 0.5,
    opacity: 0,
    x: 150,
    scrollTrigger: {
      trigger: '.home-download',
      start: 'top 70%'
    }
  })
})
</script>
<template>
  <div
    pt-14
    pb-10
    class="home-download"
  >
    <div content>
      <div
        flex
        justify-between
      >
        <div>
          <h2
            text-9
            font-bold
            w-150
          >
            {{ content?.title }}
          </h2>
          <p
            text-2xl
            font-bold
            mt-18
          >
            {{ t('下载地址:') }}
          </p>
          <div
            flex
            flex-wrap
            mr-20
          >
            <el-popover
              v-for="item in config?.downloadLogo.filter(item => !!item.image)"
              :key="item.name"
              placement="bottom"
              trigger="hover"
              word-wrap="word-wrap"
              :width="150"
              :show-arrow="false"
              popper-class="!p-0"
            >
              <template #reference>
                <div
                  shrink-0
                  mr-6
                  mt-7
                  class="download-logo"
                >
                  <img
                    :src="item.image"
                    h-12
                    w-55
                  >
                </div>
              </template>
              <div flex p-2 items-center justify-center>
                <img :src="downloadUrlImg(item.link)">
              </div>
            </el-popover>
          </div>
        </div>
        <div
          class="right-image"
          shrink-0
        >
          <img
            :src="content?.image"
            w-145
            alt=""
          >
        </div>
      </div>
    </div>
  </div>
</template>
