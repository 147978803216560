import type { QuoteTokenSymbol } from '~/types'
import type { Exchange } from '~/types/exchange'
import { DEPTH } from '~/config/enums'
import { useUserStore } from '~/store/user'
import { addSpotFavorite, deleteSpotFavorite } from '~/api'

let baseToken = $ref('')
let quoteToken = $ref('')

const changeSymbol = (baseTokenId: string, quoteTokenId: string) => {
  baseToken = baseTokenId
  quoteToken = quoteTokenId
}
let symbolList = $ref<Exchange.SymbolInfoWithTickerInfo[]>([])
let localFavoriteSymbolList = $ref<string[]>([])

const visibleSymbolList = computed(() => {
  return symbolList.filter(item => item.showStatus)
})

const setFavoriteSymbol = async (symbolId: string, exchangeId: string) => {
  const userStore = useUserStore()
  const { getFavoriteList } = userStore
  const { isLogin, baseInfo } = $(storeToRefs(userStore))

  if (isLogin) {
    const { favorites } = baseInfo

    if (favorites.find(item => item.symbolId === symbolId))
      await deleteSpotFavorite({ symbol_id: symbolId, exchange_id: exchangeId })
    else await addSpotFavorite({ symbol_id: symbolId, exchange_id: exchangeId })

    getFavoriteList()
  }
  else {
    if (localFavoriteSymbolList.includes(symbolId)) {
      localFavoriteSymbolList = localFavoriteSymbolList.filter(
        item => item !== symbolId
      )
    }
    else {
      localFavoriteSymbolList.push(symbolId)
    }
  }
}

const symbolId = $computed(() => {
  return `${baseToken}${quoteToken}`
})

const exchangeId = $computed(() => {
  return symbolList[0]?.exchangeId
})

const symbolMap = $computed(() =>
  Object.fromEntries(symbolList.map(item => [item.symbolId, item]))
)

const visibleSymbolMap = $computed(() => {
  return Object.fromEntries(
    visibleSymbolList.value.map(item => [item.symbolId, item])
  )
})

const currentSymbolInfo = $computed(() => {
  return symbolMap[symbolId] || {}
})

const setSymbolList = (list: QuoteTokenSymbol[]) => {
  if (!list || !list.length)
    return

  symbolList = list.map((item) => {
    return {
      tickerInfo: reactive<Exchange.TickerInfo>({
        high: '--',
        low: '--',
        close: '--',
        volume: '--',
        grow: '--',
        totalVolume: '--',
        open: '--',
        time: 0,
        amount: '--'
      }),
      ...item
    }
  })
}

const setTickerInfo = (
  tickers: Record<string, Partial<Exchange.TickerInfo>>
) => {
  Object.entries(tickers).forEach(([symbolId, tickerInfo]) => {
    if (!symbolMap[symbolId])
      return

    Object.assign(symbolMap[symbolId].tickerInfo, tickerInfo)
  })
}

// 主币精度
const currentBasePrecision = computed(() => {
  if (!currentSymbolInfo.basePrecision)
    return 4

  return DEPTH[currentSymbolInfo.basePrecision]
})
// 计价币精度
const currentQuotePrecision = computed(() => {
  if (!currentSymbolInfo.quotePrecision)
    return 4

  return DEPTH[currentSymbolInfo.quotePrecision]
})

// 价格精度
const currentPriceDigit = computed(() => {
  if (!currentSymbolInfo.minPricePrecision)
    return 4

  return DEPTH[currentSymbolInfo.minPricePrecision]
})

// 通过币种获取价格精度
const getPriceDigitBySymbol = computed(() => {
  return (symbolId: string) => {
    const minPricePrecision = symbolMap[symbolId]?.minPricePrecision

    if (!minPricePrecision)
      return 4
    return DEPTH[minPricePrecision]
  }
})
/**
 * 通过币种获取主币精度
 */
const getBasePrecisionBySymbol = computed(() => {
  return (symbolId: string) => {
    const basePrecision = symbolMap[symbolId]?.basePrecision

    if (!basePrecision)
      return 4
    return DEPTH[basePrecision]
  }
})

/**
 * 通过币种获取计价币精度
 */
const getQuotePrecisionBySymbol = computed(() => {
  return (symbolId: string) => {
    const quotePrecision = symbolMap[symbolId]?.quotePrecision

    if (!quotePrecision)
      return 4

    return DEPTH[quotePrecision]
  }
})
let dumpScale = $ref(0)
const setDumpScale = (digit: number) => {
  dumpScale = digit
}

const currentDigitMerge = $computed(() => {
  return currentSymbolInfo?.digitMerge?.split(',') ?? []
})

const currentDigitLabel = $computed(() => {
  return currentDigitMerge.find((item) => {
    return DEPTH[item] === dumpScale
  })
})

export function useExchangeSymbol() {
  return {
    baseToken: $$(baseToken),
    quoteToken: $$(quoteToken),
    symbolList: $$(symbolList),
    visibleSymbolList,
    dumpScale: $$(dumpScale),
    symbolMap: $$(symbolMap),
    visibleSymbolMap: $$(visibleSymbolMap),
    symbolId: $$(symbolId),
    exchangeId: $$(exchangeId),
    localFavoriteSymbolList: $$(localFavoriteSymbolList),

    currentSymbolInfo: $$(currentSymbolInfo),
    currentDigitMerge: $$(currentDigitMerge),
    currentPriceDigit,
    currentBasePrecision,
    currentQuotePrecision,
    currentDigitLabel: $$(currentDigitLabel),

    getPriceDigitBySymbol,
    getBasePrecisionBySymbol,
    getQuotePrecisionBySymbol,

    setSymbolList,
    setDumpScale,
    setFavoriteSymbol,
    changeSymbol,
    setTickerInfo
  }
}
