import { useFutureSymbol } from './useFutureSymbol'
import type { FutureOrderSetting } from '~/types'
import { useFutureStore } from '~/store/future'
import type { SetFutureOrderSettingParams } from '~/api'
import { getFutureOrderSettings, setFutureOrderSetting } from '~/api'

let orderSettings = $ref<FutureOrderSetting[]>([])

export function useFutureOrderSetting() {
  const futureStore = useFutureStore()

  const { symbolId, symbolList } = $(useFutureSymbol())

  // 获取下单设置
  const getOrderSetting = async () => {
    const { res, err } = await getFutureOrderSettings({
      symbol_ids: symbolList.map(item => item.symbolId).join(','),
      exchange_id: symbolList[0].exchangeId
    })

    if (err)
      return

    orderSettings = res.data
  }

  const orderSettingMap = $computed(() => {
    return Object.fromEntries(
      orderSettings.map(setting => [setting.symbolId, setting])
    )
  })

  watch($$(orderSettingMap), (newVal) => {
    futureStore.setCurrentTIF(
      newVal[futureStore.symbolId].orderSetting.timeInForce
    )
  })

  const setOrderSetting = async (params: SetFutureOrderSettingParams) => {
    const { err } = await setFutureOrderSetting(params)

    if (err)
      return false

    getOrderSetting()
    return true
  }

  const currentOrderSetting = $computed(() => {
    return orderSettingMap[symbolId] ?? null
  })

  const currentRiskSetting = $computed(() => {
    return currentOrderSetting
      ? {
        buy: currentOrderSetting?.riskLimit.find(item =>
          item.side.toLowerCase().includes('buy')
        ),
        sell: currentOrderSetting?.riskLimit.find(item =>
          item.side.toLowerCase().includes('sell')
        )
      }
      : null
  })

  // const currentOrderSetting = computed(() => {
  //   return orderSettingMap.value[symbolId] ?? null
  // })

  return {
    orderSettings: $$(orderSettings),
    orderSettingMap: $$(orderSettingMap),

    // currentOrderSetting: $$(currentOrderSetting),
    currentOrderSetting: $$(currentOrderSetting),
    currentRiskSetting: $$(currentRiskSetting),
    getOrderSetting,
    setOrderSetting
  }
}
