<script setup lang="ts">import { computed as _computed } from 'vue';

import gsap from 'gsap'
import type { NewIndexConfig } from '~/types'
import type { Nullable } from '~/types/utils'

withDefaults(defineProps<{
  config: Nullable<NewIndexConfig.ConfigItem>
}>(), { config: null, })
const content = _computed(() => {
  return __props.config?.contentList?.[0]?.content
})
const { t } = useI18n()

onMounted(() => {
  gsap.from('.video-item', {
    y: 150,
    duration: 0.5,
    opacity: 0,
    stagger: 0.15,
    scrollTrigger: {
      trigger: '.home-video-desc',
      start: 'center 70%'
    }
  })
})
</script>

<template>
  <div

    pb-18 pt-25
    class="home-video-desc"
  >
    <div content>
      <div
        justify="center"
        flex
        flex-col
        items-center
      >
        <h3 text-9 font-bold>
          {{ t('立即创建您的加密资产投资组合') }}
        </h3>
        <p

          mt-2 text-lg
          c="$ex-title"
        >
          {{ t('一分钟,购买加密货币') }}
        </p>
      </div>
      <div

        mt-15 flex
      >
        <ul

          flex flex-1
          flex-col
        >
          <li
            v-for="item in content?.list"
            :key="item.title"

            mb-8 break-all
            class="video-item"
          >
            <div

              hover:scale="110"
              flex items-center
              rounded-lg py-3
              transition
            >
              <img
                v-if="item.image"
                :src="item.image"

                mr-4 h-20
                w-20 shrink-0
                alt=""
              >
              <div font-bold>
                <p text-xl>
                  {{ item.title }}
                </p>
                <p

                  mt-3 whitespace-pre-wrap
                  text-base
                  font="!mo-regular"
                  c="$ex-title"
                >
                  {{ item.desc }}
                </p>
              </div>
            </div>
          </li>
        </ul>
        <div

          h-116 w-184
        >
          <img

            h-full w-full
            :src="content?.list?.[0]?.poster"
          >
        </div>
      </div>
    </div>
  </div>
</template>
