import { TakeApplyContactType, TakeApplySocialType } from '../types/enums'
import type { KlineResolution, LangMap, ResolutionValues } from '~/types/enums'
import { AccountTypes, AgentCommissionStatus, AgentFutureOrderType, AgentOrderSide, AssetOtherTypes, CustomInviteKycLevel, CustomInviteStatus, FollowRecommendStatus, FollowScaleTypes, FollowSquareDateType, FollowSquareSortType, FutureOrderListType, FutureOrderType, FuturePlanOrderType, FuturePositionSide, FuturePositionType, FutureStopConditionType, IEOStatus, LoginHistoryStatus, NoticeType, PriceAlarmFrequency, PriceAlarmType, StrategyGridType, StrategyOrderStatus, UserActionTypes } from '~/types/enums'

import { KlineTypes } from '~/types/kline'

export const CONTRACT_SIDE = {
  [FuturePositionSide.SHORT]: '空',
  [FuturePositionSide.LONG]: '多'
}

export const CROSS_TYPE = {
  [FuturePositionType.ISOLATED]: '逐仓',
  [FuturePositionType.CROSS]: '全仓'
}

export const ORDER_SIDE_SPOT = {
  [AgentOrderSide.BUY]: '买入',
  [AgentOrderSide.SELL]: '卖出'
}

export const ORDER_SIDE_FUTURE = {
  [AgentFutureOrderType.BUY_OPEN]: '买入开多',
  [AgentFutureOrderType.SELL_OPEN]: '卖出开空',
  [AgentFutureOrderType.BUY_CLOSE]: '买入平空',
  [AgentFutureOrderType.SELL_CLOSE]: '卖出平多'
}

// 跟单方式
export const FOLLOW_TYPE = ['开仓立即跟随', '空仓跟随']
// 跟单杠杆设置
export const FOLLOW_LEVER_TYPE = [
  '跟随交易员设置杠杆'
  // '固定杠杆'
]

// 永续合约买卖方向
export const SIDE_MAP = {
  [FutureOrderType.BUY_OPEN]: '买入开多',
  [FutureOrderType.SELL_OPEN]: '卖出开空',
  [FutureOrderType.BUY_CLOSE]: '买入平空',
  [FutureOrderType.SELL_CLOSE]: '卖出平多'
}

// 止盈止损订单类型
export const STOP_PROFIT_ORDER_TYPE = {
  [FuturePlanOrderType.STOP_LONG_PROFIT]: '多仓止盈',
  [FuturePlanOrderType.STOP_LONG_LOSS]: '多仓止损',
  [FuturePlanOrderType.STOP_SHORT_PROFIT]: '空仓止盈',
  [FuturePlanOrderType.STOP_SHORT_LOSS]: '空仓止损'
}

// 跟单跟随比例类型
export const FOLLOW_SCALE_TYPES = [
  {
    index: FollowScaleTypes.FOLLOW_RATIO,
    name: '跟随比例'
  },
  {
    index: FollowScaleTypes.FIXED_LIMIT,
    name: '固定额度'
  },
  {
    index: FollowScaleTypes.FIXED_RATIO,
    name: '固定比例'
  }
]

// 订阅分辨率
// 1m,5m,15m,30m,1h,1d,1w,1M
export const RESOLUTION_MAP: Record<KlineResolution, ResolutionValues> = {
  'D': '1m',
  '1': '1m',
  '3': '3m',
  '5': '5m',
  '15': '15m',
  '30': '30m',
  '60': '1h',
  '120': '2h',
  '240': '4h',
  '360': '6h',
  '720': '12h',
  '1D': '1d',
  '1W': '1w',
  '1M': '1M'
}

// 深度精度单位
// bhop精度单位
export const DEPTH: Record<string, number> = {
  '100000000': -8,
  '10000000': -7,
  '1000000': -6,
  '100000': -5,
  '10000': -4,
  '1000': -3,
  '100': -2,
  '10': -1,
  '1': 0,
  '0': 0,
  '0.1': 1,
  '0.01': 2,
  '0.001': 3,
  '0.0001': 4,
  '0.00001': 5,
  '0.000001': 6,
  '0.0000001': 7,
  '0.00000001': 8,
  '0.000000001': 9,
  '0.0000000001': 10,
  '0.00000000001': 11,
  '0.000000000001': 12,
  '0.0000000000001': 13,
  '0.00000000000001': 14,
  '0.000000000000001': 15,
  '0.0000000000000001': 16,
  '0.00000000000000001': 17,
  '0.000000000000000001': 18
}
// k线价格精度
export const PRECISION: Record<string, number> = {
  0: 1,
  1: 10,
  2: 100,
  3: 1000,
  4: 10000,
  5: 100000,
  6: 1000000,
  7: 10000000,
  8: 100000000,
  9: 1000000000,
  10: 10000000000,
  11: 100000000000,
  12: 1000000000000,
  13: 10000000000000,
  14: 100000000000000,
  15: 1000000000000000,
  16: 10000000000000000,
  17: 100000000000000000,
  18: 1000000000000000000
}

export const KLINE_HEADER_BTNS: KlineTypes.KlineTimeItem[] = [
  {
    text: 'Time',
    resolution: '1',
    chartType: KlineTypes.SeriesStyle.Area
  },
  {
    text: '1m',
    resolution: '1',
    chartType: KlineTypes.SeriesStyle.Candles
  },
  {
    text: '3m',
    resolution: '3',
    chartType: KlineTypes.SeriesStyle.Candles
  },
  {
    text: '5m',
    resolution: '5',
    chartType: KlineTypes.SeriesStyle.Candles
  },
  {
    text: '15m',
    resolution: '15',
    chartType: KlineTypes.SeriesStyle.Candles
  },
  {
    text: '30m',
    resolution: '30',
    chartType: KlineTypes.SeriesStyle.Candles
  },
  {
    text: '1h',
    resolution: '60',
    chartType: KlineTypes.SeriesStyle.Candles
  },
  {
    text: '2h',
    resolution: '120',
    chartType: KlineTypes.SeriesStyle.Candles
  },
  {
    text: '4h',
    resolution: '240',
    chartType: KlineTypes.SeriesStyle.Candles
  },
  {
    text: '6h',
    resolution: '360',
    chartType: KlineTypes.SeriesStyle.Candles
  },
  {
    text: '12h',
    resolution: '720',
    chartType: KlineTypes.SeriesStyle.Candles
  },
  {
    text: '1d',
    resolution: '1D',
    chartType: KlineTypes.SeriesStyle.Candles
  },
  {
    text: '1w',
    resolution: '1W',
    chartType: KlineTypes.SeriesStyle.Candles
  },
  {
    text: '1M',
    resolution: '1M',
    chartType: KlineTypes.SeriesStyle.Candles
  }
]

export const TRADINGVIEW_LANGUAGE_MAP: Record<
  LangMap,
  TradingView.LanguageCode
> = {
  'zh-cn': 'zh',
  'zh-hk': 'zh',
  'en-us': 'en',
  'ja-jp': 'ja',
  'ko-kr': 'ko',
  'ru-ru': 'ru',
  'th-th': 'th',
  'tr-tr': 'tr',
  'vi-vn': 'vi'
}

// 价格类型
export const FUTURE_PRICE_TYPES: Record<FutureStopConditionType, string> = {
  [FutureStopConditionType.MARK]: '标记价格',
  [FutureStopConditionType.INDEX]: '指数价格',
  [FutureStopConditionType.MARKET]: '市场价格'
}

// 账户类型名称
export const ACCOUNT_TYPE_NAME = {
  [AccountTypes.WALLET]: '钱包账户',
  [AccountTypes.CONTRACT]: '合约账户',
  [AccountTypes.LEVER]: '杠杆账户',
  [AccountTypes.REWARD]: '奖励账户'
}

// 二维码登录状态
export const QRCODE_LOGIN_STATUS = [
  '授权成功',
  '等待扫码',
  '已扫码',
  '二维码过期'
]

// 理财种类
export const STAKING_TYPES = ['定期项目', '活期项目', '锁仓项目']

// 世界杯比赛类型
export const WORLD_CUP_PLAY_TYPE = {
  1: '小组赛',
  2: '1/8决赛',
  3: '1/4决赛',
  4: '半决赛',
  5: '三/四名决赛',
  6: '决赛'
}

// 资产记录 其他 类型
export const ASSET_OTHER_TYPES = {
  [AssetOtherTypes.ALL]: '全部',
  [AssetOtherTypes.TRANSFER_ACCOUNTS]: '转账',
  [AssetOtherTypes.CHILD_ACCOUNT]: '子账户',
  [AssetOtherTypes.INNER_TRANSFER]: '划转',
  [AssetOtherTypes.EVENT_REWARDS]: '活动奖励',
  [AssetOtherTypes.AIR_DROP]: '空投',
  [AssetOtherTypes.INVITE_RETURN_COMMISSION]: '邀请返佣',
  [AssetOtherTypes.LEAD_REWARD]: '带单奖励',
  [AssetOtherTypes.RECOMMEND_FOLLOW_REWARD]: '推荐跟单奖励',
  [AssetOtherTypes.OTHER]: '其他'
}
export const ASSET_OTHER_TYPES_ARR = [
  {
    label: '全部',
    value: AssetOtherTypes.ALL
  },
  {
    label: '转账',
    value: AssetOtherTypes.TRANSFER_ACCOUNTS
  },
  {
    label: '子账户',
    value: AssetOtherTypes.CHILD_ACCOUNT
  },
  {
    label: '划转',
    value: AssetOtherTypes.INNER_TRANSFER
  },
  {
    label: '活动奖励',
    value: AssetOtherTypes.EVENT_REWARDS
  },
  {
    label: '空投',
    value: AssetOtherTypes.AIR_DROP
  },
  {
    label: '邀请返佣',
    value: AssetOtherTypes.INVITE_RETURN_COMMISSION
  },
  {
    label: '带单奖励',
    value: AssetOtherTypes.LEAD_REWARD
  },
  {
    label: '推荐跟单奖励',
    value: AssetOtherTypes.RECOMMEND_FOLLOW_REWARD
  },
  {
    label: '其他',
    value: AssetOtherTypes.OTHER
  },
  {
    label: '申购',
    value: AssetOtherTypes.IEO_SUBSCRIBE
  },
  {
    label: '分发',
    value: AssetOtherTypes.IEO_DISTRIBUTION
  }
]

// 站内信类型
export const INNER_MESSAGE_TYPE = [
  {
    value: NoticeType.SYSTEM,
    label: '系统通知'
  },
  {
    value: NoticeType.PERSONAL,
    label: '个人通知'
  },
  {
    value: NoticeType.OTC,
    label: 'OTC'
  },
  {
    value: NoticeType.FOLLOW,
    label: '跟单'
  }
]

// 推荐跟单-推荐明细的状态
export const FOLLOW_RECOMMEND_STATUS = {
  [FollowRecommendStatus.TO_BE_EFFECTIVE]: '待生效',
  [FollowRecommendStatus.EFFECTIVE_RECOMMEND]: '有效推荐',
  [FollowRecommendStatus.EXPIRED]: '已失效'
}

export enum TURNTABLE_RECORD_ENUM {
  EXPERIENCE = 0, // 体验金
  DEDUCTION, // 抵扣金
  MONEY // 真金
}

// 大转盘-奖励类型
export const TURNTABLE_RECORD = {
  [TURNTABLE_RECORD_ENUM.EXPERIENCE]: '体验金',
  [TURNTABLE_RECORD_ENUM.DEDUCTION]: '抵扣金',
  [TURNTABLE_RECORD_ENUM.MONEY]: ''// 真金
}

export const ALARM_TYPE_TEXT_MAP: Record<PriceAlarmType, string> = {
  [PriceAlarmType.PRICE_INCREASE]: '价格涨到',
  [PriceAlarmType.PRICE_DECREASE]: '价格跌到',
  [PriceAlarmType.RATE_INCREASE]: '涨幅达到',
  [PriceAlarmType.RATE_DECREASE]: '跌幅达到',
  [PriceAlarmType.DAILY_RATE_INCREASE]: '24小时涨幅',
  [PriceAlarmType.DAILY_RATE_DECREASE]: '24小时跌幅'
}
export const ALARM_FREQUENCY_TEXT_MAP: Record<PriceAlarmFrequency, string> = {
  [PriceAlarmFrequency.ONCE]: '提醒一次',
  [PriceAlarmFrequency.DAILY]: '每日一次',
  [PriceAlarmFrequency.EVERY]: '每次提醒'
}
export const STRATEGY_GRID_TYPE_TEXT_MAP: Record<StrategyGridType, string> = {
  [StrategyGridType.EQUAL_DIFF]: '等差',
  [StrategyGridType.EQUAL_RATIO]: '等比'
}
export const STRATEGY_ORDER_STATUS_TEXT_MAP: Record<StrategyOrderStatus, string> = {
  [StrategyOrderStatus.PENDING]: '待执行',
  [StrategyOrderStatus.WATING]: '待执行',
  [StrategyOrderStatus.PROCESSING]: '运行中',
  [StrategyOrderStatus.CLOSEING]: '关闭中',
  [StrategyOrderStatus.CLOSED]: '已关闭',
  [StrategyOrderStatus.CANCELED]: '已取消'
}

export const LOGIN_HISTORY_STATUS_TEXT_MAP: Record<LoginHistoryStatus, string> = {
  [LoginHistoryStatus.SUCCESS]: '成功',
  [LoginHistoryStatus.FAIL]: '失败',
  [LoginHistoryStatus.INCOMPLETE]: '未完成',
  [LoginHistoryStatus.SCAN_SUCCESS]: '扫码成功',
  [LoginHistoryStatus.SCAN_CANCEL]: '扫码登录取消',
  [LoginHistoryStatus.SCAN_CONFIRM]: '扫码登录确认'
}

export const USER_ACTION_HISTORY_STATUS_TEXT_MAP: Record<UserActionTypes, string> = {
  [UserActionTypes.UPDATE_PASSWORD]: '修改密码',
  [UserActionTypes.BIND_EMAIL]: '绑定邮箱',
  [UserActionTypes.BIND_MOBILE]: '绑定手机',
  [UserActionTypes.BIND_GA]: '绑定GA',
  [UserActionTypes.FIND_PWD]: '找回密码',
  [UserActionTypes.CREATE_API_KEY]: '创建API',
  [UserActionTypes.DELETE_API_KEY]: '删除API',
  [UserActionTypes.SET_TRADE_PASSWORD]: '设置交易密码',
  [UserActionTypes.SET_OTC_NICKNAME]: '设置OTC昵称',
  [UserActionTypes.ADD_OTC_PAYMENT]: '新增OTC支付方式',
  [UserActionTypes.DELETE_OTC_PAYMENT]: '删除OTC支付方式',
  [UserActionTypes.CREATE_SUB_ACCOUNT]: '创建子账户',
  [UserActionTypes.PURCHASE_FINANCE]: '购买理财',
  [UserActionTypes.ALTER_GA]: '换绑GA',
  [UserActionTypes.ALTER_MOBILE]: '换绑手机',
  [UserActionTypes.ALTER_EMAIL]: '换绑邮箱',
  [UserActionTypes.SET_PASSWORD]: '设置密码',
  [UserActionTypes.USER_UN_BIND_GA]: '解绑GA',
  [UserActionTypes.USER_UN_BIND_MOBILE]: '解绑手机',
  [UserActionTypes.USER_UN_BIND_EMAIL]: '解绑邮箱'
}

/**
 * 带单广场数据类型映射
 */
export const FOLLOW_SQUARE_SORT_TYPE_TEXT_MAP: Record<FollowSquareSortType, string> = {
  [FollowSquareSortType.TOTAL]: '综合排序',
  [FollowSquareSortType.PERSON_NUMBER]: '跟随人数',
  [FollowSquareSortType.PROFIT_RATE]: '总收益率',
  [FollowSquareSortType.PROFIT_AMOUNT]: '总收益额'
}

/**
 * 带单广场数据类型映射
 */
export const FOLLOW_SQUARE_DATE_TYPE_TEXT_MAP: Record<FollowSquareDateType, string> = {
  [FollowSquareDateType.WEEK]: '7天',
  [FollowSquareDateType.MONTH]: '30天'
}

/**
 * 常规邀请-kyc类型
 */
export const CUSTOM_INVITE_KYC_LEVEL_TEXT_MAP: Record<CustomInviteKycLevel, string> = {
  [CustomInviteKycLevel.KYC0]: '无须KYC',
  [CustomInviteKycLevel.KYC1]: 'KYC1',
  [CustomInviteKycLevel.KYC2]: 'KYC2'
}

/**
 * 常规邀请-状态
 */
export const CUSTOM_INVITE_STATUS_TEXT_MAP: Record<CustomInviteStatus, string> = {
  [CustomInviteStatus.WAITTING]: '待生效',
  [CustomInviteStatus.WORKED]: '已生效'
}

/**
 * 代理商-佣金发放状态
 */
export const AGENT_COMMISSION_STATUS_TEXT_MAP: Record<AgentCommissionStatus, string> = {
  [AgentCommissionStatus.EMPTY]: '无',
  [AgentCommissionStatus.NO_GRANT]: '未发放',
  [AgentCommissionStatus.PART_GRANT]: '部分发放',
  [AgentCommissionStatus.GRANTED]: '已发放'
}
/**
 * 当前委托和历史委托的订单类型
 */
export const FUTURE_ENTRUST_TYPE = [
  {
    label: '普通委托',
    value: FutureOrderListType.LIMIT
  },
  {
    label: '计划委托',
    value: FutureOrderListType.STOP
  },
  {
    label: '止盈止损',
    value: FutureOrderListType.STOP_LOSS
  },
  {
    label: '跟踪委托',
    value: FutureOrderListType.STOP_FOLLOW
  }
]

/**
 * 带单申请联系方式
 */
export const TAKE_APPLY_CONTACT_TYPE: Record<TakeApplyContactType, string> = {
  [TakeApplyContactType.EMAIL]: '邮箱',
  [TakeApplyContactType.MOBILE]: '手机'
}

/**
 * 带单申请社交媒体
 */
export const TAKE_APPLY_SOCIAL_MEDIA: Record<TakeApplySocialType, string> = {
  [TakeApplySocialType.DISCORD]: 'Discord',
  [TakeApplySocialType.TELEGRAM]: 'Telegram',
  [TakeApplySocialType.FACEBOOK]: 'Facebook',
  [TakeApplySocialType.TWITTER]: 'Twitter'
}

export const IEO_STATUS_TEXT_MAP: Record<IEOStatus, string> = {
  [IEOStatus.PREHEAT]: '预热中',
  [IEOStatus.BUY_START]: '进行中',
  [IEOStatus.BUY_END]: '统计中',
  [IEOStatus.PUBLISH_RESULT]: '已结束',
  [IEOStatus.END]: '已结束'
}
