import type { QuoteWS } from '~/types/ws'
import { useExchangeSymbol } from '~/composables/exchange/useExchangeSymbol'

export function useExchangeBrokerData() {
  const brokerDataHandler = (data: QuoteWS.BrokerData) => {
    const { setTickerInfo } = useExchangeSymbol()

    if (data.topic !== 'slowBroker')
      return

    setTickerInfo(
      Object.fromEntries(
        data.data.map((brokerInfo) => {
          return [
            brokerInfo.s,
            {
              high: brokerInfo.h,
              low: brokerInfo.l,
              open: brokerInfo.o,
              close: brokerInfo.c,
              volume: brokerInfo.v,
              grow: new BigNumber(brokerInfo.m).multipliedBy(100).toFixed(2),
              totalVolume: brokerInfo.qv,
              time: brokerInfo.t
            }
          ]
        })
      )
    )
  }

  return {
    brokerDataHandler
  }
}
