import { checkIp } from '~/api'

async function checkIpAction() {
  const { res, err } = await checkIp({
    stamp: Date.now()
  })
  if (err)
    return false

  return +res.data.value === 1
}

export function useIPCheck() {
  return {
    check: checkIpAction
  }
}
