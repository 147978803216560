import { defineStore } from 'pinia'
import { INITIAL_STRATEGY_LAYOUT, STRATEGY_LAYOUT_KEY } from '../config/const'
import { RESOLUTION_MAP } from '~/config/enums'
import { type KlineResolution, StrategyType } from '~/types/enums'
import { KlineTypes } from '~/types/kline'
import { deepClone } from '~/utils/utils'
import { useStrategySymbol } from '~/composables/strategy/useStrategySymbol'
import { useStrategyTrading } from '~/composables/strategy/useStrategyTrading'
import { useStrategyOrderData } from '~/composables/strategy/useStrategyOrder'

export const useStrategyStore = defineStore(
  'strategy',
  () => {
    const strategySymbol = useStrategySymbol()
    const strategyTrading = useStrategyTrading()
    const [currentStrategy, setCurrentStrategy] = useState(StrategyType.GRID)

    const { symbolId } = $(strategySymbol)

    const strategyOrder = useStrategyOrderData($$(symbolId))

    let chartLayoutMode: TradingView.LayoutType = $ref('s')
    const setChartLayoutMode = (mode: TradingView.LayoutType) => {
      if (mode === chartLayoutMode)
        return

      chartLayoutMode = mode
    }

    const resolution = $ref<Record<string, KlineResolution>>({})
    const setResolution = (res: KlineResolution, symbolId: string) => {
      resolution[symbolId] = res
    }
    const getResolutionBySymbolId = $computed(
      () => (symbolId: string) => {
        return resolution[symbolId] ?? '15'
      }
    )
    const resolutionValue = $computed(
      () => (symbolId: string) => {
        return RESOLUTION_MAP[getResolutionBySymbolId(symbolId)]
      }
    )

    const currentResolution = computed(() => {
      return getResolutionBySymbolId(symbolId)
    })
    const currentChartStyle = computed(() => {
      return getChartStyleBySymbolId(symbolId)
    })

    const chartStyle = $ref<Record<string, TradingView.SeriesStyle>>({})
    const getChartStyleBySymbolId = $computed(
      () => (symbolId: string) => {
        return chartStyle[symbolId] ?? KlineTypes.SeriesStyle.Candles
      }
    )
    const setChartStyle = (type: TradingView.SeriesStyle, symbolId: string) => {
      chartStyle[symbolId] = type
    }

    let layout = $(
      useStorage(STRATEGY_LAYOUT_KEY, INITIAL_STRATEGY_LAYOUT)
    )

    const resetLayout = () => {
      layout = deepClone(INITIAL_STRATEGY_LAYOUT)
    }

    const [showPriceAlertModal, toggleShowPriceAlertModal] = $(useToggle(false))

    return {
      chartLayoutMode: $$(chartLayoutMode as TradingView.LayoutType),
      resolution: $$(resolution),
      resolutionValue: $$(resolutionValue),
      currentResolution,
      currentStrategy,
      currentChartStyle,
      getResolutionBySymbolId: $$(getResolutionBySymbolId),
      chartStyle: $$(chartStyle),
      getChartStyleBySymbolId: $$(getChartStyleBySymbolId),
      layout: $$(layout),
      showPriceAlertModal: $$(showPriceAlertModal),

      setChartStyle,
      setChartLayoutMode,
      setCurrentStrategy,
      toggleShowPriceAlertModal,
      setResolution,
      resetLayout,

      ...strategySymbol,
      ...strategyTrading,
      ...strategyOrder

    }
  },
  {
    persist: {
      paths: ['symbolId', 'resolution', 'chartStyle', 'crncyType', 'chartLayoutMode'],
      key: 'StrategyStore'
    },
    debounce: {}
  }
)

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useStrategyStore, import.meta.hot))
