<script setup lang="ts">import { ref as _ref, computed as _computed } from 'vue';

import type { GlobalNotice } from '../types/index'
import { GLOBAL_NOTICE_KEY } from '../config/const'
import { getGlobalNotice } from '~/api'
import type { Nullable } from '~/types/utils'

const route = useRoute()

const globalNotice = _ref<
Record<'exchange' | 'future', Nullable<GlobalNotice>>
>({
  exchange: null,
  future: null
})
const alreadyShowNotice = (
  useStorage<Record<string, boolean>>(GLOBAL_NOTICE_KEY, {})
)

const getNotice = async () => {
  const { res, err } = await getGlobalNotice()

  if (err)
    return

  const availableNotice = res.data.filter((notice) => {
    return !alreadyShowNotice.value[
      dayjs(Date.now()).format('YYYY-MM-DD') + notice.barId
    ]
  })

  if (!availableNotice.length)
    return

  globalNotice.value.exchange
    = availableNotice.find(notice => notice.page.includes('exchange')) ?? null

  globalNotice.value.future
    = availableNotice.find(notice => notice.page.includes('future')) ?? null
}
getNotice()

const currentNotice = _computed(() => {
  if (route.name === 'futures-symbolId')
    return globalNotice.value.future
  else if (route.name === 'exchange-baseToken-quoteToken')
    return globalNotice.value.exchange
  else return null
})

const onClose = () => {
  if (!currentNotice.value)
    return

  alreadyShowNotice.value[
    dayjs(Date.now()).format('YYYY-MM-DD') + currentNotice.value.barId
  ] = true
}
</script>

<template>
  <div v-if="currentNotice">
    <el-alert type="warning" :title="currentNotice.content" @close="onClose" />
  </div>
</template>
