import { ElMessage } from 'element-plus'
import type { AxiosRequestConfig, AxiosResponse, Method } from 'axios'
import axios from 'axios'
import Cookie from './cookie'
import { throttle } from './utils'
import { FINGER_PRINT_KEY } from '~/config/const'
import { NEED_KYC, TOKEN_EXPIRED, WITHDRAW_ADDRESS_ERROR } from '~/config/errorCodes'

/**
 * 不显示异常提示的状态码
 */

const NO_ERROR_CODE = [NEED_KYC, WITHDRAW_ADDRESS_ERROR]

// 创建一个错误
function errorCreate(msg: string, code?: number) {
  if (code && NO_ERROR_CODE.includes(code))
    return

  const error = new Error(msg)
  errorLog(error)
  // throw error
}
const throttleErrorCreate = throttle(errorCreate, 1000)
// 记录和显示错误
function errorLog(error: Error) {
  // 显示提示
  ElMessage({
    message: error.message,
    type: 'error',
    duration: 5 * 1000
  })
}
// 定义接口
interface PendingType {
  url?: string
  method?: Method
  params: any
  data: any
  cancel: Function
}

// 取消重复请求
const pending: Array<PendingType> = []
const CancelToken = axios.CancelToken
// 创建一个 axios 实例
const service = axios.create({
  baseURL: '/' || import.meta.env.VITE_SERVER_BASE_URL,
  timeout: 20000, // 请求超时时间
  withCredentials: true
})
// 移除重复请求
function removePending(config: AxiosRequestConfig) {
  for (const key in pending) {
    const item: number = +key
    const list: PendingType = pending[key]
    // 当 前请求在数组中存在时执行函数体

    if (
      list.url === config.url
      && list.method === config.method
      && JSON.stringify(list.params) === JSON.stringify(config.params)
      && JSON.stringify(list.data) === JSON.stringify(config.data)
    ) {
      // 执行取消操作
      list.cancel('method repeat')
      // 从数组中移除记录
      pending.splice(item, 1)
    }
  }
}

// 请求拦截器
service.interceptors.request.use(
  (config) => {
    const ctoken = Cookie.get('c_token')
    if (ctoken) {
      config.url = [config.url, ['c_token', ctoken].join('=')].join(
        config.url!.includes('?') ? '&' : '?'
      )
    }

    config.headers = config.headers ?? {}
    config.headers['X-Requested-With'] = 'XMLHttpRequest'
    config.headers['Accept-Language'] = localStorage.lang ?? 'en-us'
    config.headers['Content-Type'] = 'application/x-www-form-urlencoded'

    const fingerPrint = useStorage(FINGER_PRINT_KEY, '')
    if (fingerPrint.value)
      config.headers.imei = fingerPrint.value

    let str = ''
    // let form = new FormData();
    const fn = (item: any, key: any) => {
      str += `${key}[]=${item}&`
    }

    let tempData: any = config.data
    if (config.method?.toUpperCase() === 'GET')
      tempData = config.params

    for (const key in config.data) {
      if ({}.hasOwnProperty.call(tempData, key)) {
        const v = tempData[key]
        if (Object.prototype.toString.call(v) === '[object Array]')
          for (let i = 0, l = v.length; i < l; i += 1) fn(v[i], key)
        else str += `${key}=${tempData[key]}&`
      }
      // form.append(key, options.body[key]);
    }
    str = str.replace(/&$/, '')
    if (/get/i.test(config.method!)) {
      if (str)
        config.url += config.url!.includes('?') ? `&${str}` : `?${str}`
    }

    if (/post/i.test(config.method!))
      config.data = str

    removePending(config)
    config.cancelToken = new CancelToken((c) => {
      pending.push({
        url: config.url,
        method: config.method as Method,
        params: config.params,
        data: config.data,
        cancel: c
      })
    })
    return config
  },
  (error) => {
    // 发送失败
    return Promise.reject(error)
  }
)
export interface ServerResponse<T = any> {
  data: T
}
export interface IAxios<D = null> {
  code: number
  msg: string
  data: D
}
// 响应拦截器
service.interceptors.response.use(
  <T>(response: AxiosResponse<IAxios<T>>) => {
    removePending(response.config)
    // dataAxios 是 axios 返回数据中的 data
    const dataAxios = response.data
    // 这个状态码是和后端约定的
    const { code, data, msg } = dataAxios

    // 根据 code 进行判断
    if (response.status < 200 || response.status > 502) {
      errorCreate(`${response.statusText}`)
      return Promise.reject(response.statusText)
    }
    else {
      if (code < 200 || code > 300) {
        if (code === TOKEN_EXPIRED) {
          Cookie.clear('account_id')
          delete sessionStorage.userInfo
          throttleErrorCreate(msg)

          setTimeout(() => {
            location.href = `${
              location.origin
            }/login?redirect=${encodeURIComponent(window.location.href)}`
          }, 1000)
        }
        else {
          errorCreate(msg, code)
        }

        return Promise.reject(dataAxios)
      }

      return {
        data: data ?? dataAxios
      } as any
    }
  },
  (error) => {
    const { response, status } = error

    if (status < 200 || status >= 500) {
      errorCreate(`${response?.data?.msg ?? response?.statusText}`)
      return Promise.reject(error)
    }
    else {
      const { code, msg } = response?.data || {}

      if (code < 200 || code > 300) {
        if (code === TOKEN_EXPIRED) {
          Cookie.clear('account_id')
          throttleErrorCreate(msg)

          setTimeout(() => {
            location.href = `${
              location.origin
            }/login?redirect=${encodeURIComponent(window.location.href)}`
          }, 1000)
        }
        else {
          errorCreate(msg, code)
        }
      }
      // errorLog(error)
      return Promise.reject(response?.data || 'error')
    }
  }
)

export default service
