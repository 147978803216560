import { defineStore } from 'pinia'
import BigNumber from 'bignumber.js'
import type { WritableComputedRef } from 'vue'
import { DEFAULT_THEME } from '../config/const'
import { hexToRgba } from '../utils/utils'
import type { ColorScheme, HotSymbolInfo } from '../types/index'
import { getHotSymbol } from '../api/index'
import { useExchangeStore } from './exchange'
import type {
  CountryItem,
  FollowPicConfig,
  FuturesSymbol,
  HeadConfigList,
  IndexConfig,
  InviteConfig,
  PlatformData,
  PlatformListItem,
  SupportLanguage,
  Token,
  WebConfig
} from '~/types'

import type { Nullable } from '~/types/utils'
import {
  getBasicCountries,
  getBasicHeadConfig,
  getCommonLangConfig,
  getCustomKV,
  getEmailAutoSuffix,
  getFollowPicConfig,
  getIndexConfig,
  getRate,
  getShowAgentStatus,
  getSupportUnit
} from '~/api'
import Cookie from '~/utils/cookie'
import type { RateItem } from '~/api/apiResponseType'
import { CustomKVKeys, PageColorType } from '~/types/enums'
import { useFutureStore } from '~/store/future'
import { useUserStore } from '~/store/user'

interface ConfigStoreModel {
  inviteConfig: InviteConfig
  webConfig: Nullable<WebConfig>
  indexConfig: Nullable<IndexConfig>
  headConfigList: HeadConfigList[]
  showAgent: boolean
  customKV: Nullable<PlatformData>
  rates: Record<string, RateItem>
  followPicConfig: Nullable<FollowPicConfig>
  useDarkMode: WritableComputedRef<boolean>
  unit: string
  ['theme']: Record<
  'light' | 'dark',
  {
    primary: string
    success: string
    warning: string
    error: string
    grow: string
    down: string
    background: string
    exchangePageBg: string
    pageBg: string
    title: string
    disabledBg: string
    subTitle: string
    mainTitle: string
    splitLine: string
    inputBg: string
    sliderBg: string
    white: string
    sliderForegroundColor: string
    black: string
  }
  >
  countries: CountryItem[] // 国家列表
  mailSuffix: string[] // 邮箱前缀列表
  unitList: SupportLanguage[]
  hotSymbolConfig: Nullable<HotSymbolInfo>
}

export const useConfigStore = defineStore('config', {
  state: (): ConfigStoreModel => {
    return {
      inviteConfig: {
        invite_activity_rule_url: '',
        invite_title_pic_pc: ''
      },
      hotSymbolConfig: null,
      countries: [],
      mailSuffix: [],
      webConfig: null,
      customKV: null,
      followPicConfig: null,
      useDarkMode: isDark,
      unit: 'en-us',
      unitList: [],
      /**
       * 新的配置单独接口取
       */
      headConfigList: [],
      indexConfig: {
        orgId: '',
        logo: '',
        favicon: '',
        copyright: '',
        zendesk: '',
        shares: [],
        title: '',
        imageConfig: {
          dark: {},
          light: {}
        },
        footConfigList: [],
        headConfigList: [],
        announcements: [],
        userAgreement: '',
        privacyAgreement: '',
        optionCustomerService: '',
        optionOpenWithAnswer: false,
        shareConfig: {
          logoUrl: '',
          watermarkImageUrl: '',
          title: '',
          description: '',
          openUrl: '',
          openUrlImgBase64: '',
          contractLossShareTitles: [],
          contractZeroShareTitles: [],
          contractProfitShareTitles: []
        },
        logoUrl: '',
        indexModules: [],
        keywords: '',
        banners: [],
        announcementMoreUrl: '',
        userFuturesAgreement: '',
        userOptionAgreement: '',
        videoVerifyAgreement: '',
        customerService: '',
        futuresCustomerService: '',
        futuresOpenWithAnswer: false,
        description: '',
        smallBanners: [],
        socialMedia: [],
        indexConfig: {
          dark: [],
          light: []
        }
      },
      showAgent: false,
      rates: {},
      theme: { ...DEFAULT_THEME }
    }
  },
  actions: {
    /**
     * 获取邀请页面配置
     */
    async getInviteCommonConfig() {
      const { res } = await getCommonLangConfig()
      if (res)
        Object.assign(this.inviteConfig, res.data.list)
    },
    /**
     * 获取热门币种配置信息
     */
    async getHotSymbolConfig() {
      const { res, err } = await getHotSymbol()

      if (err)
        return

      if (res)
        this.hotSymbolConfig = res.data
    },
    /**
     * 获取国家列表
     */
    async getAllCountries() {
      this.countries = []
      const { res } = await getBasicCountries()
      if (res) {
        res.data.forEach((item: CountryItem) => {
          this.countries.push({
            value: item.nationalCode,
            label: item.nationalCode,
            ...item
          })
        })
      }
    },
    /**
     * 获取邮箱前缀列表
     */
    async getMailSuffixList() {
      const { res, err } = await getEmailAutoSuffix()
      if (err)
        return
      if (res)
        this.mailSuffix = res.data
    },
    toggleDarkMode(useDark: boolean) {
      toggleDark(useDark)
    },
    async getCustomKV() {
      const { res, err } = await getCustomKV({
        custom_keys: CustomKVKeys.PLATFORM_DATA
      })

      if (err)
        return

      this.customKV = res.data
    },
    async setLocale(locale: string, reload = true) {
      if (localStorage.lang === locale)
        return

      Cookie.set(
        'locale',
        locale,
        10000000000000,
        location.hostname.replace(
          `${location.hostname.split('.').shift()}.`,
          ''
        )
      )
      localStorage.lang = locale
      if (Cookie.get('account_id')) {
        const userStore = useUserStore()
        await userStore.setUserSetting({ lang: locale })
      }
      if (!reload)
        return

      const queryParams = new URLSearchParams(location.search)
      const query = queryParams.get('lang')

      if (query) {
        window.location.href = window.location.href.replace(
          `lang=${query}`,
          `lang=${locale}`
        )
      }
      else {
        window.location.reload()
      }
    },
    async getUnits() {
      const { res, err } = await getSupportUnit()
      if (err)
        return

      this.unitList = res.data

      if (!this.unitList.find(item => item.lang === this.unit))
        this.changeUnit('en-us')
    },
    changeUnit(unit: string, setUserUnit = true) {
      if (!unit)
        return

      if (setUserUnit) {
        const { isLogin, setUserSetting } = useUserStore()

        if (isLogin)
          setUserSetting({ unit })
      }

      this.unit = unit
      localStorage.unit = unit
    },
    setWebConfig() {
      this.webConfig = window.WEB_CONFIG
      this.webConfig.token = this.webConfig?.token?.filter(item => item.tokenId !== 'BNB')
      this.unit = localStorage.unit

      const { setSymbolList } = useFutureStore()
      setSymbolList(this.webConfig.futuresSymbol || [])

      const { setSymbolList: setExchangeSymbolList } = useExchangeStore()
      setExchangeSymbolList(
        this.webConfig?.customQuoteToken
          ?.flatMap(item => item.quoteTokenSymbols)
          .filter(Boolean)
      )
    },
    mergeTheme() {
      if (!this.webConfig)
        return

      const colorTheme = this.webConfig.colorScheme || { ...DEFAULT_THEME }

      const userStore = useUserStore()
      const { userSetting } = $(storeToRefs(userStore))

      const lightUpColor
        = userSetting.commonConfig.up_down === PageColorType.GREEN_UP
          ? colorTheme.light.grow
          : colorTheme.light.down

      const lightDownColor
        = userSetting.commonConfig.up_down === PageColorType.GREEN_UP
          ? colorTheme.light.down
          : colorTheme.light.grow
      const darkUpColor
        = userSetting.commonConfig.up_down === PageColorType.GREEN_UP
          ? colorTheme.dark.grow
          : colorTheme.dark.down
      const darkDownColor
        = userSetting.commonConfig.up_down === PageColorType.GREEN_UP
          ? colorTheme.dark.down
          : colorTheme.dark.grow

      const colorKeys = Object.keys(this.theme.light)

      const newTheme = {
        light: {
          ...this.theme.light,
          ...colorKeys.reduce((acc, key) => {
            acc[key]
              = colorTheme.light[key as keyof ColorScheme]
              || this.theme.light[key as keyof typeof this.theme.light]
            return acc
          }, {} as Record<string, string>),
          grow: lightUpColor,
          down: lightDownColor
        },
        dark: {
          ...this.theme.dark,
          ...colorKeys.reduce((acc, key) => {
            acc[key]
              = colorTheme.dark[key as keyof ColorScheme]
              || this.theme.light[key as keyof typeof this.theme.light]
            return acc
          }, {} as Record<string, string>),
          grow: darkUpColor,
          down: darkDownColor
        }
      }

      this.theme = {
        ...this.theme,
        ...newTheme
      }
    },
    /**
     * 获取头部导航配置
     */
    async getHeadConfig() {
      const { res } = await getBasicHeadConfig()
      this.headConfigList = res?.data
    },
    async getIndexConfig() {
      const searchParams = new URLSearchParams(location.search)
      const isPreview = searchParams.get('preview') === 'true'
      const { res, err } = await getIndexConfig(isPreview)

      if (err)
        return

      this.indexConfig = res.data as IndexConfig
      const link = document.createElement('link')
      link.rel = 'icon'
      link.href = this.indexConfig.favicon || ''
      document.getElementsByTagName('head')[0].appendChild(link)

      document.title = this.indexConfig.title || ''
      if (document.querySelector('#ze-snippet') || !res?.data.zendesk)
        return

      const script = document.createElement('script')
      script.id = 'ze-snippet'
      script.async = true
      script.src = res?.data.zendesk
      script.onload = function () {
        const langObj: Record<string, string> = {
          'en-us': 'en-US',
          'zh-cn': 'zh-cn',
          'zh-hk': 'zh-tw',
          'th-th': 'th',
          'ko-kr': 'ko',
          'ja-jp': 'ja',
          'ru-ru': 'ru',
          'de-de': 'de',
          'es-es': 'et',
          'fr-fr': 'fr',
          'vi-vn': 'vi',
          'tr-tr': 'tr'
        }
        window.zE
        && window.zE(() => {
          const lang = (localStorage.lang as string) || Cookie.get('lang')
          // zE.setLocale(langObj[lang]);
          window.zE('messenger:set', 'locale', langObj[lang])
          // if (
          //   window.location.pathname.indexOf("/exchange") > -1 ||
          //   window.location.pathname.indexOf("/contract/quote") > -1 ||
          //   ismobile
          // ) {
          //   zE("webWidget", "hide");
          // } else {
          //   zE("webWidget", "show");
          // }
        })
      }
      document.querySelector('body')?.appendChild(script)
    },
    async getShowAgentStatus() {
      const { res, err } = await getShowAgentStatus()
      if (err)
        return

      this.showAgent = res.data.result
    },
    async getRate() {
      const legalCoins = new Set(['BTC', 'USDT', 'USD'])

      this.unitList.forEach((item) => {
        legalCoins.add(item.suffix)
      })

      const { res, err } = await getRate({
        tokens: (this.webConfig?.token ?? [])
          .map(item => item.tokenId)
          .concat(['BTC', 'USDT'])
          .join(',')
          .toUpperCase(),
        legalCoins: Array.from(legalCoins).join(',').toUpperCase()
      })

      if (err)
        return

      const map: Record<string, RateItem> = {}
      res?.data.forEach((item) => {
        map[item.token] = item.rates
      })

      this.rates = map
    },
    async getFollowPicConfig() {
      const { res, err } = await getFollowPicConfig({
        org_id: this.webConfig!.orgId
      })
      if (err)
        return

      this.followPicConfig = res.data
    }
  },
  getters: {
    tokenToCurrencyValue() {
      /**
       * @description
       * @author zhangcong
       * @date 10/08/2022
       * @param {string} token 币种id
       * @param {number} unit 汇率代码
       * @param {number|string} value 币种数量
       * @return {string} 转换法币后的价值 没有获取到汇率、值、用户的unit将返回--
       */

      return (token: string, unit?: string, value?: number | string) => {
        if (value === 0 || value === '0')
          return '0.00'

        if (!this.rates[token] || !value || !unit)
          return '--'

        const originRet = new BigNumber(value).multipliedBy(
          this.rates[token][unit] ?? 0
        )
        const ret = originRet.abs()

        let digit = 4

        if (ret.isGreaterThanOrEqualTo(10))
          digit = 2
        else if (ret.isGreaterThanOrEqualTo(1))
          digit = 3
        else if (ret.isGreaterThanOrEqualTo(0.1))
          digit = 4
        else if (ret.isGreaterThanOrEqualTo(0.01))
          digit = 5
        else if (ret.isGreaterThanOrEqualTo(0.001))
          digit = 6
        else if (ret.isGreaterThanOrEqualTo(0.0001))
          digit = 7
        else if (ret.isGreaterThanOrEqualTo(0.00001))
          digit = 8
        else if (ret.isGreaterThanOrEqualTo(0.000001))
          digit = 9
        else digit = 10

        return originRet.toFixed(digit)
      }
    },
    usdtToCurrencyValue() {
      return (
        value: number | string,
        options: { withPrefix?: boolean, withSuffix?: boolean, transferCb?: (value: string) => string } = {}
      ) => {
        if (Number.isNaN(+value))
          return '--'
        options.transferCb = options.transferCb || ((val: string) => val)

        return `${options?.withPrefix ? `${this.curUnit?.prefix} ` : ''
        }${options.transferCb(this.tokenToCurrencyValue('USDT', this.curUnit?.suffix, value))}${options?.withSuffix ? ` ${this.curUnit?.suffix}` : ''
        }`
      }
    },

    /**
     * @description 获取当前汇率
     * @author zhangcong
     * @date 10/08/2022
     * @return {SupportLanguage}  当前汇率代码对应的对象
     */
    curUnit(state) {
      return state.unitList.find(
        item => item.lang === state.unit
      )
    },
    // 跟单相关协议
    protocols(state) {
      const obj: Record<string, PlatformListItem> = {}

      if (state.customKV?.[CustomKVKeys.PLATFORM_DATA]?.list?.length) {
        state.customKV[CustomKVKeys.PLATFORM_DATA]!.list.forEach((item) => {
          obj[item.key] = item
        })
      }

      return obj
    },
    getProtocolUrlByName() {
      return (name: string) => {
        return this.protocols[name]?.value
      }
    },
    tokenMapGetter(state) {
      const tokenMap: Record<string, Token> = {}

      state.webConfig?.token.forEach((item) => {
        tokenMap[item.tokenId] = item
      })

      return tokenMap
    },
    tokenLogoGetter() {
      return (tokenId: string) => {
        const token = this.tokenMapGetter[tokenId]
        if (!token)
          return ''

        return token.iconUrl
      }
    },
    currentThemeGetter(state) {
      return state.useDarkMode ? state.theme.dark : state.theme.light
    },
    klineThemeGetter(state) {
      const theme = state.useDarkMode ? state.theme.dark : state.theme.light
      return {
        up: theme.grow,
        down: theme.down,
        bg: theme.background,
        grid: hexToRgba(theme.exchangePageBg, 0.08),
        grid2: theme.exchangePageBg,
        cross: 'rgba(104,119,139,.7)',
        border: theme.disabledBg,
        text: theme.mainTitle,
        areatop: hexToRgba(theme.primary, 0.5),
        areadown: hexToRgba(theme.primary, 0.01),
        showLegend: true
      }
    },
    // 合约币种信息map
    futureSymbolMapGetter(state) {
      const map: Record<string, FuturesSymbol> = {}

      state.webConfig?.futuresSymbol.forEach((item) => {
        map[item.symbolId] = item
      })

      return map
    },
    // 图片配置
    imageConfigGetter(state) {
      return state.useDarkMode
        ? state.indexConfig?.imageConfig?.dark
        : state.indexConfig?.imageConfig?.light
    },
    indexPageConfig(state) {
      return state.useDarkMode
        ? state.indexConfig?.indexConfig?.dark
        : state.indexConfig?.indexConfig?.light
    },
    socialMedia(state) {
      return state.indexConfig?.socialMedia?.map((item) => {
        return {
          detailList: item.detailList,
          icon: state.useDarkMode ? item.darkDefaultLogo : item.defaultLogo,
          selectIcon: state.useDarkMode
            ? item.darkSelectedLogo
            : item.selectedLogo
        }
      })
    },
    brokerName(state) {
      return state.webConfig?.page.index ?? ''
    }
  },
  persist: {
    paths: ['useDarkMode', 'unit']
  }
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useConfigStore, import.meta.hot))
