import { DEPTH } from '~/config/enums'
import { useFutureStore } from '~/store/future'

let symbolId = $ref('BTC-SWAP-USDT')

function setSymbolId(id: string) {
  symbolId = id
}

let dumpScale = $ref(0)
function setDumpScale(digit: number) {
  dumpScale = digit
}

export function useStrategySymbol() {
  const futureStore = useFutureStore()
  const { getSymbolInfoBySymbolId, getQuotePrecisionBySymbolId, getPriceDigitBySymbol } = futureStore

  const currentSymbolInfo = $computed(() => {
    return getSymbolInfoBySymbolId(symbolId)
  })

  const currentPriceDigit = $computed(() => {
    return getPriceDigitBySymbol(symbolId)
  })

  const currentQuotePrecision = $computed(() => {
    return getQuotePrecisionBySymbolId(symbolId)
  })

  const currentOrderSetting = $computed(() => {
    const { orderSettingMap } = $(storeToRefs(futureStore))

    return orderSettingMap[symbolId]
  })

  const currentDigitMerge = $computed(() => {
    return currentSymbolInfo?.digitMerge.split(',')
  })

  const currentDigitLabel = $computed(() => {
    return currentDigitMerge.find((item) => {
      return DEPTH[item] === dumpScale
    })
  })

  const currentQuoteToken = computed(() => {
    return currentSymbolInfo.quoteTokenId
  })

  return {
    symbolId: $$(symbolId),
    dumpScale: $$(dumpScale),

    currentSymbolInfo: $$(currentSymbolInfo),
    currentDigitMerge: $$(currentDigitMerge),
    currentDigitLabel: $$(currentDigitLabel),
    currentPriceDigit: $$(currentPriceDigit),
    currentOrderSetting: $$(currentOrderSetting),
    currentQuotePrecision: $$(currentQuotePrecision),
    currentQuoteToken,

    setSymbolId,
    setDumpScale
  }
}
