import { getQuoteTrades } from '~/api'
import {
  SPOT_TRADE_MAX_COUNT,
  SPOT_TRADE_UPDATE_INTERVAL
} from '~/config/throttleSetting'

import type { QuoteWS } from '~/types/ws'
import { excludeRepeatArray } from '~/utils/helper'
import { useExchangeStore } from '~/store/exchange'

// 历史成交相关
let cachedOrders = $ref<QuoteWS.TradeInfo[]>([])
let tradeOrders = $ref<QuoteWS.TradeInfo[]>([])
let lastExecute = $ref(0)

const addTradeOrders = (orders: QuoteWS.TradeInfo[]) => {
  cachedOrders.push(...orders)

  if (Date.now() - lastExecute > SPOT_TRADE_UPDATE_INTERVAL) {
    lastExecute = Date.now()
    tradeOrders.unshift(...cachedOrders)
    cachedOrders = []
  }

  if (tradeOrders.length > SPOT_TRADE_MAX_COUNT)
    tradeOrders = tradeOrders.slice(0, SPOT_TRADE_MAX_COUNT)

  tradeOrders = excludeRepeatArray('v', tradeOrders)
  tradeOrders.sort((a, b) => b.t - a.t) // 时间降序
}

const clearOrders = () => {
  cachedOrders = []
  tradeOrders = []
}

export function useExchangeTradeData() {
  const exchangeStore = useExchangeStore()

  const tradeDataHandler = (data: QuoteWS.TradeData) => {
    const { symbolId } = $(storeToRefs(exchangeStore))
    if (data.topic !== 'trade' || data.symbol !== symbolId)
      return

    addTradeOrders(data.data)
  }

  const tradeHttpAction = async () => {
    const { symbolId, exchangeId } = $(storeToRefs(exchangeStore))
    if (!symbolId || !exchangeId)
      return

    const { res, err } = await getQuoteTrades({
      symbol: `${exchangeStore.exchangeId}.${exchangeStore.symbolId}`,
      limit: SPOT_TRADE_MAX_COUNT
    })

    if (err)
      return

    addTradeOrders(res.data)
  }

  return {
    tradeOrders: $$(tradeOrders),
    tradeDataHandler,
    tradeHttpAction,
    clearOrders
  }
}
