/**
 * token过期
 */
export const TOKEN_EXPIRED = 30000
/**
 * 需要实名认证
 */
export const NEED_KYC = 31118
/**
 * 提币地址错误
 */
export const WITHDRAW_ADDRESS_ERROR = 36004
