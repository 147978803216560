<script setup lang="ts">
withDefaults(defineProps<{
  color: string
  hoverColor?: string
  position?: 'left' | 'right'
  disabled?: boolean
  buttonClass?: string
}>(), { position: 'left',disabled: false, })
const emits = defineEmits<{
  (e: 'click'): void
}>()
const realHoverColor = computed(() => {
  return __props.hoverColor || __props.color
})

function onClick() {
  !__props.disabled && emits('click')
}
</script>

<template>
  <div w-full>
    <div
      :class="[position === 'left' ? 'left-skew-btn' : 'right-skew-btn', buttonClass ?? '']"
      :style="{ background: color, color }"

      flex-1 cursor-pointer
      rounded-full p-2
      text-center
      bg="$ex-inputBg"
      c="$ex-inputBg"
      @click="onClick"
    >
      <slot />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.right-skew-btn {
  position: relative;
  margin-right: 10px;
  z-index: 1;

  &::after {
    content: '';
    position: absolute;
    right: -9px;
    top: 0;
    width: 50%;
    z-index: -1;
    height: 100%;
    background: currentColor;
    transform: skewX(-15deg);
    border-radius: 4px;
  }

  &:hover {
    color: v-bind(realHoverColor) !important;
    background: v-bind(realHoverColor) !important;
  }
}

.left-skew-btn {
  position: relative;
  margin-left: 10px;
  z-index: 1;

  &::after {
    content: '';
    position: absolute;
    left: -9px;
    top: 0;
    width: 50%;
    height: 100%;
    background: currentColor;
    transform: skewX(-15deg);
    z-index: -1;
    border-radius: 4px;
  }

  &:hover {
    color: v-bind(realHoverColor) !important;
    background: v-bind(realHoverColor) !important;
  }
}
</style>
