<script setup lang="ts">import { computed as _computed } from 'vue';

import type { NewIndexConfig } from '~/types'
import type { Nullable } from '~/types/utils'

withDefaults(defineProps<{
  config: Nullable<NewIndexConfig.ConfigItem>
}>(), { config: null, })
const content = _computed(() => {
  return __props.config?.contentList?.[0]?.content
})

const { t } = useI18n()

const openLink = (link?: string): void => {
  if (!link)
    return

  window.open(link, '_blank', 'noopener noreferrer')
}

</script>
<template>
  <div
    pt-14
    pb-30
    class="home-partner"
  >
    <div content>
      <div
        flex
        justify-between
      >
        <div>
          <h2
            text-9
            font-bold
            w-150
          >
            {{ t('合作伙伴') }}
          </h2>
          <div
            flex
            flex-wrap
            gap-10
            pt-12
          >
            <div
              v-for="item in content?.list"
              :key="item.image"
              shrink-0
              w-50
              h-21
              class="download-logo"
              :class="item.link ? 'cursor-pointer' : ''"
              @click="openLink(item.link)"
            >
              <img
                :src="item.image"
                w-full
                h-full
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
