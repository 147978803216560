<script setup lang="ts">import { toRef as _toRef, computed as _computed } from 'vue';

import { Vue3SeamlessScroll } from 'vue3-seamless-scroll'
import { useConfigStore } from '~/store/config'

const configStore = useConfigStore()
const __$temp_1 = (storeToRefs(configStore)),
  indexConfig = _toRef(__$temp_1, 'indexConfig');
const announcements = _computed(() => indexConfig.value?.announcements ?? [])
// const announcementMoreUrl = $computed(
//   () => indexConfig?.announcementMoreUrl ?? ''
// )
</script>

<template>
  <div v-if="announcements.length">
    <div

      flex justify-between
      pb-8
    >
      <div h-6 flex overflow-hidden>
        <i
          i-basil:volume-down-outline mr-1
          text="6"
          mt="-0.5"
        />
        <vue3-seamless-scroll
          :list="announcements" hover
          :limit-scroll-num="2"
          :single-height="24"
        >
          <div
            v-for="item in announcements"
            :key="item.publishTime"
            truncate
          >
            <a
              :href="item.directUrl"
              target="_blank"
              rel="noopener noreferrer nofollow"

              ml-2
              text-sm
            >
              {{ item.title }}
            </a>
          </div>
        </vue3-seamless-scroll>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.more-btn {
  color: var(--ex-title);
}
</style>
