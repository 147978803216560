import type { MaybeRef } from '@vueuse/core'
import { useFutureSymbol } from './useFutureSymbol'
import type { FutureOrderSetting } from '~/types'
import { useFutureStore } from '~/store/future'
import { getFollowOrderCreateSetting, getFutureOrderSettings } from '~/api'

let orderSettings = $ref<FutureOrderSetting[]>([])

export function useFutureFollowOrderSetting() {
  const futureStore = useFutureStore()

  const { symbolList } = $(useFutureSymbol())

  // 获取下单设置
  const getFollowOrderSetting = async () => {
    const { res, err } = await getFollowOrderCreateSetting({
      symbol_ids: symbolList.map(item => item.symbolId).join(','),
      exchange_id: symbolList[0].exchangeId
    })

    if (err)
      return

    orderSettings = res.data
  }

  const followOrderSettingMap = $computed(() => {
    return Object.fromEntries(
      orderSettings.map(setting => [setting.symbolId, setting])
    )
  })

  return {
    followOrderSettings: $$(orderSettings),
    followOrderSettingMap: $$(followOrderSettingMap),
    getFollowOrderSetting
  }
}
