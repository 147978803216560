import { getUserLeverage } from '~/api'
import { useFutureStore } from '~/store/future'
import {
  FutureOrderDelegateType,
  FutureOrderPriceType,
  FutureOrderSide,
  FutureOrderType,
  FuturePositionType,
  FutureStopConditionType,
  FutureTimeInForce,
  TrackCallbackType
} from '~/types/enums'

let currentOrderSide = $ref(FutureOrderSide.OPEN)
let currentOrderType = $ref(FutureOrderType.BUY_OPEN)
let currentPositionType = $ref(FuturePositionType.CROSS)
let currentLeverage = $ref(10)
let currentLongLeverage = $ref(10)
let currentShortLeverage = $ref(10)
let currentPriceType = $ref(FutureOrderPriceType.MARKET)
let currentDelegateType = $ref(FutureOrderDelegateType.NORMAL)
let currentTIF = $ref(FutureTimeInForce.IOC)
let usePercentQuantity = $ref(false)
let currentPercent = $ref(0)
let useStopLimit = $ref(false)
const [useTrackTrigger, setUseTrackTrigger] = useToggle(false)

const initialForm = {
  quantity: '',
  price: '',
  triggerPrice: '',
  stopProfitPrice: '',
  stopLossPrice: '',
  callbackValue: '',
  callbackType: TrackCallbackType.PERCENTAGE,
  triggerConditionType: FutureStopConditionType.MARK,
  stopLossTriggerConditionType: FutureStopConditionType.MARK,
  stopProfitTriggerConditionType: FutureStopConditionType.MARK,
  planPriceType: FutureOrderPriceType.LIMIT
}
export type FutureTradeFormData = typeof initialForm
let tradeFormData = $ref({ ...initialForm })
const [quickCreateOrderDragging, toggleQuickCreateOrderDragging] = useToggle(false)

function setCurrentOrderSide(side: FutureOrderSide) {
  currentOrderSide = side
}

function setCurrentOrderType(type: FutureOrderType) {
  currentOrderType = type
}

function setUsePercentQuantity(use: boolean) {
  usePercentQuantity = use
}

function setUseStopLimit(use: boolean) {
  useStopLimit = use
}

function setCurrentTIF(tif: FutureTimeInForce) {
  currentTIF = tif
}

function setCurrentPercent(percent: number) {
  currentPercent = percent
}

function setCurrentPositionType(type: FuturePositionType) {
  currentPositionType = type
}

async function getCurrentLeverage() {
  const futureStore = useFutureStore()
  const { symbolId } = $(storeToRefs(futureStore))

  const { res, err } = await getUserLeverage({ symbol_id: symbolId })
  if (err)
    return

  setCurrentLeverage(res.data.leverage)
  setCurrentLongLeverage(res.data.leverageLong)
  setCurrentShortLeverage(res.data.leverageShort)
}

function setCurrentLeverage(leverage: number) {
  currentLeverage = leverage
}

function setCurrentLongLeverage(leverage: number) {
  currentLongLeverage = leverage
}

function setCurrentShortLeverage(leverage: number) {
  currentShortLeverage = leverage
}

function setCurrentPriceType(type: FutureOrderPriceType) {
  currentPriceType = type
}

function setCurrentDelegateType(type: FutureOrderDelegateType) {
  currentDelegateType = type
}

function setTradeFormData(form: Partial<typeof initialForm>) {
  Object.assign(tradeFormData, form)
}

function resetForm() {
  tradeFormData = { ...initialForm }
}

export function useFutureTrading() {
  return {
    currentOrderSide: $$(currentOrderSide),
    currentPositionType: $$(currentPositionType),
    currentLeverage: $$(currentLeverage),
    currentLongLeverage: $$(currentLongLeverage),
    currentShortLeverage: $$(currentShortLeverage),
    currentPriceType: $$(currentPriceType),
    currentOrderType: $$(currentOrderType),
    currentDelegateType: $$(currentDelegateType),
    currentTIF: $$(currentTIF),
    usePercentQuantity: $$(usePercentQuantity),
    useStopLimit: $$(useStopLimit),
    useTrackTrigger,
    tradeFormData: $$(tradeFormData),
    currentPercent: $$(currentPercent),
    quickCreateOrderDragging,

    setUseTrackTrigger,
    setTradeFormData,
    setCurrentOrderSide,
    toggleQuickCreateOrderDragging,
    setCurrentPositionType,
    setCurrentLeverage,
    setCurrentLongLeverage,
    setCurrentShortLeverage,
    setCurrentPercent,
    getCurrentLeverage,
    resetForm,
    setCurrentPriceType,
    setCurrentOrderType,
    setCurrentDelegateType,
    setUsePercentQuantity,
    setUseStopLimit,
    setCurrentTIF
  }
}
