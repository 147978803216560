<script setup lang="ts">import { computed as _computed, toRef as _toRef, ref as _ref } from 'vue';

import { graphic } from 'echarts'
import { toThousand } from '../../utils/utils'
import { getMultipleKline } from '~/api/index'
import { useConfigStore } from '~/store/config'
import { useExchangeStore } from '~/store/exchange'
import type { QuoteWS } from '~/types/ws'
import { useFutureStore } from '~/store/future'
import type { Nullable } from '~/types/utils'
import type { NewIndexConfig } from '~/types'

const { push } = useRouter()
withDefaults(defineProps<{
  config: Nullable<NewIndexConfig.ConfigItem>
}>(), { config: null, })
const content = _computed(() => {
  return __props.config?.contentList?.[0]?.content
})

const configStore = useConfigStore()
const { usdtToCurrencyValue } = configStore
const __$temp_1 = (storeToRefs(configStore)),
  webConfig = _toRef(__$temp_1, 'webConfig'),
  tokenLogoGetter = _toRef(__$temp_1, 'tokenLogoGetter');
const { recommendSymbols } = webConfig.value!

const exchangeStore = useExchangeStore()
const __$temp_2 = (storeToRefs(exchangeStore)),
  exchangeSymbolMap = _toRef(__$temp_2, 'visibleSymbolMap');

const futureStore = useFutureStore()
const __$temp_3 = (storeToRefs(futureStore)),
  symbolMap = _toRef(__$temp_3, 'visibleSymbolMap');

let multipleKlineData: Record<string, QuoteWS.KlineInfo[]> = _ref({})

async function getKlineData() {
  const { err, res } = await getMultipleKline({
    symbol: recommendSymbols.map(item => item.symbolId).join(','),
    exchangeId: recommendSymbols[0]?.exchangeId ?? '',
    interval: '1m',
    limit: 20,
    realtimeInterval: webConfig.value?.realtimeInterval ?? '24h'
  })

  if (err)
    return

  multipleKlineData.value = res.data
}
const goExchange = (
  baseToken: string,
  quoteToken: string,
  symbolId: string
) => {
  const isFuture = symbolMap.value[symbolId]

  if (isFuture)
    push(`/futures/${symbolId}`)
  else push(`/exchange/${baseToken}/${quoteToken}`)
}
const isSpotToken = (symbolId: string) => {
  return !symbolMap.value[symbolId]
}

getKlineData()

const ONE_MIN = 60 * 1000
const LOOP_INTERVAL = 200

useIntervalFn(() => {
  Object.entries(multipleKlineData.value).forEach(([symbolId, klineList]) => {
    const tickerInfo = isSpotToken(symbolId)
      ? exchangeSymbolMap.value[symbolId]?.tickerInfo
      : symbolMap.value[symbolId]?.tickerInfo

    const lastBar = klineList.at(-1)

    if (tickerInfo?.time && lastBar?.t) {
      if (
        new BigNumber(tickerInfo.time)
          .dividedBy(ONE_MIN)
          .integerValue(BigNumber.ROUND_DOWN)
          .multipliedBy(ONE_MIN)
          .minus(lastBar.t)
          .isGreaterThan(0)
      ) {
        klineList.shift()
        klineList.push({
          t: tickerInfo.time,
          o: tickerInfo.open,
          c: tickerInfo.close,
          h: tickerInfo.high,
          l: tickerInfo.low,
          s: symbolId,
          sn: symbolId,
          v: tickerInfo.volume
        })
      }
    }
  })
}, LOOP_INTERVAL)

const getSymbolInfo = (symbolId: string) => {
  const isFuture = symbolMap.value[symbolId]

  if (isFuture)
    return symbolMap.value[symbolId]
  else return exchangeSymbolMap.value[symbolId]
}

const getChartOptions = _computed(() => {
  return (symbolId: string) => {
    const klineData = multipleKlineData.value[symbolId]
    const symbolInfo = getSymbolInfo(symbolId)
    const isGrow = new BigNumber(
      symbolInfo?.tickerInfo?.close
    ).isGreaterThanOrEqualTo(symbolInfo?.tickerInfo?.open)
    const stop1Color = useCssVar(
      isGrow ? '--ex-grow-light-5' : '--ex-down-light-5'
    )
    const stop2Color = useCssVar(isGrow ? '--ex-grow' : '--ex-down')

    if (!klineData?.length || !symbolInfo)
      return {}

    return {
      grid: {
        left: 0,
        right: 5,
        top: 5,
        bottom: 4
      },
      xAxis: {
        type: 'category',
        data: klineData.map(item => item.t),
        show: false
      },
      yAxis: {
        type: 'value',
        show: false,
        min: 'dataMin',
        max: 'dataMax'
      },
      color: [
        {
          type: 'linear',
          x: 0,
          y: 0,
          x2: 1,
          y2: 0,
          colorStops: [
            {
              offset: 0,
              color: stop1Color.value // 0% 处的颜色
            },
            {
              offset: 1,
              color: stop2Color.value // 100% 处的颜色
            }
          ],
          global: false // 缺省为 false
        }
      ],

      series: [
        {
          data: klineData.map(item => item.c),
          type: 'line',
          smooth: true,
          symbol: (value: string, params: { dataIndex: number }) => {
            if (params.dataIndex === klineData.length - 1)
              return 'emptyCircle'

            return 'none'
          },
          areaStyle: {
            color: new graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: stop2Color.value
              },
              {
                offset: 1,
                color: 'rgba(255, 255, 255, 0)'
              }
            ])
          }
          // symbolSize: 8,
          // lineStyle: {
          //   cap: 'round'
          // }
        }
      ]
    }
  }
})

</script>

<template>
  <div
    flex
    flex-col
    font-mo-regular
    class="home-market"
    py-4
    :style="{ background: content?.background ?? '' }"
  >
    <div content>
      <el-row :gutter="4">
        <el-col
          v-for="symbol in recommendSymbols.slice(0, 4)!"
          :key="symbol.symbolId"
          :span="6"
        >
          <el-card
            shadow="hover"
            class="!rounded-md !b-none cursor-pointer !bg-transparent market-table-row"
            @click="
              goExchange(
                symbol.baseTokenId,
                symbol.quoteTokenId,
                symbol.symbolId
              )
            "
          >
            <div flex>
              <div
                flex-1
                flex
                items-center
              >
                <div>
                  <img
                    :src="isSpotToken(symbol.symbolId)
                      ? tokenLogoGetter(symbol.baseTokenId)
                      : symbolMap[symbol.symbolId]?.iconUrl
                    "
                    alt=""
                    class="w-8 h-8 mr-2"
                  >
                </div>
                <div>
                  <div
                    flex
                    c="$ex-mainTitle"
                    items-center
                  >
                    <span text-sm>{{
                      isSpotToken(symbol.symbolId)
                        ? `${symbol.baseTokenId}${symbol.quoteTokenId}`
                        : `${symbol.secondLevelUnderlyingId}${symbol.quoteTokenId
                        }`
                    }}</span>
                  </div>
                  <div pt-2>
                    <p
                      c="$ex-mainTitle"
                      text-lg
                    >
                      {{
                        usdtToCurrencyValue(
                          getSymbolInfo(symbol.symbolId)?.tickerInfo?.close,
                          {
                            withPrefix: true,
                            transferCb: toThousand
                          }
                        )
                      }}
                    </p>
                  </div>
                </div>
              </div>
              <div
                basis-20
                shrink-0
              >
                <line-chart
                  :option="getChartOptions(symbol.symbolId)"
                  :height="56"
                />
              </div>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
