<script setup lang="ts">import { toRef as _toRef } from 'vue';

import { useConfigStore } from '~/store/config'

interface TooltipInputProps {
  contentText?: string
  textClass?: string
  popperClass?: string
}
defineProps<TooltipInputProps>()
const store = useConfigStore()
const __$temp_1 = (storeToRefs(store)),
  useDarkMode = _toRef(__$temp_1, 'useDarkMode');
</script>

<template>
  <div class="tooltips-text leading-4">
    <el-tooltip
      placement="top"
      v-bind="$attrs"
      :effect="useDarkMode ? 'dark' : 'light'"
      :popper-class="`${popperClass || ''} tooltips-text-popper`"
    >
      <span :class="textClass" class="border-b border-current border-dashed" cursor-help>
        <slot>{{ contentText }}</slot>
      </span>
      <template v-if="$slots.content" #content>
        <slot name="content" />
      </template>
    </el-tooltip>
  </div>
</template>
