import type { DirectiveBinding, ObjectDirective, VNode } from 'vue'
import type { Nullable } from '~/types/utils'
import { debounce, isObject } from '~/utils/utils'
export type BindingValue = number | string | [number | string, number | string]
export interface LimitBindingValue { min?: number; max?: number; debounce?: boolean; debounceTime?: number; getLimit?: () => { min?: number; max?: number }; value: BindingValue }
interface NumberInputBinding {
  float: boolean
  allowNegative: boolean
  isNegative: boolean
  elinput: boolean

  value: BindingValue | LimitBindingValue
}
const debounceMap: Map<VNode, () => void> = new Map()

function onInput(
  el: HTMLElement,
  ele: HTMLInputElement,
  binding: DirectiveBinding<NumberInputBinding>,
  vnode: VNode
) {
  function handle() {
    let IntegerLength = 0 // 期望的最多整数位
    // 只保留数字
    let val = ele.value
    const isNegative = val.startsWith('-')
    const numberLimit: { min?: number; max?: number } = {}
    // modifiers为修饰符对象，传入了float，则其float属性为true
    if (binding.modifiers.float) {
      // 清除"数字"和"."以外的字符
      val = val.replace(/[^\d.]/g, '')
      // 只保留第一个, 清除多余的
      val = val.replace(/\.{2,}/g, '.')
      const frontVal = val.slice(0, val.indexOf('.') + 1)
      const endVal = val.slice(val.indexOf('.') + 1).replace(/\./g, '')
      val = frontVal + endVal
      // 第一个字符如果是.号，则补充前缀0
      val = val.replace(/^\./g, '0.')
      let bindingValue: BindingValue = binding.value as unknown as BindingValue
      if (typeof binding.value !== 'undefined') {
        // 期望保留的最大小数位数
        let pointKeep = 0

        if (isObject(binding.value)) {
          if ('min' in binding.value)
            numberLimit.min = (binding.value as LimitBindingValue).min

          if ('max' in binding.value)
            numberLimit.max = (binding.value as LimitBindingValue).max

          if ('getLimit' in binding.value) {
            const limit = (binding.value as LimitBindingValue).getLimit!()

            if ('min' in limit)
              numberLimit.min = limit.min

            if ('max' in limit)
              numberLimit.max = limit.max
          }

          if (binding.value.value)
            bindingValue = binding.value.value as unknown as BindingValue
        }

        if (
          typeof bindingValue === 'string'
          || typeof bindingValue === 'number'
        ) {
          pointKeep = parseInt(String(bindingValue))
        }
        else if (Array.isArray(bindingValue)) {
          pointKeep = parseInt(`${bindingValue[1]}`)
          IntegerLength = parseInt(`${bindingValue[0]}`)
        }

        if (!isNaN(pointKeep)) {
          if (!Number.isInteger(pointKeep) || pointKeep < 0)
            pointKeep = 0

          const str = `^(\\d+)\\.(\\d{${pointKeep}}).*$`
          const reg = new RegExp(str)
          if (pointKeep === 0) {
            // 不需要小数点
            val = val.replace(reg, '$1')
          }
          else {
            // 通过正则保留小数点后指定的位数
            val = val.replace(reg, '$1.$2')
          }
        }
      }
    }
    else {
      val = ele.value.replace(/[^\d]/g, '')
    }
    if (val) {
      // 获取整数
      let integerNumber = val.split('.')[0]
      if (integerNumber.length > 1) {
        integerNumber
          = integerNumber.slice(0, -1).replace(/^0*/, '')
          + integerNumber.slice(-1)
        if (IntegerLength) {
          // 如果有整数位最多的限制
          if (+integerNumber > 0)
            integerNumber = integerNumber.slice(0, IntegerLength)
        }
      }
      if (val.split('.').length > 1) {
        // 说明有小数点
        val = `${integerNumber}.${val.split('.')[1]}`
      }
      else {
        val = integerNumber
      }
    }

    if (binding.modifiers.allowNegative) {
      if (isNegative)
        val = `-${val}`
    }
    else if (binding.modifiers.isNegative) {
      if (val)
        val = `-${val}`
    }

    if ('min' in numberLimit && new BigNumber(val).lt(numberLimit.min!))
      val = `${numberLimit.min}`
    if ('max' in numberLimit && new BigNumber(val).gt(numberLimit.max!))
      val = `${numberLimit.max}`

    function setVal() {
      ele.value = val
      if (binding.instance) {
        try {
          // binding.instance.$refs.input = val
          if (binding.arg) {
            // @ts-expect-error
            binding.instance.$refs[binding.arg].value = val
            // @ts-expect-error
            binding.instance.$refs[binding.arg].$emit('input', val)
            // @ts-expect-error
            binding.instance.$refs[binding.arg].$emit('update:modelValue', val)
          }
          else {
            // @ts-expect-error
            binding.instance.$refs.input.value = val
            // @ts-expect-error
            binding.instance.$refs.input.$emit('input', val)
            // @ts-expect-error
            binding.instance.$refs.input.$emit('update:modelValue', val)
          }
        }
        catch (e) { }
      }
    }
    // if (binding.modifiers.lazy) {
    //   let debounceFunc: () => void
    //   if (debounceMap.get(vnode)) { debounceFunc = debounceMap.get(vnode)! }
    //   else {
    //     debounceFunc = debounce(setVal, 100)
    //     debounceMap.set(vnode, debounceFunc)
    //   }

    //   debounceFunc()
    // }
    // else {
    setVal()
    // }
  }
  return handle
}

export const numberInput: ObjectDirective<
HTMLElement,
NumberInputBinding
> = {

  beforeMount(el, binding, vnode) {
    let ele = el.tagName === 'INPUT' ? el : el.querySelector('input')
    if (binding.modifiers.elinput) {
      try {
        const element = Array.from(el.children).find(
          element => element.tagName === 'INPUT'
        ) as Nullable<HTMLInputElement>

        ele = element ?? null
      }
      catch (error) { }
    }
    if (!ele)
      return

    let handler = onInput(el, ele as HTMLInputElement, binding, vnode)
    if (isObject(binding.value) && 'debounce' in binding.value && binding.value.debounce)
      handler = debounce(handler, (binding.value.debounceTime as unknown as number) || 200)

    // @ts-expect-error 需要挂载额外属性
    el.ele = ele
    // @ts-expect-error 需要挂载额外属性
    el.inputHandler = onInput
    ele.addEventListener('input', handler, false)
  },
  beforeUnmount(el, binding, vnode) {
    // @ts-expect-error
    (el.ele! as HTMLElement).removeEventListener('input', el.inputHandler)
  }
}
