<script setup lang="ts">import { computed as _computed } from 'vue';

import gsap from 'gsap'
import type { Nullable } from '~/types/utils'
import { NewIndexConfig } from '~/types'

withDefaults(defineProps<{
  config: Nullable<NewIndexConfig.ConfigItem>
}>(), { config: null, })
const content = _computed(() => {
  return __props.config?.contentList?.[0]?.content
})
const layout = _computed(() => {
  const layoutMap: Record<
  NewIndexConfig.LayoutMode,
  {
    left: {
      image: number
      desc: number
    }
    right: {
      image: number
      desc: number
    }
  }
  > = {
    [NewIndexConfig.LayoutMode.IMAGE_TOP]: {
      left: {
        image: 1,
        desc: 2
      },
      right: {
        image: 1,
        desc: 2
      }
    },
    [NewIndexConfig.LayoutMode.IMAGE_BOTTOM]: {
      left: {
        image: 2,
        desc: 1
      },
      right: {
        image: 2,
        desc: 1
      }
    },
    [NewIndexConfig.LayoutMode.IMAGE_LEFT_BOTTOM_RIGHT_TOP]: {
      left: {
        image: 2,
        desc: 1
      },
      right: {
        image: 1,
        desc: 2
      }
    },
    [NewIndexConfig.LayoutMode.IMAGE_LEFT_TOP_RIGHT_BOTTOM]: {
      left: {
        image: 1,
        desc: 2
      },
      right: {
        image: 2,
        desc: 1
      }
    }
  }

  return layoutMap[__props.config?.layoutMode || NewIndexConfig.LayoutMode.IMAGE_TOP]
})

onMounted(() => {
  gsap.from('.left-introduction-image', {
    x: -150,
    duration: 0.5,
    opacity: 0,
    scrollTrigger: {
      trigger: '.home-introduction',
      start: 'top 30%'
    }
  })
  gsap.from('.right-introduction-image', {
    x: 150,
    duration: 0.5,
    opacity: 0,
    scrollTrigger: {
      trigger: '.home-introduction',
      start: 'top 30%'
    }
  })
  gsap.from('.left-title', {
    x: -150,
    duration: 0.5,
    opacity: 0,
    scrollTrigger: {
      trigger: '.home-introduction',
      start: 'top 30%'
    }
  })
  gsap.from('.left-desc', {
    x: 150,
    duration: 0.5,
    opacity: 0,
    stagger: 0.15,
    scrollTrigger: {
      trigger: '.home-introduction',
      start: 'top 30%'
    }
  })
  gsap.from('.right-title', {
    x: 150,
    duration: 0.5,
    opacity: 0,
    scrollTrigger: {
      trigger: '.home-introduction',
      start: 'top 30%'
    }
  })
  gsap.from('.right-desc', {
    x: -150,
    duration: 0.5,
    stagger: 0.15,
    opacity: 0,
    scrollTrigger: {
      trigger: '.home-introduction',
      start: 'top 30%'
    }
  })
})
</script>

<template>
  <div class="home-introduction">
    <div content pt-14 pb-11>
      <h3 text-8 font-bold>
        {{ content?.title }}
      </h3>
      <div flex mt-6>
        <div
          flex-1
          flex
          flex-col
          justify-between
          rounded-md
          px-9
          py-6
          mr-16
          overflow-hidden
          :style="{ backgroundColor: content?.leftPart?.background }"
        >
          <img
            v-if="content?.leftPart?.image"
            class="left-introduction-image"
            :src="content?.leftPart?.image"
            w-full
            :style="{
              order: layout.left.image
            }"
          >
          <div :style="{ order: layout.left.desc }">
            <div v-for="item in content?.leftPart?.list" :key="item.desc" pt-6>
              <h3 text-7 font-bold class="left-title">
                {{ item.title }}
              </h3>
              <ul my-5 font-bold>
                <li v-for="desc in item.desc?.split(/\n/)" :key="desc" class="left-desc">
                  · {{ desc }}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div
          flex-1
          flex
          flex-col
          justify-between
          rounded-md
          px-9
          py-6
          :style="{ backgroundColor: content?.rightPart?.background }"
          overflow-hidden
        >
          <img
            v-if="content?.rightPart?.image"
            :src="content?.rightPart?.image"
            class="right-introduction-image"
            w-full
            :style="{
              order: layout.right.image
            }"
          >
          <div :style="{ order: layout.right.desc }">
            <div v-for="item in content?.rightPart?.list" :key="item.desc" pt-6>
              <h3 text-7 font-bold class="right-title">
                {{ item.title }}
              </h3>
              <ul my-5 font-bold>
                <li v-for="desc in item.desc?.split(/\n/)" :key="desc" class="right-desc">
                  · {{ desc }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
