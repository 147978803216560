import type { MaybeRef } from '@vueuse/core'
import type { SpotOrderParams, SpotTradeParams } from '~/api'
import {
  getSpotHistoryOrder,
  getSpotHistoryTrade,
  getSpotOrder,
  getSpotPlanHistoryOrder,
  getSpotPlanOrder
} from '~/api'

import { SpotOrderStatus } from '~/types/enums'
import type { QuoteWS } from '~/types/ws'
import { excludeRepeatArray } from '~/utils/helper'

let currentOrders = $ref<QuoteWS.SpotOrderInfo[]>([])
let currentPlanOrders = $ref<QuoteWS.SpotPlanOrderInfo[]>([])
let historyOrders = $ref<QuoteWS.SpotOrderInfo[]>([])
let historyPlanOrders = $ref<QuoteWS.SpotPlanOrderInfo[]>([])
let historyTradeOrders = $ref<QuoteWS.SpotTradeInfo[]>([])

const [currentOrderLoading, toggleCurrentOrderLoading] = $(useToggle(false))
const [currentPlanOrderLoading, toggleCurrentPlanOrderLoading] = $(
  useToggle(false)
)
const [historyOrderLoading, toggleHistoryOrderLoading] = $(useToggle(false))
const [historyPlanOrderLoading, toggleHistoryPlanOrderLoading] = $(
  useToggle(false)
)
const [historyTradeOrderLoading, toggleHistoryTradeOrderLoading] = $(
  useToggle(false)
)

const [noMoreCurrentOrder, toggleNoMoreCurrentOrder] = $(useToggle(false))
const [noMoreCurrentPlanOrder, toggleNoMoreCurrentPlanOrder] = $(
  useToggle(false)
)
const [noMoreHistoryOrder, toggleNoMoreHistoryOrder] = $(useToggle(false))
const [noMoreHistoryPlanOrder, toggleNoMoreHistoryPlanOrder] = $(
  useToggle(false)
)
const [noMoreHistoryTradeOrder, toggleNoMoreHistoryTradeOrder] = $(
  useToggle(false)
)

const showAllSymbolOrder = $ref(false)

export function useExchangeOrderData(symbolId: MaybeRef<string>) {
  const symbolIdRef = $ref(symbolId)

  const filterCurrentOrders = computed(() => {
    if (showAllSymbolOrder)
      return currentOrders

    return currentOrders.filter((item) => {
      return item.symbolId === symbolIdRef
    })
  })

  const filterCurrentPlanOrders = computed(() => {
    if (showAllSymbolOrder)
      return currentPlanOrders

    return currentPlanOrders.filter((item) => {
      return item.symbolId === symbolIdRef
    })
  })

  const filterHistoryOrders = computed(() => {
    if (showAllSymbolOrder)
      return historyOrders

    return historyOrders.filter((item) => {
      return item.symbolId === symbolIdRef
    })
  })

  const filterHistoryPlanOrders = computed(() => {
    if (showAllSymbolOrder)
      return historyPlanOrders

    return historyPlanOrders.filter((item) => {
      return item.symbolId === symbolIdRef
    })
  })

  const filterHistoryTradeOrders = computed(() => {
    if (showAllSymbolOrder)
      return historyTradeOrders

    return historyTradeOrders.filter(item => item.symbolId === symbolIdRef)
  })

  const dealOrderData = (orderData: QuoteWS.SpotOrderInfo[]) => {
    orderData.forEach((order) => {
      // 状态为NEW和PARTIAL_FILLED的订单视为当前委托
      if (
        [SpotOrderStatus.NEW, SpotOrderStatus.PARTIALLY_FILLED].includes(
          order.status
        )
      ) {
        if (
          !currentOrders.length
          || (currentOrders.length && +order.time - +currentOrders[0].time > 0)
        ) {
          currentOrders.unshift(order)
        }
        else {
          const sameOrder = currentOrders.find(
            item => item.orderId === order.orderId
          )

          if (sameOrder)
            Object.assign(sameOrder, order)
          else currentOrders.push(order)
        }
      }

      // 状态为FILLED和CANCELED的订单视为历史委托
      if (
        [SpotOrderStatus.FILLED, SpotOrderStatus.CANCELED].includes(
          order.status
        )
      ) {
        currentOrders = currentOrders.filter(
          item => item.orderId !== order.orderId
        )

        if (
          !historyOrders.length
          || (historyOrders.length && +order.time - +historyOrders[0].time > 0)
        ) {
          historyOrders.unshift(order)
        }
        else {
          const sameOrder = historyOrders.find(
            item => item.orderId === order.orderId
          )

          if (sameOrder)
            Object.assign(sameOrder, order)
          else historyOrders.push(order)
        }
      }
    })

    // 去除重复订单
    currentOrders = excludeRepeatArray('orderId', currentOrders)
    currentOrders.sort((a, b) => +b.time - +a.time)

    historyOrders = excludeRepeatArray('orderId', historyOrders)
    historyOrders.sort((a, b) => +b.time - +a.time)
  }
  const dealPlanOrderData = (orderData: QuoteWS.SpotPlanOrderInfo[]) => {
    orderData.forEach((order) => {
      // 状态为NEW和PARTIAL_FILLED的订单是当前的计划委托
      if (
        [
          SpotOrderStatus.ORDER_NEW,
          SpotOrderStatus.ORDER_PARTIALLY_FILLED
        ].includes(order.status)
      ) {
        if (
          !currentPlanOrders.length
          || (currentPlanOrders.length
            && +order.time - +currentPlanOrders[0].time > 0)
        ) {
          currentPlanOrders.unshift(order)
        }
        else {
          const sameOrder = currentPlanOrders.find(
            item => item.orderId === order.orderId
          )

          if (sameOrder)
            Object.assign(sameOrder, order)
          else currentPlanOrders.push(order)
        }
      }

      // 状态为FILLED和CANCELED和REJECTED的订单视为历史委托
      if (
        [SpotOrderStatus.ORDER_CANCELED, SpotOrderStatus.ORDER_FILLED, SpotOrderStatus.ORDER_REJECTED].includes(
          order.status
        )
      ) {
        currentPlanOrders = currentPlanOrders.filter(
          item => item.orderId !== order.orderId
        )

        if (
          !historyPlanOrders.length
          || (historyPlanOrders.length
            && +order.time - +historyPlanOrders[0].time > 0)
        ) {
          historyPlanOrders.unshift(order)
        }
        else {
          const sameOrder = historyPlanOrders.find(
            item => item.orderId === order.orderId
          )

          if (sameOrder)
            Object.assign(sameOrder, order)
          else historyPlanOrders.push(order)
        }
      }
    })

    // 去除重复订单
    currentPlanOrders = excludeRepeatArray('orderId', currentPlanOrders)
    currentPlanOrders.sort((a, b) => +b.time - +a.time)

    historyPlanOrders = excludeRepeatArray('orderId', historyPlanOrders)
    historyPlanOrders.sort((a, b) => +b.time - +a.time)
  }

  const orderDataHandler = (data: QuoteWS.SpotOrderData) => {
    if (data.topic !== 'order')
      return

    const orderData = data.data
    if (!orderData?.length)
      return

    dealOrderData(orderData)
  }

  const planOrderDataHandler = (data: QuoteWS.SpotPlanOrderData) => {
    if (data.topic !== 'plan_order')
      return

    const orderData = data.data
    if (!orderData?.length)
      return

    dealPlanOrderData(orderData)
  }

  const matchDataHandler = (data: QuoteWS.SpotTradeData) => {
    if (!['match'].includes(data.topic))
      return

    const orderData = data.data
    if (!orderData?.length)
      return

    historyTradeOrders.unshift(...orderData)
    // 去除重复订单
    historyTradeOrders = excludeRepeatArray(
      'tradeId',
      historyTradeOrders,
      'time'
    )
    historyTradeOrders.sort((a, b) => +b.time - +a.time)
  }

  const orderHttpAction = async (
    isPlanRequest = false,
    params: Partial<SpotOrderParams> = {}
  ) => {
    const loadingStatus = isPlanRequest
      ? currentPlanOrderLoading
      : currentOrderLoading
    const toggleAction = isPlanRequest
      ? toggleCurrentPlanOrderLoading
      : toggleCurrentOrderLoading
    const isNoMoreData = isPlanRequest
      ? noMoreCurrentPlanOrder
      : noMoreCurrentOrder
    const toggleIsNoMoreData = isPlanRequest
      ? toggleNoMoreCurrentPlanOrder
      : toggleNoMoreCurrentOrder

    if (loadingStatus || (isNoMoreData && params.from_order_id))
      return

    const method = isPlanRequest ? getSpotPlanOrder : getSpotOrder

    const mergedParams = {
      limit: 50,
      ...params
    }

    toggleAction(true)
    const { res, err } = await method(mergedParams)
    toggleAction(false)

    if (err)
      return

    if (new BigNumber(res.data.length).isLessThan(mergedParams.limit))
      toggleIsNoMoreData(true)

    if (isPlanRequest)
      dealPlanOrderData(res.data as QuoteWS.SpotPlanOrderInfo[])
    else dealOrderData(res.data as QuoteWS.SpotOrderInfo[])
  }

  const getHistoryEntrustData = async (
    isPlanRequest = false,
    params: Partial<SpotOrderParams> = {}
  ) => {
    const loadingStatus = isPlanRequest
      ? historyPlanOrderLoading
      : historyOrderLoading
    const toggleAction = isPlanRequest
      ? toggleHistoryPlanOrderLoading
      : toggleHistoryOrderLoading
    const isNoMoreData = isPlanRequest
      ? noMoreHistoryPlanOrder
      : noMoreHistoryOrder
    const toggleIsNoMoreData = isPlanRequest
      ? toggleNoMoreHistoryPlanOrder
      : toggleNoMoreHistoryOrder

    if (loadingStatus || (isNoMoreData && params.from_order_id))
      return

    const method = isPlanRequest ? getSpotPlanHistoryOrder : getSpotHistoryOrder

    const mergedParams = {
      limit: 50,
      ...params
    }

    toggleAction(true)
    const { res, err } = await method(mergedParams)
    toggleAction(false)

    if (err)
      return

    if (new BigNumber(res.data.length).isLessThan(mergedParams.limit))
      toggleIsNoMoreData(true)

    if (isPlanRequest)
      dealPlanOrderData(res.data as QuoteWS.SpotPlanOrderInfo[])
    else dealOrderData(res.data as QuoteWS.SpotOrderInfo[])
  }

  const getHistoryTradeData = async (params: Partial<SpotTradeParams> = {}) => {
    if (
      historyTradeOrderLoading
      || (noMoreHistoryTradeOrder && params.from_trade_id)
    )
      return

    const mergedParams: SpotTradeParams = {
      limit: 50,
      ...params
    }

    toggleHistoryTradeOrderLoading(true)
    const { res, err } = await getSpotHistoryTrade(mergedParams)
    toggleHistoryTradeOrderLoading(false)

    if (err)
      return

    if (new BigNumber(res.data.length).isLessThan(mergedParams.limit))
      toggleNoMoreHistoryTradeOrder(true)

    historyTradeOrders.unshift(...res.data)

    // 去除重复订单
    historyTradeOrders = excludeRepeatArray(
      'tradeId',
      historyTradeOrders,
      'time'
    )
    historyTradeOrders.sort((a, b) => +b.time - +a.time)
  }

  return {
    currentOrders: $$(currentOrders),
    currentPlanOrders: $$(currentPlanOrders),
    historyPlanOrders: $$(historyPlanOrders),
    historyOrders: $$(historyOrders),
    historyTradeOrders: $$(historyTradeOrders),
    showAllSymbolOrder: $$(showAllSymbolOrder),

    currentOrderLoading: $$(currentOrderLoading),
    currentPlanOrderLoading: $$(currentPlanOrderLoading),
    historyOrderLoading: $$(historyOrderLoading),
    historyPlanOrderLoading: $$(historyPlanOrderLoading),
    historyTradeOrderLoading: $$(historyTradeOrderLoading),
    noMoreCurrentOrder: $$(noMoreCurrentOrder),
    noMoreCurrentPlanOrder: $$(noMoreCurrentPlanOrder),
    noMoreHistoryOrder: $$(noMoreHistoryOrder),
    noMoreHistoryPlanOrder: $$(noMoreHistoryPlanOrder),
    noMoreHistoryTradeOrder: $$(noMoreHistoryTradeOrder),

    filterCurrentOrders,
    filterHistoryOrders,
    filterHistoryTradeOrders,
    filterCurrentPlanOrders,
    filterHistoryPlanOrders,

    orderDataHandler,
    planOrderDataHandler,
    matchDataHandler,
    getHistoryTradeData,
    getHistoryEntrustData,
    orderHttpAction
  }
}
