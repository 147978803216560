import { defineStore } from 'pinia'
import { SubAccountType } from '../types/enums'
import {
  getBaseInfo,
  getFavoriteList,
  getInvitationAgentInfo,
  getInvitationInfo,
  getSubAccounts,
  getTakeConfig,
  getUserKycLevelInfo,
  getUserOpenInfo,
  getUserPushSetting,
  getUserSetting,
  getVerifyInfo,
  setUserSetting
} from '~/api'
import { MAIN_ACCOUNT_INDEX } from '~/config/const'
import { ACCOUNT_TYPE_NAME } from '~/config/enums'
import type {
  AgentInfo,
  BaseInfo,
  InvitationInfo,
  SubAccountList,
  UserAuthorize,
  UserCommonConfig,
  UserOpenInfo,
  UserSetting,
  UserTakeConfig
} from '~/types'
import { PageColorType } from '~/types/enums'
import type { JSONString } from '~/types/utils'
import Cookie from '~/utils/cookie'
import { parse } from '~/utils/utils'
import { useConfigStore } from '~/store/config'
import { useExchangeStore } from '~/store/exchange'
import { useFutureStore } from '~/store/future'
import type { KycVerifyInfo } from '~/types/index'

interface UserStoreModel {
  isLogin: boolean
  username: string
  baseInfo: BaseInfo
  userOpenInfo: UserOpenInfo
  accountId: string
  userSetting:
    | UserSetting
    | {
      commonConfig: UserCommonConfig
    }
  userTakeConfig: UserTakeConfig
  subAccounts: SubAccountList
  loginVerify: UserAuthorize
  kycLevelInfo: Array<{
    displayLevel: string
    allowVerify: boolean
  }>
  verifyInfo: KycVerifyInfo
  pushSetting: Array<{
    switchType: number
    status: number // 0 开启 1 关闭
  }>
  inviteInfo: InvitationInfo
  agentInfo: AgentInfo
}
export const useUserStore = defineStore('user', {
  state: (): UserStoreModel => {
    return {
      loginVerify: {} as UserAuthorize,
      isLogin: false,
      subAccounts: [],
      userTakeConfig: {
        userId: '0',
        accountId: '0',
        symbolIdList: [],
        drawScale: '',
        positionType: 0,
        transactionType: 0,
        totalFollowAmount: '0',
        followCount: 0,
        takeProfit: '',
        followCountLimit: 75,
        isTakeUser: 0
      },
      userOpenInfo: {
        nickName: '',
        photo: '',
        introduce: '',
        isModify: true
      },
      username: '',
      userSetting: {
        commonConfig: {
          quickCloseConfirm: false,
          lang: localStorage.lang as string,
          unit: localStorage.unit as string,
          up_down: PageColorType.GREEN_UP
        },
        userId: '',
        updated: '',
        created: ''
      },
      baseInfo: {
        userId: '',
        registerType: 0,
        mobile: '',
        nationalCode: '',
        email: '',
        bindGA: false,
        bindTradePwd: false,
        bindPassword: false,
        userType: 0,
        verifyStatus: 0,
        kycLevel: 0,
        kycVerifyStatus: 0,
        displayLevel: '',
        accounts: [],
        defaultAccountId: '',
        registerDate: '',
        openOption: false,
        openFuture: false,
        openMargin: false,
        favorites: [],
        source: '',
        isAgent: 0,
        customLabelList: [],
        levelNames: [],
        leader: 0,
        isHobbit: 0,
        antiPhishingCode: '',
        isComplianceVerify: false,
        lastLoginDate: '',
        lastLoginIp: '',
        platfrom: ''
      },
      accountId: Cookie.get('account_id') ?? '',
      kycLevelInfo: [], // kyc级别信息，
      verifyInfo: {} as KycVerifyInfo,
      pushSetting: [], // 站内信推送设置
      inviteInfo: {
        inviteCode: '',
        inviteCount: 0,
        inviteDirectVaildCount: 0,
        inviteIndirectVaildCount: 0
      },
      agentInfo: {
        coinAssets: '',
        futuresAssets: '',
        inviteNum: '',
        isShowProfitLoss: false,
        profitLoss: '',
        // 合约分佣比例可配置最大值
        rate: '0.99',
        // 币币分佣比例可配置最大值
        rateCoin: '0.99',
        // 合约分佣比例配置间隔
        rateInterval: 1,
        // 币币分佣比例配置间隔
        rateIntervalCoin: 1,
        // 合约分佣比例可配置最小值
        rateMin: '0.01',
        // 币币分佣比例可配置最小值
        rateMinCoin: '0.01',
        selfRebate: 0,
        tokenAmount: '',
        totalAssets: '',
        yesterdayDepositNum: '',
        yesterdayInviteNum: '',
        yesterdayTokenAmount: '',
        yesterdayWithdrawNum: ''
      }
    }
  },
  actions: {
    // 获取用户的推送设置
    async getUserPushSettingAction() {
      const { res } = await getUserPushSetting()
      if (res)
        this.pushSetting = res.data
    },
    setLoginVerify(payload: UserAuthorize) {
      this.loginVerify = payload
    },
    // 扫码登录成功
    qrCodeLoginSuccess(payload: BaseInfo, redirect: string) {
      this.baseInfo = payload
      window.localStorage.removeItem('resetpwdType')
      window.sessionStorage.userinfo = JSON.stringify(payload)
      this.setIsLogin(true)

      if (redirect)
        window.location.href = decodeURIComponent(redirect)
      else window.location.href = window.location.origin
    },
    async getFavoriteList() {
      const { res, err } = await getFavoriteList()

      if (err)
        return

      this.baseInfo.favorites = res.data
    },
    setIsLogin(payload: boolean) {
      this.isLogin = payload
    },
    setUserName(payload: string) {
      this.username = payload
    },
    async getSubAccountsData() {
      const { res, err } = await getSubAccounts()
      if (err)
        return

      const subAccounts = res.data

      this.subAccounts = subAccounts.map((item) => {
        if (
          item.accountIndex === 99
          && item.accountType === SubAccountType.FUTURE
        ) {
          return {
            ...item,
            accountName: '跟单账户'
          }
        }

        if (item.accountIndex === MAIN_ACCOUNT_INDEX) {
          return {
            ...item,
            accountName: ACCOUNT_TYPE_NAME[item.accountType]
          }
        }

        return item
      })
    },
    async getUserTakeConfig() {
      if (!this.isLogin)
        return

      const { res, err } = await getTakeConfig()
      if (err)
        return

      this.userTakeConfig = res.data
    },
    async setUserSetting(config: Partial<UserCommonConfig>) {
      const newConfig = {
        ...(this.userSetting.commonConfig as UserCommonConfig),
        ...config
      }
      if (!newConfig.lang)
        newConfig.lang = localStorage.lang as string

      if (!newConfig.unit)
        newConfig.unit = localStorage.unit as string

      const { err } = await setUserSetting({
        common_config: JSON.stringify(newConfig) as JSONString<UserCommonConfig>
      })
      if (err)
        return false

      await this.getUserSetting()
      useConfigStore().mergeTheme()

      return true
    },
    async getUserSetting() {
      const { res, err } = await getUserSetting()

      if (err)
        return

      const { data } = res
      // @ts-expect-error
      data.commonConfig = parse<string, UserCommonConfig>(
        (data.commonConfig as unknown as string) || '{}'
      ) as UserCommonConfig
      this.userSetting = data as UserSetting

      if (this.userSetting.commonConfig.up_down === undefined) {
        this.setUserSetting({ up_down: PageColorType.GREEN_UP })
      }
      else if (typeof this.userSetting.commonConfig.up_down === 'number') {
        this.setUserSetting({
          up_down: `${this.userSetting.commonConfig.up_down}`
        })
      }
      const { changeUnit } = useConfigStore()
      changeUnit(data.commonConfig.unit, false)

      useConfigStore().setLocale((data.commonConfig as UserCommonConfig).lang || localStorage.lang)
    },
    async getBaseInfo() {
      if (window.sessionStorage && sessionStorage[this.accountId])
        this.baseInfo = sessionStorage[this.accountId]

      const { res, err } = await getBaseInfo()

      if (err)
        return

      this.baseInfo = res.data
      sessionStorage[this.accountId] = JSON.stringify(res.data)
      if (this.baseInfo.registerType === 1)
        this.username = this.baseInfo.mobile || this.baseInfo.email
      else this.username = this.baseInfo.email || this.baseInfo.mobile
    },

    async getUserOpenInfoAction() {
      if (!Cookie.get('user_id'))
        return

      const { res } = await getUserOpenInfo({
        own_user_id: Cookie.get('user_id')
      })
      if (res)
        this.userOpenInfo = { ...res.data }
    },

    // 获取用户kyc级别信息
    async getKycLevelInfo() {
      if (!Cookie.get('user_id'))
        return

      const { res } = await getUserKycLevelInfo()

      if (res)
        this.kycLevelInfo = res.data
    },

    // 获取实名认证信息
    async getVerifyInfo() {
      if (!Cookie.get('user_id'))
        return

      const { res } = await getVerifyInfo()

      if (res)
        this.verifyInfo = res.data
    },
    // 获取邀请信息
    async getInviteInfo() {
      if (!Cookie.get('user_id'))
        return

      const { res } = await getInvitationInfo()
      if (res)
        this.inviteInfo = res.data
    },
    // 获取实名认证信息
    async getAgentInfo() {
      if (!Cookie.get('user_id'))
        return

      const { res } = await getInvitationAgentInfo()

      if (res)
        this.agentInfo = res.data
    }
  },

  getters: {
    userFavoriteSymbolList(state) {
      const exchangeStore = useExchangeStore()
      const futureStore = useFutureStore()

      const { symbolMap } = $(storeToRefs(exchangeStore))
      const { symbolMap: futureSymbolMap } = $(storeToRefs(futureStore))

      return state.baseInfo.favorites
        .map(item => item.symbolId)
        .filter(item => symbolMap[item] || futureSymbolMap[item])
    },
    // 三级kyc是否打开
    needVedioVerify(state) {
      const LeverThree = state.kycLevelInfo.find(
        item => +item.displayLevel === 3
      )
      let needVedio = false

      needVedio = LeverThree ? LeverThree.allowVerify : false

      return needVedio
    }
  },
  persist: {
    paths: ['userSetting']
  }
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot))
