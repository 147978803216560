import { ElNotification } from 'element-plus'
import { useConfigStore } from '~/store/config'
import { useExchangeStore } from '~/store/exchange'
import { useFutureStore } from '~/store/future'
import { PriceAlarmExchangeType, PriceAlarmType } from '~/types/enums'
import type { FuturesSymbolWithTickerInfo } from '~/types/futures'
import type { QuoteWS } from '~/types/ws'
import { saveDigital } from '~/utils/utils'
import { getFormatSubMessage } from '~/utils/ws'

export function usePriceAlarm() {
  const { t } = useI18n()

  function messageHandler(message: QuoteWS.PriceAlarmData) {
    if (message.topic !== 'system_message' || !message.data)
      return

    const futureStore = useFutureStore()
    const { getSymbolDisplayName, getPriceDigitBySymbol } = futureStore
    const { symbolMap } = $(storeToRefs(futureStore))

    const exchangeStore = useExchangeStore()
    const { symbolMap: exchangeSymbolMap, getPriceDigitBySymbol: getExchangePriceDigitBySymbol } = $(storeToRefs(exchangeStore))

    const isFuture = message.data.symbolType === PriceAlarmExchangeType.FUTURE
    const { reminderType, reminderValue, symbolId, currentPrice } = message.data
    const isPriceAlarm = [PriceAlarmType.PRICE_INCREASE, PriceAlarmType.PRICE_DECREASE].includes(reminderType)

    const symbolDigit = $computed(() => {
      return isFuture ? getPriceDigitBySymbol(symbolId) : getExchangePriceDigitBySymbol(symbolId)
    })
    function getSymbolNameBySymbolId(symbolId: string) {
      if (isFuture)
        return getSymbolDisplayName(symbolId)

      const symbolInfo = getSymbolInfoBySymbolId(symbolId)

      return `${symbolInfo.baseTokenId}/${symbolInfo.quoteTokenId}`
    }
    function getSymbolInfoBySymbolId(symbolId: string) {
      if (isFuture)
        return symbolMap[symbolId] as FuturesSymbolWithTickerInfo

      return exchangeSymbolMap[symbolId]
    }

    const titleTypeTextMap: Record<PriceAlarmType, string> = {
      [PriceAlarmType.PRICE_INCREASE]: '价格上涨',
      [PriceAlarmType.PRICE_DECREASE]: '价格下跌',
      [PriceAlarmType.RATE_INCREASE]: '价格涨幅',
      [PriceAlarmType.RATE_DECREASE]: '价格跌幅',
      [PriceAlarmType.DAILY_RATE_INCREASE]: '24小时涨幅',
      [PriceAlarmType.DAILY_RATE_DECREASE]: '24小时跌幅'

    }
    const title = `${getSymbolNameBySymbolId(message.data!.symbolId)} ${t(titleTypeTextMap[message.data.reminderType])}${t('提醒')}`

    const contentTextMap: Record<PriceAlarmType, string> = {
      [PriceAlarmType.PRICE_INCREASE]: '价格已上涨到',
      [PriceAlarmType.PRICE_DECREASE]: '价格已下跌到',
      [PriceAlarmType.RATE_INCREASE]: '价格涨幅已达',
      [PriceAlarmType.RATE_DECREASE]: '价格跌幅已达',
      [PriceAlarmType.DAILY_RATE_INCREASE]: '24小时涨幅已达',
      [PriceAlarmType.DAILY_RATE_DECREASE]: '24小时跌幅已达'

    }
    const content = $computed(() => {
      if (!message.data)
        return ''

      const prefixContent = `${t('您关注的{symbol},{type}{value}', {
        symbol: getSymbolNameBySymbolId(symbolId),
        type: t(contentTextMap[reminderType]),
        value: isPriceAlarm ? saveDigital(reminderValue, symbolDigit) : `${new BigNumber(reminderValue).multipliedBy(100).toString()}%`
      })}`
      const suffixContent = !isPriceAlarm ? `,${t('当前报价:{price}', { price: currentPrice })}` : ''

      return `${prefixContent}${suffixContent}`
    })

    ElNotification({
      title,
      message: h('p', { class: 'c-$ex-mainTitle text-xs', style: { textAlign: 'left' } }, content),
      customClass: 'price-alarm-notification'
    })
  }
  function sub() {
    const ws = useWS(WSTYPE.UWS)
    const configStore = useConfigStore()
    const { webConfig } = $(storeToRefs(configStore))
    const message = getFormatSubMessage('system_message', { orgId: webConfig?.orgId })

    ws.sub({
      payload: message,
      httpAction() { },
      callback(message: QuoteWS.PriceAlarmData) {
        try {
          messageHandler(message)
        }
        catch (e) {
        }
      }
    })
  }

  return {
    sub
  }
}
