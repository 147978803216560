import type { IResponse } from '~/api'
import type { ServerResponse } from '~/utils/axios'

export default function useESPagination<
  T extends (params: any) => Promise<IResponse<ServerResponse<any>>>,
  P extends Parameters<T>[0]
>(
  method: T,
  mainParams: Partial<P> = {},
  limitKey = 'limit'
) {
  type ResponseType = Awaited<ReturnType<T>>
  type InnerResponseType = ResponseType extends IResponse<ServerResponse<infer T>> ? T : never

  const list = ref<InnerResponseType>([] as InnerResponseType)
  const [loading, toggleLoading] = $(useToggle(false))
  const [noMoreData, toggleNoMoreData] = $(useToggle(false))

  const getList = async (params: Partial<P> = {}) => {
    if (loading) {
      return {
        error: new Error('loading'),
        list: null
      }
    }

    const query = {
      ...mainParams,
      ...params
    }

    toggleLoading(true)
    const { res, err } = await method(query)
    toggleLoading(false)

    if (!res) {
      return {
        error: err,
        list: null
      }
    }

    (list.value as any[]).push(...res.data)
    if (res.data.length < query[limitKey as keyof P])
      toggleNoMoreData(true)

    return {
      list: res.data as InnerResponseType,
      error: null
    }
  }

  return {
    list,
    getList,
    noMoreData: $$(noMoreData),
    loading: $$(loading)
  }
}
