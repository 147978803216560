import { useConfigStore } from '~/store/config'
import WSClass from '~/utils/ws'

export enum WSTYPE {
  /**
   * 行情ws地址
   */
  QWS,
  /**
   * 用户ws
   */
  UWS
}

let hostname = window.location.hostname
let protocol = window.location.protocol === 'http:' ? 'ws:' : 'wss:'
// 行情ws地址
const qws = '/ws/quote/v1'
// 订单ws地址
const ws = '/api/ws/user'
// local
if (hostname.startsWith('local')) {
  protocol = 'wss:'
  hostname = import.meta.env.VITE_PROXY_SERVER_HOST
}

const qwsURL = window.location.hostname.startsWith('local')
  ? window.location.origin.replace('http', 'ws') + qws
  : `${protocol}//${
    hostname.includes('www')
      ? hostname.replace('www', 'ws')
      : `ws.${hostname}`
  }${qws}`

const wsURL = window.location.hostname.startsWith('local')
  ? window.location.origin.replace('http', 'ws') + ws
  : `${protocol}//${
    hostname.includes('www')
      ? hostname.replace('www', 'ws')
      : `ws.${hostname}`
  }${ws}`

let QWS_INSTANCE = $ref<WSClass | null>(null)
let UWS_INSTANCE = $ref<WSClass | null>(null)

export function useWS(type: WSTYPE) {
  let ws = $ref<WSClass | null>(null)
  const configStore = useConfigStore()
  const { webConfig } = $(storeToRefs(configStore))

  const { orgId } = webConfig!

  if (type === WSTYPE.QWS) {
    if (!QWS_INSTANCE)
      QWS_INSTANCE = new WSClass(qwsURL, orgId)

    ws = QWS_INSTANCE
  }
  else {
    if (!UWS_INSTANCE)
      UWS_INSTANCE = new WSClass(wsURL, orgId)

    ws = UWS_INSTANCE
  }

  return ws
}
