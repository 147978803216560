import type { MaybeRef } from '@vueuse/core'
import {
  getStrategyOrderList
} from '~/api'
import type { StrategyOrderInfo } from '~/types'
import {
  StrategyOrderTabType,
  StrategyType
} from '~/types/enums'
import type { QuoteWS } from '~/types/ws'

// const data = {
//   id: 1,
//   end_time: 876702218571,
//   begin_time: 1061730068255,
//   profit_amount: 11.11,
//   symbol_id: 'BTC-SWAP-USDT',
//   margin: 22.22,
//   side: 1,
//   leverage: 1,
//   stop_top_price: 92,
//   trigger_price: '123',
//   more_shares_count_margin: '30',
//   more_shares_price_multiple: '28',
//   more_shares_spread_multiple: '3',
//   max_more_shares_count: '4',
//   total_profit: 97,
//   close_profit: 10,
//   loop_count: 10,
//   stop_loss_ratio: '4',
//   not_profit: 63,
//   liquidation_price: 36,
//   status: 2,
//   grid_type: 1,
//   min_price: 72,
//   max_price: 74,
//   grid_count: 71,
//   more_shares_ratio: 96,
//   stop_profit_ratio: 6,
//   stop_low_price: 65
// }
const [gridProcessOrders, setGridProcessOrders] = $(useState<StrategyOrderInfo[]>([]))
const [gridHistoryOrders, setGridHistoryOrders] = $(useState<StrategyOrderInfo[]>([]))
const [martinProcessOrders, setMartinProcessOrders] = $(useState<StrategyOrderInfo[]>([]))
const [martinHistoryOrders, setMartinHistoryOrders] = $(useState<StrategyOrderInfo[]>([]))

const [gridLoading, toggleGridLoading] = $(useToggle(false))
const [martinLoading, toggleMartinLoading] = $(useToggle(false))

const showAllSymbolOrder = $ref(false)

export function useStrategyOrderData(symbolId: MaybeRef<string>) {
  const symbolIdRef = $ref(symbolId)

  const filterGridProcessOrders = computed<StrategyOrderInfo[]>(() => {
    if (showAllSymbolOrder)
      return gridProcessOrders

    return gridProcessOrders.filter((item) => {
      return item.symbol_id === symbolIdRef
    })
  })

  const filterGridHistoryOrders = computed<StrategyOrderInfo[]>(() => {
    if (showAllSymbolOrder)
      return gridHistoryOrders

    return gridHistoryOrders.filter((item) => {
      return item.symbol_id === symbolIdRef
    })
  })

  const filterMartinProcessOrders = computed<StrategyOrderInfo[]>(() => {
    if (showAllSymbolOrder)
      return martinProcessOrders

    return martinProcessOrders.filter((item) => {
      return item.symbol_id === symbolIdRef
    })
  })

  const filterMartinHistoryOrders = computed<StrategyOrderInfo[]>(() => {
    if (showAllSymbolOrder)
      return martinHistoryOrders

    return martinHistoryOrders.filter((item) => {
      return item.symbol_id === symbolIdRef
    })
  })

  const getStrategyOrder = async (params: { tactics_type: StrategyType; status: StrategyOrderTabType }) => {
    const isGrid = params.tactics_type === StrategyType.GRID
    isGrid ? toggleGridLoading(true) : toggleMartinLoading(true)

    const setDataStrategy = {
      [`${StrategyType.GRID}_${StrategyOrderTabType.PROCESSING}`]: setGridProcessOrders,
      [`${StrategyType.GRID}_${StrategyOrderTabType.HISTORY}`]: setGridHistoryOrders,
      [`${StrategyType.MARTIN}_${StrategyOrderTabType.PROCESSING}`]: setMartinProcessOrders,
      [`${StrategyType.MARTIN}_${StrategyOrderTabType.HISTORY}`]: setMartinHistoryOrders
    }

    const { res, err } = await getStrategyOrderList(params)
    isGrid ? toggleGridLoading(false) : toggleMartinLoading(false)

    if (err)
      return

    setDataStrategy[`${params.tactics_type}_${params.status}`](res.data)
  }

  const orderWSHandler = (data: QuoteWS.StrategyOrderData) => {
    if (data.topic !== 'tactics_info')
      return

    setGridProcessOrders(data.data?.grid ?? [])
    // console.log(data, data.data?.grid, gridProcessOrders)
    setMartinProcessOrders(data.data?.martingale ?? [])
  }

  return {
    gridProcessOrders: $$(gridProcessOrders),
    gridHistoryOrders: $$(gridHistoryOrders),
    martinProcessOrders: $$(martinProcessOrders),
    martinHistoryOrders: $$(martinHistoryOrders),
    showAllSymbolOrder: $$(showAllSymbolOrder),

    filterMartinHistoryOrders,
    filterMartinProcessOrders,
    filterGridHistoryOrders,
    filterGridProcessOrders,

    gridLoading: $$(gridLoading),
    martinLoading: $$(martinLoading),

    getStrategyOrder,
    setMartinHistoryOrders,
    setGridHistoryOrders,
    setGridProcessOrders,
    setMartinProcessOrders,
    orderWSHandler
  }
}
