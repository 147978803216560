import type { QuoteWS } from '~/types/ws'
import type { DepthData, DepthDataItem } from '~/types/futures'
import { getQuoteDepth } from '~/api'
import { DEPTH_CHART_LIMIT } from '~/config/throttleSetting'
import { useFutureStore } from '~/store/future'
import { useStrategyStore } from '~/store/strategy'
import { Compare } from '~/types/enums'

// 深度相关
let depthData = $shallowRef<DepthData>({
  bids: [],
  asks: []
})
let mergeDepthData = $shallowRef<DepthData>({
  bids: [],
  asks: []
})

const [loading, toggleLoading] = $(useToggle(false))
function clearDepthData() {
  // depthData = { asks: [], bids: [] }
  mergeDepthData = { asks: [], bids: [] }
}

function formatDepthData(data: QuoteWS.DepthInfo) {
  let total = 0

  const formatData = {
    bids: [] as DepthDataItem[],
    asks: [] as DepthDataItem[]
  }

  formatData.bids = data.b.map((bid) => {
    total += Number(bid[1])

    return {
      price: bid[0],
      total: String(total),
      volume: bid[1],
      compareToPre: Compare.EQ
    }
  })

  total = 0
  formatData.asks = data.a.map((ask) => {
    total += Number(ask[1])

    return {
      price: ask[0],
      total: String(total),
      volume: ask[1],
      compareToPre: Compare.EQ
    }
  })

  return formatData
}

export function useFutureDepthData() {
  const futureStore = useFutureStore()

  const setDepthData = (data: QuoteWS.DepthInfo, isDepth = true) => {
    if (!data)
      return

    const formattedData = formatDepthData(data)

    if (isDepth)
      depthData = formattedData
    else mergeDepthData = formattedData
  }

  const depthDataHandler = (data: QuoteWS.DepthData, isDepth = true, isStrategy = false) => {
    const strategyStore = useStrategyStore()
    const { symbolId: futureSymbolId, dumpScale: futureDumpScale } = $(storeToRefs(futureStore))
    const { symbolId: strategySymbolId, dumpScale: strategyDumpScale } = $(storeToRefs(strategyStore))
    const symbolId = isStrategy ? strategySymbolId : futureSymbolId
    const dumpScale = isStrategy ? strategyDumpScale : futureDumpScale

    const realDumpScale = dumpScale <= 0 ? dumpScale - 1 : dumpScale
    if (
      !['depth', 'mergedDepth'].includes(data.topic)
      || data.symbol !== symbolId
    )
      return

    if (
      data.topic === 'mergedDepth'
      && !new BigNumber(realDumpScale).isEqualTo(data.params.dumpScale!)
    )
      return

    setDepthData(data.data[0], isDepth)
  }

  const mergeDepthDataHandler = (data: QuoteWS.DepthData) => {
    depthDataHandler(data, false)
  }
  const strategyDepthDataHandler = (data: QuoteWS.DepthData) => {
    depthDataHandler(data, false, true)
  }

  /**
   * 深度数据http轮询
   * @param digit 小数位数
   * @param isDepth 是否是深度图表数据
   */
  const depthHttpAction = async (isStrategy = false) => {
    const strategyStore = useStrategyStore()
    const { exchangeId, symbolId: futureSymbolId, dumpScale: futureDumpScale } = $(storeToRefs(futureStore))
    const { symbolId: strategySymbolId, dumpScale: strategyDumpScale } = $(storeToRefs(strategyStore))
    const symbolId = isStrategy ? strategySymbolId : futureSymbolId
    const dumpScale = isStrategy ? strategyDumpScale : futureDumpScale

    if (loading || !exchangeId || !symbolId || typeof dumpScale !== 'number')
      return

    toggleLoading(true)
    const { res, err } = await getQuoteDepth({
      symbol: `${exchangeId}.${symbolId}`,
      limit: DEPTH_CHART_LIMIT,
      dumpScale: dumpScale <= 0 ? dumpScale - 1 : dumpScale
    })
    toggleLoading(false)

    if (err || res.data[0].s !== symbolId)
      return

    // 深度图和图表数据一起设置 减少轮询次数
    setDepthData(res.data[0], false)
    setDepthData(res.data[0])
  }

  return {
    depthData: $$(depthData),
    mergeDepthData: $$(mergeDepthData),
    depthDataHandler,
    mergeDepthDataHandler,
    strategyDepthDataHandler,
    depthHttpAction,
    clearDepthData
  }
}
