<script setup lang="ts">import { toRef as _toRef } from 'vue';

import { useConfigStore } from '~/store/config'

const configStore = useConfigStore()
const __$temp_1 = (storeToRefs(configStore)),
  indexConfig = _toRef(__$temp_1, 'indexConfig');
const banners = computed(() => indexConfig.value?.banners ?? [])
</script>

<template>
  <div>
    <div v-if="banners.length" bg="$ex-homeBannerBg" items-center>
      <div content>
        <el-carousel height="200px">
          <el-carousel-item
            v-for="banner in banners"
            :key="banner.imgUrl"
          >
            <div
              w-full
              h-full
            >
              <a
                v-if="banner.directUrl"
                :href="banner.directUrl"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img :src="banner.imgUrl" h-full w-full rounded-lg>
              </a>
              <img v-else :src="banner.imgUrl" h-full w-full rounded-lg>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
  </div>
</template>
