import type { PiniaPluginContext } from 'pinia'
import { debounce } from '~/utils/utils'

export default function debouncePinia({ options, store }: PiniaPluginContext) {
  if (options.debounce) {
    // we are overriding the actions with new ones
    return Object.keys(options.debounce).reduce((debouncedActions, action) => {
      debouncedActions[action] = debounce(
        store[action],
        options.debounce![action]!
      )

      return debouncedActions
    }, {} as Record<string, ReturnType<typeof debounce>>)
  }
}
