<script setup lang="ts">
import { useConfigStore } from '~/store/config'

const configStore = useConfigStore()
const { indexConfig, socialMedia } = storeToRefs(configStore)
const { t } = useI18n()
// const isPic = (url: string) => {
//   return /\.jpg|\.png|\.gif/.test(url)
// }
</script>

<template>
  <div class="broker-footer bg-hex-17171f" flex flex-col>
    <div class="broker-footer-con" flex justify="!between">
      <div
        v-for="item in indexConfig?.footConfigList"
        :key="item.caption"
        class="broker-footer-group"

        text-base text-white
      >
        <h3 class="Body1 color-common-surface hover-common-surface">
          {{ item.caption }}
        </h3>
        <p v-for="_item in item.items" :key="_item.text" class="Caption">
          <a
            class="hover-common-surface Body2 c-$ex-title"
            text-sm
            hover:text-white
            :href="_item.link"
            target="_blank"
            rel="noopener noreferrer nofollow"
          >{{ _item.text }}</a>
        </p>
      </div>
      <div class="broker-footer-group">
        <h3 class="Body1 color-common-surface hover-common-surface text-white">
          {{ t('社交媒体') }}
        </h3>

        <div class="broker-footer-link mt-2">
          <template v-for="item in socialMedia" :key="item.icon">
            <el-popover
              v-if="item.detailList.length > 1"
              placement="top"
              trigger="hover"
              word-wrap="word-wrap"
              :offset="20"
              :max-width="140"
              :show-arrow="false"
              popper-class="!p-2 !min-w-45"
            >
              <template #reference>
                <a
                  class="hover-common-surface group color-gray-200"
                  :href="item.detailList?.[0]?.url"
                  :target="item.detailList?.[0]?.isBlank ? '_blank' : ''"
                  rel="noopener noreferrer nofollow"
                >
                  <span
                    group-hover:hidden inline-block
                    h-7 w-7
                    :style="{
                      background: `url(${item.icon}) no-repeat center center/cover`,
                    }"
                  />
                  <span
                    hidden h-7
                    w-7 group-hover:inline-block
                    :style="{
                      background: `url(${item.selectIcon}) no-repeat center center/cover`,
                    }"
                  />
                </a>
              </template>
              <div flex flex-wrap>
                <div
                  v-for="(detail) in item.detailList"
                  :key="detail.url"
                  w-full
                  text-center
                >
                  <el-link
                    :href="detail.url"
                    :target="detail.isBlank ? '_blank' : ''"
                    rel="noopener noreferrer nofollow"
                  >
                    <div>
                      {{ detail.title }}
                    </div>
                  </el-link>
                </div>
              </div>
            </el-popover>
            <a
              v-else
              class="hover-common-surface group color-gray-200"
              :href="item.detailList?.[0]?.url"
              :target="item.detailList?.[0]?.isBlank ? '_blank' : ''"
              rel="noopener noreferrer nofollow"
            >
              <span

                inline-block h-7
                w-7 group-hover:hidden
                :style="{
                  background: `url(${item.icon}) no-repeat center center/cover`,
                }"
              />
              <span
                hidden h-7
                w-7 group-hover:inline-block
                :style="{
                  background: `url(${item.selectIcon}) no-repeat center center/cover`,
                }"
              />
            </a>
          </template>
        </div>
      </div>
    </div>
    <div mt-auto>
      <div class="broker-footer-copyright flex items-center justify-center bg-hex-22222C py-5 c-$ex-title">
        {{ indexConfig?.copyright }}
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.broker-footer {
  min-height: 222px;
  &-left {
    width: 400px;
  }
  &-con {
    min-width: 1280px;
    max-width: 1328px;
    margin: 0 auto;
    padding: 40px 56px 44px;
    display: flex;
  }
  &-logo {
    padding-bottom: 9px;
    display: flex;
    align-items: center;
    img {
      height: 32px;
    }
    strong {
      font-size: 21px;
    }
  }
  &-group {
    flex: 1;
    h3 {
      margin-bottom: 16px;
      font-weight: 400;
      display: inline-block;
      position: relative;
      &::after{
        content:'';
        position: absolute;
        width: 50%;
        left: 0;
        bottom: -10px;
        height: 1px;
        background: var(--ex-primary);
      }
    }
    p {
      line-height: 24px;
      margin-bottom: 8px;
    }
  }
  &-link {
    width: 240px;
    a,
    .ivu-poptip {
      margin-right: 20px;
      margin-bottom: 16px;
    }
    a{
      display: inline-block;
    }
  }
}
</style>
