import type { UnwrapRef } from 'vue'

export function useState<T>(value: T) {
  const valRef = ref<T>(value)

  function setVal(val: T) {
    valRef.value = val as UnwrapRef<T>
  }

  return [valRef, setVal] as const
}
