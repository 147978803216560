import type { MaybeRef } from '@vueuse/core'
import useESPagination from '../useESPagination'
import type {
  GetEntrustParams,
  GetFollowUserPositionParams,
  GetHistoryTradeParams,
  GetPositionListParams
} from '~/api'
import {
  getCurrentEntrustList,
  getCurrentPositionList,
  getCurrentStopLossEntrust,
  getFollowUserPosition,
  getHistoryEntrustList,
  getHistoryStopLossEntrust,
  getHistoryTradeList
} from '~/api'

import type { FutureHistoryTrade } from '~/types'
import {
  FutureOrderListType,
  FutureOrderStatus,
  FuturePlanOrderType
} from '~/types/enums'
import type { QuoteWS } from '~/types/ws'
import { excludeRepeatArray } from '~/utils/helper'

let currentOrders = $shallowRef<QuoteWS.OrderInfo[]>([])
let historyOrders = $shallowRef<QuoteWS.OrderInfo[]>([])
let currentPositions = $shallowRef<QuoteWS.PositionInfo[]>([])
let historyTradeOrders = $shallowRef<FutureHistoryTrade[]>([])
// 跟单持仓数据
let followPositions = $shallowRef<QuoteWS.PositionInfo[]>([])

let noticeCount = $ref(0)

const { loading: positionLoading, getList: getPositionList, noMoreData: noMorePositionData } = $(useESPagination(getCurrentPositionList, { limit: 100 }))
const { loading: historyTradeLoading, getList: getHistoryTrade, noMoreData: noMoreHistoryTradeData } = $(useESPagination(getHistoryTradeList, { limit: 50 }))
const { loading: historyLimitOrderLoading, getList: getHistoryLimitOrder, noMoreData: noMoreHistoryLimitData } = $(useESPagination(getHistoryEntrustList, { limit: 50 }))
const { loading: historyStopOrderLoading, getList: getHistoryStopOrder, noMoreData: noMoreHistoryStopData } = $(useESPagination(getHistoryEntrustList, { limit: 50 }))
const { loading: historyTrackOrderLoading, getList: getHistoryTrackOrder, noMoreData: noMoreHistoryTrackData } = $(useESPagination(getHistoryEntrustList, { limit: 50 }))
const { loading: historyStopLossOrderLoading, getList: getHistoryStopLossOrder, noMoreData: noMoreHistoryStopLossData } = $(useESPagination(getHistoryStopLossEntrust, { limit: 50 }))
const { loading: currentLimitOrderLoading, getList: getCurrentLimitOrder, noMoreData: noMoreCurrentLimitData } = $(useESPagination(getCurrentEntrustList, { limit: 50 }))
const { loading: currentStopOrderLoading, getList: getCurrentStopOrder, noMoreData: noMoreCurrentStopData } = $(useESPagination(getCurrentEntrustList, { limit: 50 }))
const { loading: currentTrackOrderLoading, getList: getCurrentTrackOrder, noMoreData: noMoreCurrentTrackData } = $(useESPagination(getCurrentEntrustList, { limit: 50 }))
const { loading: currentStopLossOrderLoading, getList: getCurrentStopLossOrder, noMoreData: noMoreCurrentStopLossData } = $(useESPagination(getCurrentStopLossEntrust, { limit: 50 }))

const showAllSymbolOrder = $ref(false)

function resetNoticeCount() {
  noticeCount = 0
}

export function useFutureOrderData(symbolId: MaybeRef<string>) {
  const symbolIdRef = $ref(symbolId)

  const filterCurrentOrders = computed(() => {
    if (showAllSymbolOrder)
      return currentOrders

    return currentOrders.filter((item) => {
      return item.symbolId === symbolIdRef
    })
  })

  const filterCurrentStopOrders = computed(() => {
    return filterCurrentOrders.value.filter((item) => {
      return (
        item.type === FutureOrderListType.STOP
        && item.planOrderType !== FuturePlanOrderType.STOP_COMMON
      )
    })
  })

  const filterCurrentTrackOrders = computed(() => {
    return filterCurrentOrders.value.filter((item) => {
      return (
        item.type === FutureOrderListType.STOP_FOLLOW
      )
    })
  })

  const filterHistoryOrders = computed(() => {
    if (showAllSymbolOrder)
      return historyOrders

    return historyOrders.filter((item) => {
      return item.symbolId === symbolIdRef
    })
  })

  const filterHistoryTrackOrders = computed(() => {
    return filterHistoryOrders.value.filter((item) => {
      return item.type === FutureOrderListType.STOP_FOLLOW
    })
  })

  const filterHistoryStopOrders = computed(() => {
    return filterHistoryOrders.value.filter((item) => {
      return (
        item.type === FutureOrderListType.STOP
        && item.planOrderType !== FuturePlanOrderType.STOP_COMMON
      )
    })
  })

  const filterCurrentPositions = computed(() => {
    if (showAllSymbolOrder)
      return currentPositions

    return currentPositions.filter(item => item.symbolId === symbolIdRef)
  })

  const filterHistoryTradeOrders = computed(() => {
    if (showAllSymbolOrder)
      return historyTradeOrders

    return historyTradeOrders.filter(item => item.symbolId === symbolIdRef)
  })

  const dealOrderData = (orderData: QuoteWS.OrderInfo[], fromWS = false) => {
    let tempNoticeCount = noticeCount

    orderData.forEach((order) => {
      // 状态为NEW和PARTIAL_FILLED的订单视为当前委托
      if (
        [
          FutureOrderStatus.NEW,
          FutureOrderStatus.PARTIALLY_FILLED,
          FutureOrderStatus.ORDER_NEW,
          FutureOrderStatus.ORDER_PARTIALLY_FILLED,
          FutureOrderStatus.ORDER_ACTIVATION,
          FutureOrderStatus.ORDER_NOT_ACTIVATION
        ].includes(order.status)
      ) {
        if (
          !currentOrders.length
          || (currentOrders.length && +order.time - +currentOrders[0].time > 0)
        ) {
          currentOrders.unshift(order)
        }
        else {
          const sameOrder = currentOrders.find(
            item => item.orderId === order.orderId
          )

          if (sameOrder)
            Object.assign(sameOrder, order)
          else currentOrders.push(order)
        }
      }

      // 状态为FILLED和CANCELED的订单视为历史委托
      if (
        [
          FutureOrderStatus.FILLED,
          FutureOrderStatus.CANCELED,
          FutureOrderStatus.ORDER_CANCELED,
          FutureOrderStatus.ORDER_FILLED,
          FutureOrderStatus.ORDER_REJECTED,
          FutureOrderStatus.REJECTED
        ].includes(order.status)
      ) {
        currentOrders = currentOrders.filter(
          item => item.orderId !== order.orderId
        )

        if (
          [FutureOrderStatus.FILLED, FutureOrderStatus.ORDER_FILLED].includes(
            order.status
          )
          && fromWS
        )
          tempNoticeCount += 1

        if (
          !historyOrders.length
          || (historyOrders.length && +order.time - +historyOrders[0].time > 0)
        ) {
          historyOrders.unshift(order)
        }
        else {
          const sameOrder = historyOrders.find(
            item => item.orderId === order.orderId
          )

          if (sameOrder)
            Object.assign(sameOrder, order)
          else historyOrders.push(order)
        }
      }
    })

    noticeCount = tempNoticeCount

    // 去除重复订单
    currentOrders = excludeRepeatArray('orderId', currentOrders)
    currentOrders.sort((a, b) => +b.time - +a.time)

    historyOrders = excludeRepeatArray('orderId', historyOrders)
    historyOrders.sort((a, b) => +b.time - +a.time)

    triggerRef($$(currentOrders))
    triggerRef($$(historyOrders))
  }

  const orderDataHandler = (data: QuoteWS.OrderData) => {
    if (data.topic !== 'futures_order')
      return

    const orderData = data.data
    if (!orderData?.length)
      return

    dealOrderData(orderData, true)
  }

  const orderHttpAction = async (
    isStopRequest = false,
    params: Partial<GetEntrustParams> = {}
  ) => {
    let method = isStopRequest
      ? getCurrentStopLossOrder
      : getCurrentLimitOrder

    if (params.type === FutureOrderListType.LIMIT && currentLimitOrderLoading)
      return

    if (isStopRequest && currentStopLossOrderLoading)
      return

    if (params.type === FutureOrderListType.STOP) {
      if (currentStopOrderLoading)
        return

      method = getCurrentStopOrder
    }

    if (params.type === FutureOrderListType.STOP_FOLLOW) {
      if (currentTrackOrderLoading)
        return

      method = getCurrentTrackOrder
    }

    if (params.from_order_id) {
      if (params.type === FutureOrderListType.LIMIT && noMoreCurrentLimitData)
        return

      if (params.type === FutureOrderListType.STOP && noMoreCurrentStopData)
        return

      if (params.type === FutureOrderListType.STOP_FOLLOW && noMoreCurrentTrackData)
        return

      if (isStopRequest && noMoreCurrentStopLossData)
        return
    }

    const mergedParams = {
      limit: 50,
      ...params
    }

    const { list } = await method(mergedParams)

    if (!list)
      return

    dealOrderData(list)
  }

  const dealPositionData = (positionData: QuoteWS.PositionInfo[]) => {
    // 去除重复订单
    // currentPositions = excludeRepeatArray('positionId', positionData)
    currentPositions = positionData
    // currentPositions.sort((a, b) => a.symbolId.localeCompare(b.symbolId))
  }

  const positionDataHandler = (data: QuoteWS.PositionData) => {
    if (data.topic !== 'futures_position' || data.event === 'subbed')
      return

    const positionData = data.data

    dealPositionData(positionData ?? [])
  }

  const followPositionDataHandler = (data: QuoteWS.PositionData) => {
    if (data.topic !== 'futures_follow_position' || data.event === 'subbed')
      return

    const positionData = data.data

    followPositions = positionData ?? []
  }

  const getHistoryEntrustData = async (
    isStopRequest = false,
    params: Partial<GetEntrustParams> = {}
  ) => {
    let method = isStopRequest ? getHistoryStopLossOrder : getHistoryLimitOrder

    if (params.type === FutureOrderListType.LIMIT && historyLimitOrderLoading)
      return

    if (isStopRequest && historyStopLossOrderLoading)
      return

    if (params.type === FutureOrderListType.STOP) {
      if (historyStopOrderLoading)
        return

      method = getHistoryStopOrder
    }

    if (params.type === FutureOrderListType.STOP_FOLLOW) {
      if (historyTrackOrderLoading)
        return

      method = getHistoryTrackOrder
    }

    // 不传是查最新的 传了是查更多 差更多才验证是否没有后继数据
    if (params.from_order_id) {
      if (params.type === FutureOrderListType.STOP && noMoreHistoryStopData)
        return

      if (params.type === FutureOrderListType.LIMIT && noMoreHistoryLimitData)
        return

      if (params.type === FutureOrderListType.STOP_FOLLOW && noMoreHistoryTrackData)
        return

      if (isStopRequest && noMoreHistoryStopLossData)
        return
    }

    const mergedParams = {
      limit: 50,
      ...params
    }

    const { list } = await method(mergedParams)

    if (!list)
      return

    dealOrderData(list)
  }

  const positionHttpAction = async (
    params: Partial<GetPositionListParams> = {}
  ) => {
    if (positionLoading || (noMorePositionData && params.from_position_id))
      return

    const mergedParams: GetPositionListParams = {
      limit: 100,
      ...params
    }

    const { error, list } = await getPositionList(mergedParams)
    if (error)
      return

    dealPositionData(list!)
  }

  // 跟单持仓http处理
  const followPositionHttpAction = async (
    params: Partial<GetFollowUserPositionParams> = {}
  ) => {
    const mergedParams: GetFollowUserPositionParams = {
      limit: 50,
      ...params
    }

    const { res, err } = await getFollowUserPosition(mergedParams)

    if (err)
      return

    followPositions = res.data
  }

  const getHistoryTradeData = async (
    params: Partial<GetHistoryTradeParams> = {}
  ) => {
    if (historyTradeLoading || (noMoreHistoryTradeData && params.from_trade_id))
      return

    const { list } = await getHistoryTrade(params)

    if (!list)
      return

    list.forEach((trade) => {
      if (
        !historyTradeOrders.length
        || (historyTradeOrders.length
        && +trade.time - +historyTradeOrders[0].time > 0)
      ) {
        historyTradeOrders.unshift(trade)
      }
      else {
        const sameTrade = historyTradeOrders.find(
          item => item.tradeId === trade.tradeId
        )

        if (sameTrade)
          Object.assign(sameTrade, trade)
        else historyTradeOrders.push(trade)
      }
    })

    // 去除重复订单
    historyTradeOrders = excludeRepeatArray(
      'tradeId',
      historyTradeOrders,
      'time'
    )
    historyTradeOrders.sort((a, b) => +b.time - +a.time)

    triggerRef($$(historyTradeOrders))
  }

  return {
    currentOrders: $$(currentOrders),
    historyOrders: $$(historyOrders),
    currentPositions: $$(currentPositions),
    followPositions: $$(followPositions),
    historyTradeOrders: $$(historyTradeOrders),
    noticeCount: $$(noticeCount),
    showAllSymbolOrder: $$(showAllSymbolOrder),
    positionLoading: $$(positionLoading),
    historyLimitOrderLoading: $$(historyLimitOrderLoading),
    historyStopOrderLoading: $$(historyStopOrderLoading),
    historyStopLossOrderLoading: $$(historyStopLossOrderLoading),
    currentLimitOrderLoading: $$(currentLimitOrderLoading),
    currentStopOrderLoading: $$(currentStopOrderLoading),
    currentStopLossOrderLoading: $$(currentStopLossOrderLoading),
    historyTradeLoading: $$(historyTradeLoading),
    currentTrackOrderLoading: $$(currentTrackOrderLoading),
    historyTrackOrderLoading: $$(historyTrackOrderLoading),
    noMoreCurrentLimitData: $$(noMoreCurrentLimitData),
    noMoreHistoryLimitData: $$(noMoreHistoryLimitData),
    noMoreCurrentStopData: $$(noMoreCurrentStopData),
    noMoreHistoryStopData: $$(noMoreHistoryStopData),
    noMoreCurrentStopLossData: $$(noMoreCurrentStopLossData),
    noMoreHistoryStopLossData: $$(noMoreHistoryStopLossData),
    noMorePositionData: $$(noMorePositionData),
    noMoreHistoryTradeData: $$(noMoreHistoryTradeData),
    noMoreHistoryTrackData: $$(noMoreHistoryTrackData),
    noMoreCurrentTrackData: $$(noMoreCurrentTrackData),

    filterCurrentOrders,
    filterHistoryOrders,
    filterCurrentPositions,
    filterHistoryTradeOrders,
    filterCurrentTrackOrders,
    filterHistoryTrackOrders,
    filterCurrentStopOrders,
    filterHistoryStopOrders,

    orderDataHandler,
    getHistoryTradeData,
    getHistoryEntrustData,
    orderHttpAction,
    positionDataHandler,
    followPositionDataHandler,
    positionHttpAction,
    followPositionHttpAction,
    resetNoticeCount
  }
}
