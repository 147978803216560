import { clamp } from '@vueuse/core'
import { useState } from './../useState'
import type { CreateGridParams, CreateMartinParams, CreateStrategyParams } from '~/types'
import {
  OrderSide,
  StrategyGridType,
  StrategyType
} from '~/types/enums'
import type { CamelCase } from '~/types/utils'
import { deepClone } from '~/utils/utils'
import { getStrategyMaxLeverage } from '~/api'

const initialGridForm: CamelCase<CreateGridParams> & Pick<CamelCase<CreateStrategyParams<{}>>, 'triggerPrice'> = {
  minPrice: '',
  maxPrice: '',
  gridType: StrategyGridType.EQUAL_DIFF,
  gridCount: '',
  allPrice: '',
  triggerPrice: '',
  margin: ''
}
const initialMartinForm: CamelCase<CreateMartinParams> & Pick<CamelCase<CreateStrategyParams<{}>>, 'triggerPrice'> = {
  triggerPrice: '',
  allPrice: '',
  maxMoreSharesCount: '',
  moreSharesCountMargin: '',
  moreSharesRatio: '',
  moreSharesPriceMultiple: '',
  moreSharesSpreadMultiple: '',
  stopProfitRatio: '',
  stopLossRatio: '',
  firstMargin: ''
}

let gridForm = $ref(deepClone(initialGridForm))
let martinForm = $ref(deepClone(initialMartinForm))
let currentOrderSide = $ref(OrderSide.BUY)
// const currentPositionType = $ref(FuturePositionType.ISOLATED)
let currentLeverage = $ref(5)
let currentMaxLeverage = $ref(20)
let useGridAdvanceSetting = $ref(false)
let useMartinAdvanceSetting = $ref(false)
// 这个变量用来表示是不是在执行copy订单操作 每次执行+1 有变化时不请求ai参数
const [copyFlag, setCopyFlag] = $(useState(0))

const setUseGridAdvanceSetting = (val: boolean) => {
  useGridAdvanceSetting = val
}
const setUseMartinAdvanceSetting = (val: boolean) => {
  useMartinAdvanceSetting = val
}

const setCurrentOrderSide = (side: OrderSide) => {
  currentOrderSide = side
}

const setCurrentLeverage = (leverage: number) => {
  currentLeverage = clamp(leverage, 1, currentMaxLeverage)
}

const setCurrentMaxLeverage = (leverage: number) => {
  currentMaxLeverage = leverage
}

const getCurrentMaxLeverage = async() => {
  const { res, err } = await getStrategyMaxLeverage()

  if (err)
    return

  setCurrentMaxLeverage(res.data.leverage)
}

export function useStrategyTrading() {
  function resetForm(type = StrategyType.GRID) {
    if (type === StrategyType.GRID)
      gridForm = deepClone(initialGridForm)
    else
      martinForm = deepClone(initialMartinForm)
  }

  function mergeForm(opts:
  | { type: StrategyType.GRID; data: Partial<typeof gridForm> }
  | { type: StrategyType.MARTIN; data: Partial<typeof martinForm> }) {
    if (opts.type === StrategyType.GRID)
      gridForm = { ...gridForm, ...opts.data }
    else
      martinForm = { ...martinForm, ...opts.data }
  }

  return {
    gridForm: $$(gridForm),
    martinForm: $$(martinForm),
    currentOrderSide: $$(currentOrderSide),
    currentLeverage: $$(currentLeverage),
    currentMaxLeverage: $$(currentMaxLeverage),
    useGridAdvanceSetting: $$(useGridAdvanceSetting),
    useMartinAdvanceSetting: $$(useMartinAdvanceSetting),
    copyFlag: $$(copyFlag),

    setCurrentLeverage,
    setCopyFlag,
    setCurrentOrderSide,
    setUseGridAdvanceSetting,
    setUseMartinAdvanceSetting,
    getCurrentMaxLeverage,
    setCurrentMaxLeverage,
    resetForm,
    mergeForm
  }
}
