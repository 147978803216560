import { useFutureSymbol } from './useFutureSymbol'
import type { QuoteWS } from '~/types/ws'
import { getQuoteTicker } from '~/api'
import type { TickerInfo } from '~/types/futures'

export function useFutureBrokerData() {
  const { setTickerInfo } = useFutureSymbol()

  const brokerDataHandler = (data: QuoteWS.BrokerData) => {
    if (data.topic !== 'slowBroker')
      return

    setTickerInfo(
      Object.fromEntries(
        data.data.map((brokerInfo) => {
          return [
            brokerInfo.s,
            {
              high: brokerInfo.h,
              low: brokerInfo.l,
              open: brokerInfo.o,
              close: brokerInfo.c,
              volume: brokerInfo.v,
              grow: new BigNumber(brokerInfo.m).multipliedBy(100).toFixed(2),
              totalVolume: brokerInfo.qv
            }
          ]
        })
      )
    )
  }

  const brokerHttpAction = async (
    callback: (data: Record<string, Partial<TickerInfo>>) => void
  ) => {
    const { res, err } = await getQuoteTicker()

    if (err)
      return

    callback(
      Object.fromEntries(
        res.data.map((brokerInfo) => {
          return [
            brokerInfo.s,
            {
              high: brokerInfo.h,
              low: brokerInfo.l,
              open: brokerInfo.o,
              close: brokerInfo.c,
              volume: brokerInfo.v,
              time: brokerInfo.t,
              grow: new BigNumber(brokerInfo.m).multipliedBy(100).toFixed(2),
              totalVolume: brokerInfo.qv
            }
          ]
        })
      )
    )
  }

  return {
    brokerDataHandler,
    brokerHttpAction
  }
}
