// websocket
// @ts-expect-error
import pako from 'pako'
import * as helper from './utils'
import { USE_BINARY_DATA } from '~/config/const'
import { RESOLUTION_MAP } from '~/config/enums'
import type { KlineResolution } from '~/types/enums'
import {
  DEPTH_CHART_LIMIT,
  FUTURE_TRADE_MAX_COUNT,
  KLINE_GET_LIMIT,
  QUOTE_HTTP_POLLING_INTERVAL
} from '~/config/throttleSetting'

/**
 * ws
 * 订阅：当ws链接状态是1，直接订阅；未链接时，使用httpAction查询信息，传入payload，callback2个参数；拿到数据后，调用callback
 * 查询：当ws链接状态是1，直接查询；未链接时，使用httpAction查询信息，传入payload，callback2个参数；拿到数据后，调用callback
 *
 */

const workerUrl = `${import.meta.env.VITE_ROUTER_BASE_URL.replace(
  /\/$/,
  ''
)}/worker_v1.0.js`
const workerUrlSafari = `${import.meta.env.VITE_ROUTER_BASE_URL.replace(
  /\/$/,
  ''
)}/worker_v1.1.js`
const safari
  = /Safari/i.test(navigator.userAgent) && !/Chrome/i.test(navigator.userAgent)

interface BhopSubInfo {
  payload: {
    id: string
    topic: string
    event: string
    params: Record<string, any>
    symbol?: string
    limit?: number
  }
  status: 'subing' | 'subed'
  httpAction?(data?: any): void
  callback(data?: any): void
}

export type SubChannel =
  | 'kline'
  | 'broker'
  | 'mergedDepth'
  | 'trade'
  | 'depth'
  | 'slowBroker'
  | 'indexKline'
  | 'markKline'
export type UserSubChannel =
  | 'futures_tradeable'
  | 'futures_balance'
  | 'futures_order'
  | 'futures_position'
  | 'futures_follow_position'
  | 'futures_follow_tradeable'
  | 'futures_leverage'
  | 'order'
  | 'plan_order'
  | 'match'
  | 'balance'
  | 'system_message'
  | 'tactics_info'
export interface SubOptionsData {
  symbol?: string
  duration?: string
  digit?: number
  resolution?: KlineResolution
  realtimeInterval?: string
  exchangeId?: number | string
  orgId?: number
  from?: number
  to?: number
  step?: number
  id?: string
}
export interface SubOptions {
  payload: BhopSubInfo['payload']
  httpAction: BhopSubInfo['httpAction']
  callback: BhopSubInfo['callback']
}

export function getFormatSubMessage(channel: SubChannel | UserSubChannel, options: SubOptionsData): BhopSubInfo['payload'] {
  options.symbol = options.symbol || ''

  const getKlineSubMessage = (
    symbol: string,
    resolution: KlineResolution,
    exchangeId: number | string,
    id: string
  ) => {
    const params: BhopSubInfo['payload'] = {
      id:
        id
        || `kline_${exchangeId}${symbol.toUpperCase()}${
          RESOLUTION_MAP[resolution]
        }`,
      topic: `kline_${RESOLUTION_MAP[resolution]}`,
      event: 'sub',
      symbol: `${exchangeId}.${symbol.toUpperCase()}`,
      params: {
        binary: USE_BINARY_DATA.value,
        klineType: RESOLUTION_MAP[resolution],
        realtimeInterval: '24h',
        limit: KLINE_GET_LIMIT
      }
    }
    return params
  }

  const getBrokerSubMessage = (orgId: number) => {
    const params: BhopSubInfo['payload'] = {
      id: 'broker',
      topic: 'broker',
      event: 'sub',
      params: {
        org: orgId,
        binary: USE_BINARY_DATA.value
      }
    }
    return params
  }

  const getSlowBrokerSubMessage = (realtimeInterval: string, orgId: number) => {
    const params: BhopSubInfo['payload'] = {
      id: 'slowBroker',
      topic: 'slowBroker',
      event: 'sub',
      params: {
        org: orgId,
        binary: USE_BINARY_DATA.value
      }
    }
    return params
  }

  const getMergedDepthSubMessage = (
    symbol: string,
    digit: number,
    exchangeId: number | string
  ) => {
    const params: BhopSubInfo['payload'] = {
      event: 'sub',
      id: `${exchangeId}.${symbol.toUpperCase()}${digit}`,
      limit: 20,
      params: {
        dumpScale: digit <= 0 ? digit - 1 : digit, // 合并深度, 2代表2位小数
        binary: USE_BINARY_DATA.value
      },
      symbol: `${exchangeId}.${symbol.toUpperCase()}`,
      topic: 'mergedDepth'
    }

    return params
  }

  const getTradeSubMessage = (
    symbol: string,
    exchangeId: number | string,
    orgId: number
  ) => {
    const params: BhopSubInfo['payload'] = {
      id: `trade${exchangeId}.${symbol.toUpperCase()}`,
      topic: 'trade',
      event: 'sub',
      limit: FUTURE_TRADE_MAX_COUNT,
      symbol: `${exchangeId}.${symbol.toUpperCase()}`,
      params: { org: orgId, binary: USE_BINARY_DATA.value }
    }

    return params
  }
  const getDepthSubMessage = (symbol: string, exchangeId: number | string) => {
    const params: BhopSubInfo['payload'] = {
      id: `depth${exchangeId}.${symbol.toUpperCase()}`,
      topic: 'depth',
      event: 'sub',
      limit: DEPTH_CHART_LIMIT,
      symbol: `${exchangeId}.${symbol.toUpperCase()}`,
      params: { binary: USE_BINARY_DATA.value }
    }

    return params
  }
  const getIndexKlineSubMessage = (
    symbol: string,
    resolution: KlineResolution,
    id: string
  ) => {
    const params: BhopSubInfo['payload'] = {
      id: id || `indexKline_${symbol}_${RESOLUTION_MAP[resolution]}`,
      topic: `indexKline_${RESOLUTION_MAP[resolution]}`,
      event: 'sub',
      limit: KLINE_GET_LIMIT,
      symbol,
      params: { binary: USE_BINARY_DATA.value }
    }

    return params
  }

  const getMarkKlineSubMessage = (
    symbol: string,
    resolution: KlineResolution,
    id: string
  ) => {
    const params: BhopSubInfo['payload'] = {
      id: id || `markKline_${symbol}_${RESOLUTION_MAP[resolution]}`,
      topic: `markKline_${RESOLUTION_MAP[resolution]}`,
      event: 'sub',
      limit: KLINE_GET_LIMIT,
      symbol,
      params: { binary: USE_BINARY_DATA.value }
    }

    return params
  }

  const channelMap: {
    [key in UserSubChannel | SubChannel]: BhopSubInfo['payload']
  } = {
    tactics_info: {
      id: 'tactics_info',
      topic: 'tactics_info',
      event: 'sub',
      params: {
        binary: USE_BINARY_DATA.value
      }
    },
    futures_order: {
      id: 'futures_order',
      topic: 'futures_order',
      event: 'sub',
      params: {
        binary: USE_BINARY_DATA.value
      }
    },
    system_message: {
      id: 'system_message',
      topic: 'system_message',
      event: 'sub',
      params: {
        org: options.orgId,
        binary: USE_BINARY_DATA.value
      }
    },
    futures_position: {
      id: 'futures_position',
      topic: 'futures_position',
      event: 'sub',
      params: {
        org: options.orgId,
        binary: USE_BINARY_DATA.value
      }
    },
    futures_follow_position: {
      id: 'futures_follow_position',
      topic: 'futures_follow_position',
      event: 'sub',
      params: {
        org: options.orgId,
        binary: USE_BINARY_DATA.value
      }
    },
    futures_balance: {
      id: 'futures_balance',
      topic: 'futures_balance',
      event: 'sub',
      params: {
        org: options.orgId,
        binary: USE_BINARY_DATA.value
      }
    },
    futures_leverage: {
      id: 'futures_leverage',
      topic: 'futures_leverage',
      event: 'sub',
      params: {
        org: options.orgId,
        binary: USE_BINARY_DATA.value
      }
    },
    futures_tradeable: {
      id: 'futures_tradeable',
      topic: 'futures_tradeable',
      event: 'sub',
      params: {
        org: options.orgId,
        binary: USE_BINARY_DATA.value
      }
    },
    futures_follow_tradeable: {
      id: 'futures_follow_tradeable',
      topic: 'futures_follow_tradeable',
      event: 'sub',
      params: {
        org: options.orgId,
        binary: USE_BINARY_DATA.value
      }
    },
    order: {
      id: 'order',
      topic: 'order',
      event: 'sub',
      params: {
        org: options.orgId,
        binary: USE_BINARY_DATA.value
      }
    },
    balance: {
      id: 'balance',
      topic: 'balance',
      event: 'sub',
      params: {
        org: options.orgId,
        binary: USE_BINARY_DATA.value
      }
    },
    plan_order: {
      id: 'plan_order',
      topic: 'plan_order',
      event: 'sub',
      params: {
        org: options.orgId,
        binary: USE_BINARY_DATA.value
      }
    },
    match: {
      id: 'match',
      topic: 'match',
      event: 'sub',
      params: {
        org: options.orgId,
        binary: USE_BINARY_DATA.value
      }
    },
    // depth: getDepthSubMessage(options.symbol!),
    indexKline: getIndexKlineSubMessage(
      options.symbol,
      options.resolution!,
      options.id!
    ),
    markKline: getMarkKlineSubMessage(
      options.symbol,
      options.resolution!,
      options.id!
    ),
    kline: getKlineSubMessage(
      options.symbol,
      options.resolution!,
      options.exchangeId!,
      options.id!
    ),
    // trade: getTradeSubMessage(options.symbol!),
    broker: getBrokerSubMessage(options.orgId!),
    mergedDepth: getMergedDepthSubMessage(
      options.symbol,
      options.digit!,
      options.exchangeId!
    ),
    trade: getTradeSubMessage(
      options.symbol,
      options.exchangeId!,
      options.orgId!
    ),
    depth: getDepthSubMessage(options.symbol, options.exchangeId!),
    slowBroker: getSlowBrokerSubMessage(
      options.realtimeInterval!,
      options.orgId!
    )
  }

  return channelMap[channel]
}

class WSClass {
  ws: WebSocket | null
  worker: Worker | null
  subs: Record<string, BhopSubInfo>
  ready: boolean
  loopInterval: NodeJS.Timer | null
  // subsQueue: BhopSubInfo['payload'][]
  reqs: Record<
  string,
  {
    payload: Record<string, any>
    callback(data?: any): void
  }
  >

  count: number

  constructor(public path: string, public orgId: number) {
    this.ws = null
    this.worker = null
    this.ready = false
    // 已订阅数据
    this.subs = {}
    // this.subsQueue = []
    this.loopInterval = null
    // 查询
    this.reqs = {
      /**
       * id: {
       *    payload:{}, 查询条件
       *    callback: ()=>{}  回调方法
       * }
       */
    }
    this.count = 0 // ping pong 计数

    // 启动ping
    this.ping()
    this.httpAction()
    this.loop()
    // 启动ws
    this.start()
    // 启动worker
    this.start_worker()
  }

  start_worker = () => {
    if (window.Worker) {
      if (!this.worker) {
        this.worker = new Worker(safari ? workerUrlSafari : workerUrl)
        this.worker.onmessage = (e) => {
          this.callFn(e.data)
        }
      }
    }
  }

  // ws未链接时，启动http轮询
  httpAction = async () => {
    if (!this.ws || !this.ready) {
      for (const key in this.subs) {
        const d = this.subs[key]
        d.httpAction?.()
      }
    }

    await helper.delay(QUOTE_HTTP_POLLING_INTERVAL)
    this.httpAction()
  }

  loop = () => {
    // const SUB_GAP = 50 // 每300毫秒发送一次订阅消息 防止被断开连接
    // this.loopInterval && clearInterval(this.loopInterval)
    // this.loopInterval = setInterval(() => {
    //   if (!this.ws || !this.ready || !this.subsQueue.length)
    //     return
    //   const payload = this.subsQueue.shift()
    //   if (payload) {
    //   }
    // }, SUB_GAP)
  }

  start = () => {
    if (!this.path)
      return

    this.ws = new WebSocket(`${this.path}?lang=${localStorage.lang}`)

    this.ws.addEventListener('open', this.open)
    this.ws.addEventListener('message', this.message)
    this.ws.addEventListener('close', this.close)
    this.ws.addEventListener('error', this.close)
  }

  open = () => {
    this.ready = this.ws?.readyState === 1
    // 重置pong 计数

    this.count = 0
    // 重新订阅已有的sub
    for (const id in this.subs) {
      const sub = this.subs[id]
      const { payload, httpAction, callback } = sub
      this.sub({ payload, httpAction, callback }, true)
    }
  }

  close = async () => {
    this.count = 0
    this.ws?.removeEventListener('open', this.open)
    this.ws?.removeEventListener('message', this.message)
    this.ws?.removeEventListener('close', this.close)
    this.ws?.removeEventListener('error', this.close)
    this.ready = false
    this.ws = null

    await helper.delay(3000)
    this.start()
  }

  callFn = (data: any) => {
    const id = data.id || data.topic
    if (!id || data.event === 'subbed')
      return
    if (this.subs[id])
      this.subs[id].callback && this.subs[id].callback(data)

    if (this.reqs[id])
      this.reqs[id].callback && this.reqs[id].callback(data)
  }

  message = (res: any) => {
    let data = res.data
    if (data && data != null) {
      // pong
      if (/pong/i.test(data)) {
        this.pong()
        return
      }
      // ping
      if (/ping/.test(data)) {
        this.ws?.send(data.replace('ping', 'pong'))
        return
      }
      if (data instanceof Blob) {
        if (this.worker && !safari) {
          this.worker.postMessage(data)
        }
        else {
          const reader = new FileReader()
          reader.onload = (evt) => {
            if (evt.target?.readyState === FileReader.DONE) {
              if (this.worker && safari) {
                this.worker.postMessage(evt.target.result)
              }
              else {
                const result = new Uint8Array(evt.target.result as ArrayBuffer)
                const data = JSON.parse(pako.inflate(result, { to: 'string' }))
                this.callFn(data)
              }
            }
          }
          reader.readAsArrayBuffer(data)
        }
      }
      else {
        data = JSON.parse(data)
        this.callFn(data)
      }
    }
  }

  /**
   * 订阅,如果ws链接状态，直接订阅，并存储订阅信息； 其他状态，仅存储信息，链接后再订阅
   * @param {object} payload
   * @param {string} payload.id  订阅唯一标识
   * @param {string} payload.topic 频道
   * @param {string} payload.event 事件：sub，cancel，cancelAll，req
   * @param {string} payload.symbol 币对
   * @param {object} payload.params 参数
   * @param {boolean} payload.params.binary 是否加密
   * @param {Function} httpAction http降级方法
   * @param {Function} callback 成功回调
   * @param {Function} subed_callback 订阅成功回调
   */
  sub = (subs: SubOptions | SubOptions[], reopen?: boolean) => {
    if (!Array.isArray(subs))
      subs = [subs]

    if (!reopen) {
      for (const subInfo of subs) {
        if (!subInfo || !subInfo.payload.id || this.subs[subInfo.payload.id])
          return
      }
    }

    subs.forEach((subInfo) => {
      const { payload } = subInfo
      payload.params.org = this.orgId

      // this.subsQueue.push(payload)
      this.subs[payload.id] = {
        ...subInfo,
        status: 'subing'
      }

      if (!this.ws || !this.ready) {
        const { pause } = useIntervalFn(() => {
          if (!this.ws || !this.ready)
            return

          pause()

          this.ws.send(JSON.stringify(payload))
          this.subs[payload.id] && (this.subs[payload.id].status = 'subed')
        }, 200)
      }
      else {
        this.ws.send(JSON.stringify(payload))
        this.subs[payload.id] && (this.subs[payload.id].status = 'subed')
      }
    })
  }

  cancel = (id: string) => {
    if (!id) {
      console.error('缺少id')
      return
    }
    if (this.ws && this.ws.readyState === 1) {
      // 1 准确取消id的订阅
      if (this.subs[id]) {
        this.ws.send(
          JSON.stringify(this.subs[id].payload).replace('sub', 'cancel')
        )
        delete this.subs[id]
      }
      // 2、模糊取消 ^id 的订阅
      const reg = new RegExp(`^${id}`)
      for (const k in this.subs) {
        if (!this.subs[id] && reg.test(k)) {
          this.ws.send(
            JSON.stringify(this.subs[k].payload).replace('sub', 'cancel')
          )
          delete this.subs[k]
        }
      }
    }
  }

  cancelAll() {
    Object.keys(this.subs).forEach((key) => {
      this.cancel(key)
    })
  }

  // 查询
  // ws：发送payload，callback回调
  // http: 调用httpAction, 并传送payload，callback作为2个参数
  req = (payload: any, callback: (data: any) => void) => {
    if (!payload || !payload.id)
      return
    if (this.ws && this.ws.readyState === 1) {
      this.ws.send(JSON.stringify(payload))
      this.reqs[payload.id] = { payload, callback }
    }
  }

  // 主动ping 服务端
  ping = async () => {
    const data = { ping: new Date().getTime() }
    if (this.ws && this.ws.readyState === 1) {
      this.count = 1 + Number(this.count)
      this.ws.send(JSON.stringify(data))
      if (this.count > 2)
        this.ws.close()
    }
    await helper.delay(5000)
    this.ping()
  }

  // 服务端返回pong
  pong = () => {
    this.count = Math.max(0, this.count - 1)
  }
}

export default WSClass
