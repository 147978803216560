import { useFutureSymbol } from './useFutureSymbol'
import { getFundingRates } from '~/api'

export function useFutureFundingData() {
  const { setTickerInfo } = useFutureSymbol()

  const getFundingRateAction = async () => {
    const { res, err } = await getFundingRates()

    if (err)
      return

    setTickerInfo(
      Object.fromEntries(
        res.data.map((fundingItem) => {
          return [
            fundingItem.tokenId,
            {
              feeChangeTime: fundingItem.nextSettleTime,
              feeRate: fundingItem.fundingRate,
              settleRate: fundingItem.settleRate
            }
          ]
        })
      )
    )
  }

  return {
    getFundingRateAction
  }
}
