export function useElementScroll(...arg: Parameters<typeof useScroll>) {
  const { x, y, isScrolling, arrivedState, directions } = useScroll(...arg)
  const { width, height } = useElementSize(arg[0] as HTMLElement)
  function scrollLeft(distance?: number) {
    const realInstance = distance ?? width.value

    x.value = Math.floor(x.value - realInstance)
  }
  function scrollRight(distance?: number) {
    const realInstance = distance ?? width.value

    x.value = Math.ceil(x.value + realInstance)
  }

  return {
    x,
    y,
    isScrolling,
    arrivedState,
    directions,
    width,
    height,
    scrollLeft,
    scrollRight
  }
}
