/**
 * 工具类模块 提供一些常用的工具方法 非浏览器内置方法
 * @module utils/utils
 */
import { BigNumber } from 'bignumber.js'
import dayjs from 'dayjs'
import type { MaybeBigNumber } from '../types/utils'
import type { CamelCase, CamelToSnakeCase, DeepExpand, FuncParams, JSONString, Last, PickType, SnakeCase, SnakeToCamelCase } from '~/types/utils'

/**
 * 增加指定的前缀字符串
 * @param prefix 前缀字符串
 * @returns {Function} 一个新的函数 接收一个url作为参数 并返回一个带有前缀的url
 */
export function withPrefix<T extends string>(prefix: T) {
  return <U extends string>(url: U): `${T}${U}` =>
      `${prefix}${url}`
}

/**
 * 阻塞一段时间
 * @param timeout 超时时间
 * @returns {Promise<void>}
 */
export function delay(timeout: number) {
  return new Promise((resolve) => {
    setTimeout(resolve, timeout)
  })
}

/**
 * @description 检查传入参数是否是一个对象
 * @author zhangcong
 * @date 02/08/2022
 * @export utils/utils
 * @param {any} obj
 * @return {boolean}
 */
type ObjectType = Record<string, unknown>
type ArrayType = unknown[]

export function isObject(
  obj: unknown
): obj is ObjectType | Function | ArrayType {
  return (typeof obj === 'object' || isFunction(obj)) && obj !== null
}

export function isArray(obj: unknown): obj is ArrayType {
  return Array.isArray(obj)
}

export function isFunction(obj: unknown): obj is Function {
  return typeof obj === 'function'
}

export function getType(obj: any) {
  return Object.prototype.toString.call(obj).slice(8, -1)
}

export function deepClone<T = any>(source: T, hash = new WeakMap()): T {
  if (!isObject(source))
    return source as T

  if (hash.has(source))
    return hash.get(source) as T

  const target = isArray(source) ? [...source] : { ...source }
  hash.set(source, target)

  for (const key of Reflect.ownKeys(target)) {
    const value = target[key as keyof typeof target]

    if (isObject(value)) {
      target[key as keyof typeof target] = deepClone(
        value,
        hash
      ) as typeof target[keyof typeof target]
    }
  }

  return target as T
}

export function compose<T extends ((...args: any[]) => any)[]>(
  ...funcs: T
): (...args: FuncParams<Last<T>>) => ReturnType<T[0]> {
  const length = funcs.length
  for (let i = 0; i < length; i++) {
    if (typeof funcs[i] !== 'function') {
      throw new TypeError(
        `Expected a function but found a ${getType(funcs[i])} at ${i}`
      )
    }
  }

  return function (this: any, ...args: FuncParams<Last<T>>): ReturnType<T[0]> {
    let result = funcs[length - 1].apply(this, args)
    let index = length - 1
    while (--index >= 0) result = funcs[index](result)

    return result
  }
}

export function debounce<T extends (...arg: any[]) => any>(
  fn: T,
  delay: number,
  immediate = true
): (...arg: Parameters<T>) => ReturnType<T> {
  let timer: NodeJS.Timer | null = null // 声明计时器
  let immediateCalled = false // 声明一个标记用来立即执行

  return function (...arg: Parameters<T>) {
    timer && clearTimeout(timer)

    if (immediate && !immediateCalled) {
      immediateCalled = true
      return fn(...arg)
    }

    timer = setTimeout(() => {
      return fn(...arg)
    }, delay)
  }
}

export function throttle<T extends (...arg: any[]) => any>(
  func: T,
  duration: number
): (...arg: Parameters<T>) => ReturnType<T> {
  let lastCallTime = 0

  return (...arg: Parameters<T>) => {
    if (Date.now() - lastCallTime > duration) {
      lastCallTime = Date.now()
      return func(...arg)
    }
  }
}

// 邮箱格式化
export function formatEmail(email: string) {
  let newEmail = ''
  const arr = email.split('@')
  if (arr[0].length < 3)
    newEmail = `${arr[0][0]}***@${arr[1]}`
  else newEmail = `${arr[0].slice(0, 3)}***@${arr[1] ?? ''}`

  return newEmail
}

// uid格式化
export function formatUid(uid: string) {
  return `${uid.slice(0, 4)}***${uid.slice(-3)}`
}

// 字符串排序
export function localeCompare(a: string, b: string) {
  return a.localeCompare(b)
}

// 千分位
export function toThousand(num: string | number) {
  if (typeof num === 'undefined')
    return '0'
  return num.toString().replace(/\d+/, (n) => {
    const len = n.length
    if (len % 3 === 0)
      return n.replace(/(\d{3})/g, ',$1').slice(1)
    else
      return n.slice(0, len % 3) + n.slice(len % 3).replace(/(\d{3})/g, ',$1')
  })

  // return (
  //   num
  //     .toString()
  //     .trim()
  //     // eslint-disable-next-line
  //     .replace(new RegExp('(?<!\.\d*)\B(?=(\d{3})+(\.\d+)?$)', 'g'), ',')
  // safari字面量正则不支持后行断言
  // )
}
// 过滤html标签
export function filterHTMLMark(content: string) {
  return content
    .replace(/(<([^>]+)>)/gi, '')
    .replace(/[\r\n]/g, '')
    .replace(/\s+/g, '')
}

// 生成UUid
export function getUUID() {
  return Math.random().toString(16).slice(2)
}

/**
 * @description 数字转百分比
 * @author zhangcong
 * @date 14/07/2022
 * @export
 * @param {(number|string)} num
 * @param {number} [digital]
 * @param {BigNumber.RoundingMode} [roundMode]
 * @return {string} 转换后的字符串
 */
export function intToPercent(
  num: number | string,
  digital = 2,
  roundMode: BigNumber.RoundingMode = BigNumber.ROUND_DOWN
) {
  if (Number.isNaN(+num))
    return '--'

  return new BigNumber(num || 0).multipliedBy(100).toFixed(digital, roundMode)
}
/**
 * @description 数字转绝对值
 * @author zhangcong
 * @date 14/07/2022
 * @export
 * @param {string} [str]
 * @return {string} 转换后的字符串
 */
export function negativeStringToAbs(str = '0') {
  return new BigNumber(str).abs().isNaN()
    ? '--'
    : new BigNumber(str).abs().toString()
}

/**
 * @description 根据数值获取正负号
 * @author zhangcong
 * @date 14/07/2022
 * @export
 * @param {number} num
 * @return {string} '+' | '-'
 */
export function getProfitFlag(num: number) {
  return num >= 0 ? '+' : '-'
}

/**
 * @description 保留指定精度
 * @author zhangcong
 * @date 14/07/2022
 * @export
 * @param {(number | string | BigNumber)} num
 * @param {number} digital
 * @default
 * @param {BigNumber.RoundingMode} roundMode
 * @default
 * @return {string} 保留指定精度后的字符串
 */
export function saveDigital(
  num: MaybeBigNumber,
  digital = 2,
  roundMode: BigNumber.RoundingMode = BigNumber.ROUND_DOWN
) {
  if (num === 0)
    return new BigNumber(0).toFixed(digital, roundMode)

  if (!num || Number.isNaN(+num))
    return '--'

  if (digital < 0) {
    return new BigNumber(num)
      .multipliedBy(new BigNumber(10).pow(digital))
      .integerValue()
      .multipliedBy(
        new BigNumber(10).pow(new BigNumber(digital).absoluteValue())
      )
      .valueOf()
  }

  return new BigNumber(num).toFixed(digital, roundMode)
}

/**
 * @description 给数字或字符串数字添加+号
 * @author zhangcong
 * @date 2022-09-15
 * @export
 * @return {string} 添加正号后的字符串
 */
export function addPositiveIdentifier(num: string) {
  if (!num || Number.isNaN(+num))
    return '--'

  return `${+num > 0 ? '+' : ''}${num}`
}

// 数字转美制计数

/**
 * @description 数字转美制计数
 * @author zhangcong
 * @date 14/07/2022
 * @export utils/utils
 * @param {(string | number)} num
 * @return {string} 转换后的字符串
 */
export function formatNumToUSNum(num: string | number) {
  if (num === '--' || Number.isNaN(+num))
    return '--'

  const BILLION = 1000000000
  const MILLION = 1000000
  const KILO = 1000
  const BigNum = new BigNumber(num)
  const BigNumAbs = BigNum.abs()

  if (BigNumAbs.gt(BILLION))
    return `${BigNum.dividedBy(BILLION).toFixed(2)}B`
  else if (BigNumAbs.gt(MILLION))
    return `${BigNumAbs.dividedBy(MILLION).toFixed(2)}M`
  else if (BigNumAbs.gt(KILO))
    return `${BigNum.dividedBy(KILO).toFixed(2)}K`
  else return BigNum.toString()
}

export function parse<
  JSONStringWithType extends JSONString<unknown>,
  InnerType = PickType<JSONStringWithType>
>(json: JSONStringWithType): InnerType {
  return JSON.parse(json) as InnerType
}

/**
 * bex颜色转为rgba
 * @param String #ff00ff #f0f
 * @param Number a 0-1
 * @return String rgba(r,g,b,a)
 */
export function hexToRgba(hex: string, a = 1) {
  if (!hex || !hex.includes('#'))
    return 'rgba(0,0,0,0)'

  if (hex.length !== 7 && hex.length !== 4) {
    console.error(`${hex} is not hex color`)
    return 'rgba(0,0,0,0)'
  }
  const colors = hex.replace('#', '').match(/^(..?)(..?)(..?)/)
  if (!colors || colors.length !== 4)
    return 'rgba(0,0,0,0)'

  return `rgba(${Number.parseInt(
    `0x${colors[1]}${colors[1].length === 1 ? colors[1] : ''}`
  )},${Number.parseInt(
    `0x${colors[2]}${colors[2].length === 1 ? colors[2] : ''}`
  )},${Number.parseInt(`0x${colors[3]}${colors[3].length === 1 ? colors[3] : ''}`)},${Number(a) || 1
  })`
}

export function camelToSnakeCase<S extends string>(str: S): CamelToSnakeCase<S> {
  return str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`) as CamelToSnakeCase<S>
}

export function snakeToCamelCase<S extends string>(str: S): SnakeToCamelCase<S> {
  return str.replace(/(_\w)/g, m => m[1].toUpperCase()) as SnakeToCamelCase<S>
}

export function snakeCaseToCamelCaseObject<T extends object>(obj: T): DeepExpand<CamelCase<T>> {
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [
      snakeToCamelCase(key),
      isObject(value) ? snakeCaseToCamelCaseObject(value) : value
    ])
  ) as DeepExpand<CamelCase<T>>
}

export function camelCaseToSnakeCaseObject<T extends object>(obj: T): DeepExpand<SnakeCase<T>> {
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [
      camelToSnakeCase(key),
      isObject(value) ? camelCaseToSnakeCaseObject(value) : value
    ])
  ) as DeepExpand<SnakeCase<T>>
}

export function fillEmptyStrWithHolder(value: string) {
  if (value.length)
    return value

  return '--'
}

export function secondToDayTimes(s: number) {
  const ms = s * 1000
  const dateNow = Date.now()
  const date = dayjs(dateNow + ms)
  const now = dayjs(dateNow)

  return {
    d: date.diff(now, 'day'),
    h: date.diff(now, 'hour') % 24,
    m: date.diff(now, 'minute') % 60,
    s: date.diff(now, 'second') % 60
  }
}

/**
 * 格式化执行时间 小于60秒返回ns 大于六十秒除了秒有什么单位展示什么单位
 * @param s 执行秒数
 * @returns {string} 格式化后的字符串
 * @example
 * formatExecutedTime(59) // 59s
 * formatExecutedTime(60) // 1m
 * formatExecutedTime(3600) // 1h 0m
 * formatExecutedTime(86400)// 1d 0h 0m
 */
export function formatExecutedTime(s: number) {
  const dayTimes = secondToDayTimes(s)
  const retArr = []

  if (s < 60) {
    retArr.push(`${s}s`)
  }
  else {
    const { d, h, m } = dayTimes

    m && retArr.push(`${m}m`)
    h && retArr.unshift(`${h}h`)
    d && retArr.unshift(`${d}d`)
  }

  return retArr.join(' ')
}
/**
 * 获取计划委托触发价格符号
 * @param quotePrice 下单时的市场价
 * @param triggerPrice 触发价格
 * @returns {string} >= 或 <=
 * @example
 * ```
 * //触发价格大于市场价格返回 >=
 * getPlanOrderCompareSymbol(1, 2) // >=
 * //触发价格小于市场价格返回 <=
 * getPlanOrderCompareSymbol(2, 1) // <=
 * ```
 */
export function getPlanOrderCompareSymbol(quotePrice: string, triggerPrice: string) {
  return new BigNumber(triggerPrice).isGreaterThan(quotePrice) ? '>=' : '<='
}
