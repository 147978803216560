<script setup lang="ts">import { ref as _ref, computed as _computed } from 'vue';

import { Close } from '@element-plus/icons-vue'
import dayjs from 'dayjs'
import type SwiperClass from 'swiper/types/swiper-class'
import { EffectCards } from 'swiper/modules'
import type { PopupAd } from '~/types'
import { getPopupAd } from '~/api'
import { EVERY_TIME_AD_KEY, GLOBAL_AD_KEY } from '~/config/const'
import { PopupFrequency } from '~/types/enums'
import 'swiper/css/effect-cards'

// import 'swiper/css/effect-cards.min.css'

let ads = _ref<PopupAd[]>([])
let visible = _ref(false)

const alreadyPopupAd = (
  useStorage<Record<PopupFrequency, Record<string, boolean>>>(GLOBAL_AD_KEY, {
    [PopupFrequency.ONCE]: {},
    [PopupFrequency.DAILY]: {},
    [PopupFrequency.EVERY_TIME]: {}
  })
)
const everyTimePopupAd = (
  useSessionStorage(EVERY_TIME_AD_KEY, {} as Record<string, boolean>)
)

async function getAdList() {
  const { res, err } = await getPopupAd()
  if (err)
    return

  ads.value = res.data
}

const availableAds = _computed(() => {
  return ads.value.filter((ad) => {
    if (ad.frequency === PopupFrequency.DAILY) {
      return !alreadyPopupAd.value[ad.frequency][
        dayjs(Date.now()).format('YYYY-MM-DD') + ad.id
      ]
    }

    if (ad.frequency === PopupFrequency.EVERY_TIME)
      return !everyTimePopupAd.value[ad.id]

    return !alreadyPopupAd.value[ad.frequency][ad.id]
  })
})

getAdList().then(() => {
  if (!availableAds.value.length)
    return

  visible.value = true
})

watch((availableAds), (newVal) => {
  if (!newVal.length)
    visible.value = false
})

function onDialogClose() {
  availableAds.value.forEach((ad) => {
    if (ad.frequency === PopupFrequency.DAILY) {
      alreadyPopupAd.value[ad.frequency][
        dayjs(Date.now()).format('YYYY-MM-DD') + ad.id
      ] = true
    }
    else if (ad.frequency === PopupFrequency.EVERY_TIME) {
      everyTimePopupAd.value[ad.id] = true
    }
    else {
      alreadyPopupAd.value[ad.frequency][ad.id] = true
    }
  })

  visible.value = false
}
const currentIndex = _ref(0)

function onSwiper(swiper: SwiperClass) {
  const ad = availableAds.value[swiper.clickedIndex]

  if (swiper.clickedIndex !== swiper.activeIndex)
    swiper.slideTo(swiper.clickedIndex)
  else if (ad.directUrl)
    window.open(ad.directUrl, '_blank')
}
</script>

<template>
  <el-dialog
    v-model="visible" class="future-dialog global-ad-dialog"
    width="720px" :align-center="true"
    :show-close="false" :close-on-click-modal="true"
    :close-on-press-escape="false" :before-close="onDialogClose"
  >
    <!-- <span> {{ isSwiping }} {{ direction }} </span> -->
    <swiper
      slides-per-view="auto" :centered-slides="true"
      :space-between="30" :modules="[EffectCards]"
      relative
      w-180
      effect="cards" @click="onSwiper"
      @slide-change="(swiper:SwiperClass) => (currentIndex = swiper.activeIndex)"
    >
      <swiper-slide v-for="item in availableAds" :key="item.id" class="!h-83 !w-138">
        <div h-full cursor-pointer>
          <img
            rounded="3.5" :src="item.imgUrl"
            alt=""
            h-full w-full
            select-none draggable="false"
          >
        </div>
      </swiper-slide>
      <div
        v-if="availableAds.length > 1"
        bg="black/30"
        absolute bottom-5
        right-25 z-10
        rounded-full px-6
        py-1 c-white
      >
        {{ currentIndex + 1 }} / {{ availableAds.length }}
      </div>
    </swiper>
    <div
      class="title-row" relative
      h-15
      flex items-center
    >
      <div class="btn-wrap" mt-5 @click="onDialogClose">
        <el-icon class="close" :size="22">
          <close />
        </el-icon>
      </div>
    </div>
  </el-dialog>
</template>

<style lang="scss" scoped>
.title-row {
  color: #fff;
  font-weight: 600;
  font-size: 18px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  .btn-wrap {
    background: rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }
}
</style>
