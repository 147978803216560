export function formatUrl(path: string) {
  return `${location.origin}${path}`
}

export function formatLoginUrl(path: string) {
  return `${path}?redirect=${encodeURIComponent(location.href)}`
}

/**
 * 数组排重
 * @param key 根据key进行排重
 * @param arr  数组
 * @param specKey 如果重复，根据指定字段进行保留最新
 */
export function excludeRepeatArray<
  T extends Record<keyof any, any>,
  K extends keyof T
>(key: K, arr: T[], specKey?: keyof T) {
  if (!key || !arr || !Array.isArray(arr))
    return []

  const noRepeatItems: Record<string, T> = {}

  arr.forEach((item) => {
    if (noRepeatItems[item[key]]) {
      // 如果重复，保留specKey最新的数据
      if (specKey && item[specKey] - noRepeatItems[item[key]][specKey] >= 0)
        noRepeatItems[item[key]] = item
    }
    else {
      noRepeatItems[item[key]] = item
    }
  })

  return Object.values(noRepeatItems)
}

/**
 * 数字小数位截取
 * 精度以外全部舍弃
 * d -3,-2,-1,0,1,2,3,4
 */
export function digits(v: string | number, d = 0) {
  const a = ['0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']
  if (!v && v !== 0) {
    if (!d)
      return v
    a.length = d
    return `0.${a.join('')}`
  }
  if (d === 0 || +d === 0 || !d || !Number(d))
    return Math.floor(+v)

  // 整数截取
  if (d <= 0) {
    const r = new BigNumber(v)
      .multipliedBy(new BigNumber(10).pow(d))
      .integerValue(BigNumber.ROUND_DOWN)
      .dividedBy(new BigNumber(10).pow(d))
      .integerValue()
      .toString()

    return r
  }

  const s = v
  const c = `${s}`.split('.')
  if (!c[1])
    c[1] = ''

  if (c[1].length === d)
    return s

  if (c[1].length < d) {
    a.length = d - c[1].length
    return c[1] ? s + a.join('') : a.length ? `${s}.${a.join('')}` : s
  }

  if (c[1].length > d) {
    const floatValues = c[1].split('')
    floatValues.length = d

    return `${c[0]}.${floatValues.join('')}`
  }

  return v
}
/**
 * 精度截取，精度以外的值按照，0舍去，> 0 向上进一位
 * 12.10 -> 12.1
 * 12.11 -> 12.2
 * @param {number} value
 * @param {number} 位数
 */
export function digits2(v: string | number, d = 0) {
  if (!v && v !== 0)
    return digits(v, d)

  if (!d || +d === 0 || !d || !Number(d))
    return Math.ceil(Number(v))

  const n = Number(d)
  const s = new BigNumber(v)
    .multipliedBy(new BigNumber(10).pow(d))
    .integerValue(BigNumber.ROUND_CEIL)
    .dividedBy(new BigNumber(10).pow(d))
    .toString()

  if (d <= 0)
    return s

  return digits(s, n)
}
