import type { SpotCreateOrderParams } from '~/api'
import { createSpotOrder, createSpotPlanOrder } from '~/api'
import { OrderSide, SpotOrderType } from '~/types/enums'

type LimitOrMarket = SpotOrderType.LIMIT | SpotOrderType.MARKET

const initialFormData = {
  triggerPrice: '',
  price: '',
  quantity: '',
  percent: 0,
  loading: false,
  orderSide: OrderSide.BUY,
  planOrderType: SpotOrderType.LIMIT as LimitOrMarket
}

let buyTradeFormData = $ref({ ...initialFormData, orderSide: OrderSide.BUY })
let sellTradeFormData = $ref({
  ...initialFormData,
  orderSide: OrderSide.SELL
})

const resetForm = (side: OrderSide) => {
  if (side === OrderSide.BUY)
    buyTradeFormData = { ...initialFormData, orderSide: OrderSide.BUY }
  else sellTradeFormData = { ...initialFormData, orderSide: OrderSide.SELL }
}

const setTradeFormData = (
  side: OrderSide,
  form: Partial<typeof initialFormData>
) => {
  const formData = side === OrderSide.BUY ? buyTradeFormData : sellTradeFormData

  Object.assign(formData, form)
}

const submitOrder = async (
  data: SpotCreateOrderParams,
  isPlanOrder = false
) => {
  const postAction = isPlanOrder ? createSpotPlanOrder : createSpotOrder

  return await postAction(data)
}

export function useExchangeTrading() {
  return {
    buyTradeFormData: $$(buyTradeFormData),
    sellTradeFormData: $$(sellTradeFormData),

    setTradeFormData,
    resetForm,
    submitOrder
  }
}
