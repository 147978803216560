<script setup lang="ts">import { computed as _computed } from 'vue';

import gsap from 'gsap'
import QuickRegister from './quickRegister.vue'
import type { NewIndexConfig } from '~/types'
import type { Nullable } from '~/types/utils'

withDefaults(defineProps<{
  config: Nullable<NewIndexConfig.ConfigItem>
}>(), { config: null, })
const content = _computed(() => {
  return __props.config?.contentList?.[0]?.content
})

onMounted(() => {
  gsap.from('.quick-register', {
    y: 150,
    duration: 0.5,
    opacity: 0,
    scrollTrigger: {
      trigger: '.home-slogan',
      start: 'center 70%'
    }
  })
})
</script>

<template>
  <div class="home-slogan">
    <div
      h-144
      :style="{
        background: `url('${content?.image}') no-repeat center center/ cover,${
          content?.background || ''
        }!important`,
      }"
    >
      <div

        content mx-auto
        h-full
        class="slogan-main"
        :style="{
          background: `url('${
            content?.imageSmall
          }') no-repeat center center/ contain`,
        }"
      >
        <quick-register class="quick-register" w-110 pt-93 />
      </div>
    </div>
  </div>
</template>
