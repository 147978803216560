<script setup lang="ts" generic="T extends any">
interface TabItem {
  name: string
  value: T
}
const emits = defineEmits<{
  (eventName: 'tabClick' | 'update:modelValue', payload: TabItem['value']): void
}>()

const props = withDefaults(
  defineProps<{
    data: TabItem[]
    modelValue: T
    isRouteMode?: boolean
    tabClass?: string
    activeTabClass?: string
  }>(),
  {
    data: () => [],
    isRouteMode: false,
    tabClass: '',
    activeTabClass: ''
  }
)

const getActiveTab = (value1: T, value2: T) => {
  if (props.isRouteMode)
    return (value1 as string).toLowerCase() === (value2 as string).toLowerCase()

  return value1 === value2
}

function tabClick(payload: TabItem['value']) {
  emits('tabClick', payload)
  emits('update:modelValue', payload)
}
</script>
<template>
  <div flex>
    <div
      v-for="(item, idx) in props.data"
      :key="idx"
      mr-6
      pb-1
      cursor-pointer
      hover:color="$primary"
      :class="
        getActiveTab(props.modelValue, item.value)
          ? `color-$primary border-b-2 border-current active-tab ${props.tabClass} ${props.activeTabClass}`
          : props.tabClass
      "
      @click="tabClick(item.value)"
    >
      {{ item.name }}
    </div>
  </div>
</template>
