import type { I18n, I18nOptions } from 'vue-i18n'
import { createApp } from 'vue'
import { ElLoading } from 'element-plus'
import { BarChart, LineChart, PieChart } from 've-charts'
import { use } from 'echarts'
import { LegendComponent, TooltipComponent } from 'echarts/components'
import PiniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import { createPinia } from 'pinia'
import { createI18n } from 'vue-i18n'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { Swiper, SwiperSlide } from 'swiper/vue'
import VueGridLayout from 'vue3-drr-grid-layout'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import BigNumber from 'bignumber.js'
import * as Sentry from '@sentry/vue'
import { HttpClient } from '@sentry/integrations'
import FingerPrint from '@fingerprintjs/fingerprintjs'
import LuckyCanvas from '@lucky-canvas/vue'

import 'intro.js/introjs.css'
import 'vue3-drr-grid-layout/dist/style.css'
import '@unocss/reset/tailwind.css'
import '~/assets/css/main.css'
import '~/assets/css/icon.scss'
import '~/assets/css/element-overwrite.scss'
import 'uno.css'

// import 'element-plus/theme-chalk/src/index.scss'
import 'element-plus/theme-chalk/src/select-v2.scss'
import 'element-plus/theme-chalk/src/table.scss'
import 'element-plus/theme-chalk/src/loading.scss'
import 'element-plus/theme-chalk/src/notification.scss'
import 'element-plus/theme-chalk/src/alert.scss'
import 'element-plus/theme-chalk/src/message.scss'
import 'element-plus/theme-chalk/src/message-box.scss'
import 'element-plus/theme-chalk/src/overlay.scss'
import 'element-plus/theme-chalk/src/form.scss'
import 'element-plus/theme-chalk/dark/css-vars.css'

import 'swiper/css'

import {
  getBasicLangList,
  getUserSetting,
  setUserSetting
} from './api'

import App from './App.vue'
import router from './routeGuard'

import { numberInput } from '~/directives/numberInput'
import PiniaPluginDebounce from '~/libs/piniaPlugins/piniaDebounce'
import Cookie from '~/utils/cookie'
import type { UserCommonConfig } from '~/types'
import type { JSONString } from '~/types/utils'
import { FINGER_PRINT_KEY } from '~/config/const'

BigNumber.config({
  EXPONENTIAL_AT: [-50, 50]
})
dayjs.extend(timezone)
dayjs.extend(utc)

gsap.registerPlugin(ScrollTrigger)

const pinia = createPinia()
pinia.use(PiniaPluginDebounce).use(PiniaPluginPersistedstate)

async function beforeInit() {
  try {
    const FP = await FingerPrint.load()
    const res = await FP.get()

    const fingerPrint = useStorage(FINGER_PRINT_KEY, res.visitorId)
    fingerPrint.value = res.visitorId
  }
  catch (error) {

  }

  const { res } = await getBasicLangList()
  window.WEB_CONFIG.supportLanguages = res?.data ?? []
  let commonConfig: Partial<UserCommonConfig> = {}

  // 提前获取配置覆盖本地存储
  if (Cookie.get('account_id')) {
    const { res } = await getUserSetting()
    commonConfig = JSON.parse((res?.data?.commonConfig as unknown as string) || '{}') as unknown as UserCommonConfig

    localStorage.lang = commonConfig.lang || localStorage.lang
  }

  // 如果当前语言不在列表，设置为英文
  const isInLangList = res?.data.find(item => item.lang === localStorage.lang)

  if (!isInLangList)
    localStorage.lang = 'en-us'

  if ((!isInLangList) && Cookie.get('account_id')) {
  // 登录了而且语言或者汇率对应得语言不在列表内，此时需要调用接口修改用户的设置

    const obj = {
      lang: '',
      unit: ''
    }
    if (!isInLangList)
      obj.lang = 'en-us'

    await setUserSetting({
      common_config: JSON.stringify({
        ...commonConfig,
        ...obj
      }) as JSONString<UserCommonConfig>
    })
  }

  localStorage.lang && setLanguage(localStorage.lang)
  if (!localStorage.lang || !Cookie.get('locale'))
    setLanguage('en-us')
  loadLanguages(localStorage.lang || 'en-us')
}

let count = 0 // 语言包读取数量
let jsLoadUrls: string[] = []

beforeInit()

function loadLanguages(lang: string) {
  window.WEB_CONFIG.supportLanguages.forEach((el) => {
    if (el.lang === lang)
      jsLoadUrls = el.jsLoadUrls || []
  })

  jsLoadUrls.forEach((el) => {
    (function (el) {
      const script = document.createElement('script')
      script.onload = function () {
        count++

        initApp()
      }
      script.src = el
      window.document.querySelector('head')?.appendChild(script)
    })(el)
  })
  initApp()
}

export function setLanguage(locale: string, i18n?: I18n) {
  localStorage.lang = locale
  Cookie.set(
    'locale',
    locale,
    10000000000,
    location.hostname.replace(`${location.hostname.split('.').shift()}.`, '')
  )
  i18n && (i18n.global.locale = locale)
}

function initApp() {
  if (count !== jsLoadUrls.length)
    return
  const i18nConfig: I18nOptions = {
    globalInjection: true,
    legacy: false,
    locale: localStorage.lang || 'en-us',
    fallbackLocale: 'en-us',
    formatFallbackMessages: true,
    messages: {
      [localStorage.lang || 'en-us']: Object.assign(
        window.WEB_LOCALES ?? {},
        window.WEB_LOCALES_USER ?? {}
      )
    },
    missing() {
      return ''
    },
    silentTranslationWarn: true,
    silentFallbackWarn: true
  }

  if (localStorage.showMissingKeys)
    delete i18nConfig.missing

  const i18n = createI18n(i18nConfig)

  const app = createApp(App)

  // if (+import.meta.env.VITE_OPEN_SENTRY) {
  //   Sentry.init({
  //     app,
  //     dsn: import.meta.env.VITE_SENTRY_URL,
  //     sendDefaultPii: true,
  //     environment: location.hostname,
  //     replaysSessionSampleRate: 0.1,
  //     replaysOnErrorSampleRate: 0.2,
  //     tracesSampleRate: 0.1,
  //     profilesSampleRate: 1.0,
  //     integrations: [
  //       new Sentry.BrowserTracing({
  //         routingInstrumentation: Sentry.vueRouterInstrumentation(router)
  //       }),
  //       new Sentry.BrowserProfilingIntegration(),
  //       new HttpClient({
  //         failedRequestStatusCodes: [400, 599]
  //       }) as Sentry.BrowserProfilingIntegration
  //     // new Sentry.Replay({
  //     //   maxReplayDuration: 60 * 1000,
  //     //   maskAllText: false,
  //     //   maskAllInputs: false,
  //     //   sessionSampleRate: 0,
  //     //   errorSampleRate: 0.2,
  //     // })
  //     ]
  //   })
  // }

  use([TooltipComponent as unknown as any, LegendComponent as unknown as any])
  app.directive('number-input', numberInput)

  app
    .component(BarChart.name, BarChart)
    .component(LineChart.name, LineChart)
    .component(PieChart.name, PieChart)
    .component(Swiper.name!, Swiper)
    .component(SwiperSlide.name!, SwiperSlide)

  app
    .use(pinia)
    .use(i18n)
    .use(router)
    .use(ElLoading)
    .use(VueGridLayout)
    .use(LuckyCanvas)
    .mount('#app')
}
