import { useFutureSymbol } from './useFutureSymbol'
import type { QuoteWS } from '~/types/ws'
import { getQuoteIndexKline, getQuoteMarkKline } from '~/api'
import { useFutureStore } from '~/store/future'
import type { ResolutionValues } from '~/types/enums'

export function useFutureIndexData() {
  const { setTickerInfo } = useFutureSymbol()
  const futureStore = useFutureStore()

  const indexDataHandler = (res: QuoteWS.KlineData) => {
    const { visibleSymbolMapByIndexToken } = $(storeToRefs(futureStore))

    if (res.topic.includes('indexKline')) {
      const data = res.data[0]
      const symbolInfo = visibleSymbolMapByIndexToken[res.symbol!]

      if (!symbolInfo)
        return

      const klineLastBar = {
        time: data.t,
        open: +data.o,
        high: +data.h,
        low: +data.l,
        close: +data.c,
        volume: +data.v
      }
      setTickerInfo({
        [symbolInfo.symbolId]: {
          indexPrice: data.c,
          indexLastBar: klineLastBar
        }
      })

      return klineLastBar
    }
  }

  const indexHttpAction = async (
    symbolId: string,
    resolution: ResolutionValues
  ) => {
    const { getSymbolInfoBySymbolId } = futureStore
    const symbolInfo = getSymbolInfoBySymbolId(symbolId)

    if (!symbolInfo?.baseTokenFutures?.indexToken)
      return

    const { res, err } = await getQuoteIndexKline({
      interval: resolution,
      symbol: symbolInfo.baseTokenFutures.indexToken,
      limit: 1
    })

    if (err)
      return

    const data = res.data[0]
    const klineLastBar = {
      time: data.t,
      open: +data.o,
      high: +data.h,
      low: +data.l,
      close: +data.c,
      volume: +data.v
    }
    setTickerInfo({
      [symbolId]: {
        indexPrice: data.c,
        indexLastBar: klineLastBar
      }
    })

    return klineLastBar
  }

  const markDataHandler = (res: QuoteWS.KlineData) => {
    const { visibleSymbolMapByIndexToken } = $(storeToRefs(futureStore))

    if (res.topic.includes('markKline')) {
      const data = res.data[0]
      const symbolInfo = visibleSymbolMapByIndexToken[res.symbol!]

      if (!symbolInfo)
        return

      setTickerInfo({
        [symbolInfo.symbolId]: {
          markPrice: data.c
        }
      })
      const klineLastBar = {
        time: data.t,
        open: +data.o,
        high: +data.h,
        low: +data.l,
        close: +data.c,
        volume: +data.v
      }

      return klineLastBar
    }
  }

  const markHttpAction = async (
    symbolId: string,
    resolution: ResolutionValues
  ) => {
    const { getSymbolInfoBySymbolId } = futureStore
    const symbolInfo = getSymbolInfoBySymbolId(symbolId)

    if (!symbolInfo?.baseTokenFutures?.indexToken)
      return

    const { res, err } = await getQuoteMarkKline({
      interval: resolution,
      symbol: symbolInfo.baseTokenFutures.indexToken,
      limit: 1
    })

    if (err)
      return

    const data = res.data[0]

    setTickerInfo({
      [symbolInfo.symbolId]: {
        markPrice: data.c
      }
    })

    const klineLastBar = {
      time: data.t,
      open: +data.o,
      high: +data.h,
      low: +data.l,
      close: +data.c,
      volume: +data.v
    }
    return klineLastBar
  }

  return {
    indexDataHandler,
    indexHttpAction,
    markDataHandler,
    markHttpAction
  }
}
