// 存放频率和数量相关的数值
export const FUTURE_TRADE_UPDATE_INTERVAL = 100 // 合约历史交易数据更新间隔
export const FUTURE_TRADE_MAX_COUNT = 200 // 合约历史交易数据最大数量
export const SPOT_TRADE_UPDATE_INTERVAL = 100 // 现货历史交易数据更新间隔
export const SPOT_TRADE_MAX_COUNT = 200 // 现货历史交易数据最大数量
export const QUOTE_HTTP_POLLING_INTERVAL = 3000 // 行情数据http轮询间隔
export const ORDER_HTTP_POLLING_INTERVAL = 10000 // 订单数据http轮询间隔
export const DEPTH_CHART_LIMIT = 100 // 深度图数据最大数量
export const HANDICAP_LIMIT = 6 // 盘口最大展示数量
export const KLINE_GET_LIMIT = 1000 // k线数据接口数量limit
