import BigNumber from 'bignumber.js'
import { intToPercent, saveDigital } from './utils'
import { FutureCrncyType, FutureOrderSide, FutureOrderType, FuturePositionSide, OrderSide } from '~/types/enums'
import { DEPTH } from '~/config/enums'
import type { FuturesSymbolWithTickerInfo } from '~/types/futures'
import type { FutureOrderSetting, RiskLimit } from '~/types'
import type { QuoteWS } from '~/types/ws'

/**
 * 根据当前的交易单位，转换成相应的数字
 * @param {string|number} value 要转换的值
 * @param {string|number} multiplier 合约乘数
 * @param {number} precision 保留的精度 默认0
 * @param {number} unit 当前的交易单位
 * @param {number|string} price 币种当前价格 unit是USDT时需要传入
 * @returns 转换后的数字
 */
export function getValueFromExchangeUnit(
  value: string | number,
  multiplier: string | number,
  precision: number | string,
  unit: FutureCrncyType,
  price?: string | number,
  roundMode?: BigNumber.RoundingMode
) {
  if (!value)
    return '0'

  // 大于1的时候保留整数位
  precision = +precision > 1 ? 0 : precision
  precision = new BigNumber(precision).toString()

  if (unit === FutureCrncyType.COUNT) {
    return `${value}`
  }
  else if (unit === FutureCrncyType.TOKEN) {
    return saveDigital(
      new BigNumber(value).multipliedBy(multiplier),
      DEPTH[precision],
      roundMode
    )
  }
  else {
    if (!price || Number.isNaN(+price))
      return '0'

    return saveDigital(
      new BigNumber(value).multipliedBy(multiplier).multipliedBy(price),
      DEPTH[precision],
      roundMode
    )
  }
}
/**
 * 币或USDT转换为张
 * @param {number|string|BigNumber} value 币的数量
 * @param {number|string} multiplier 币的合约乘数
 * @param {number} precision 要保留的小数位数 默认0
 * @param {FutureCrncyType} unit // 当前的交易单位
 * @param {number|string} price 币种当前价格 unit是USDT时需要传入
 * @returns 转换为张后的整数
 */
export function coinToCount(
  value: string | number,
  multiplier: number | string,
  precision: string | number = 0,
  unit: FutureCrncyType,
  price?: number | string
) {
  if (!value)
    return '0'

  // 大于1的时候保留整数位
  precision = +precision > 1 ? 0 : precision
  precision = new BigNumber(precision).toString()

  if (unit === FutureCrncyType.COUNT) {
    return `${value}`
  }
  else if (unit === FutureCrncyType.TOKEN) {
    return saveDigital(
      new BigNumber(value).dividedBy(multiplier),
      DEPTH[precision]
    )
  }
  else {
    // 以U计算
    if (!price || Number.isNaN(+price))
      return '0'

    return saveDigital(
      new BigNumber(value).dividedBy(multiplier).dividedBy(price),
      DEPTH[precision]
    )
  }
}

/**
 * 计算平仓收益
 * @param {number|string} positionPrice 持仓价格
 * @param {number|string} closePrice 平仓价格
 * @param {number|string} contractMultiplier 合约乘数
 * @param {number|string} count 张数
 * @param {string} isLong 是否多仓
 * @returns 2位小数的数字
 */
export function calcProfit(
  positionPrice: number | string,
  closePrice: number | string,
  contractMultiplier: number | string,
  count: number | string,
  isLong: string,
  digital = 2
) {
  if (Number.isNaN(+closePrice))
    return '0.00'

  return new BigNumber(closePrice || positionPrice)
    .minus(positionPrice)
    .multipliedBy(contractMultiplier)
    .multipliedBy(count)
    .multipliedBy(+isLong > 0 ? 1 : -1)
    .toFixed(digital, BigNumber.ROUND_DOWN)
}

/**
 * 收益率反推止盈止损价格
 * @param {number|string} avgPrice 开仓均价
 * @param {number|string} profitRate 收益率 小数
 * @param {number|string} contractMultiplier 合约乘数
 * @param {number|string} count 张数
 * @param {number|string} margin 仓位保证金
 * @param {string} isLong 是否多仓
 * @param {number} digits 保留的小数位数
 * 止盈止损价格计算公式: 开仓均价+（收益率*仓位保证金）/(仓位数量*合约乘数) * 方向(多仓1 空仓-1)
 */
export function calcStopPrice(
  avgPrice: number | string,
  profitRate: number | string,
  contractMultiplier: number | string,
  count: number | string,
  margin: number | string,
  isLong: string,
  digits: number
) {
  if (Number.isNaN(+profitRate) || !profitRate)
    return ''

  return new BigNumber(avgPrice)
    .plus(
      new BigNumber(profitRate)
        .div(100)
        .multipliedBy(margin)
        .div(
          new BigNumber(contractMultiplier)
            .multipliedBy(count)
            .multipliedBy(+isLong > 0 ? 1 : -1)
        )
    )
    .toFixed(digits, BigNumber.ROUND_DOWN)
}
function checkClosePriceLimit(floor: string, ceil: string, closePrice: string, total: string, reverseTotal: string, isLong: boolean) {
  if (isLong) {
    if (+reverseTotal >= +total)
      return floor
  }
  else {
    if (+reverseTotal >= +total)
      return ceil
  }

  if (+closePrice < +floor)
    return floor
  if (+closePrice > +ceil)
    return ceil

  return closePrice
}

export const calcCrossClosePrice = (crossPositions: QuoteWS.PositionInfo[], available: string, orderSettingMap: Record<string, FutureOrderSetting>, currentPosition: QuoteWS.PositionInfo, symbolMap: Record<string, FuturesSymbolWithTickerInfo>) => {
  const symbolInfo = symbolMap[currentPosition.symbolId]
  const orderSetting = orderSettingMap[currentPosition.symbolId]
  if (!available || !symbolInfo || !currentPosition || !orderSetting?.riskLimit)
    return '--'
  // 未实现盈亏(unrealisedPnl) 已结算未实现盈亏(settledUpnl)
  // 计算除当前币对的所有仓位PNL 当仓位的未实现盈亏 > 仓位的已结算未实现盈亏时 用未实现盈亏 - 已结算未实现盈亏
  // 当仓位的未实现盈亏 <= 仓位的已结算未实现盈亏时 如果未实现盈亏 > 0 视为0 为负数时保留原值
  const pnlSum = crossPositions.reduce((sum, position) => {
    if (position.symbolId === currentPosition.symbolId)
      return sum

    let pnl = new BigNumber(position.unrealisedPnl)
    if (pnl.gt(position.settledUpnl))
      pnl = pnl.minus(position.settledUpnl)
    else if (pnl.gt(0))
      pnl = new BigNumber(0)

    return sum.plus(pnl)
  }, new BigNumber(0))
    .toString()
  // 计算所有仓位的保证金之合
  const marginSum = crossPositions.reduce((sum, position) => {
    return sum.plus(new BigNumber(position.margin))
  }, new BigNumber(0)).toString()

  const isLong = currentPosition.isLong === FuturePositionSide.LONG
  const longPositions = crossPositions.filter(item => item.symbolId === currentPosition.symbolId && item.isLong === FuturePositionSide.LONG)
  const shortPositions = crossPositions.filter(item => item.symbolId === currentPosition.symbolId && item.isLong === FuturePositionSide.SHORT)
  const longSums = longPositions.reduce((total, position) => {
    return {
      openValue: total.openValue.plus(position.openValue),
      total: total.total.plus(position.total)
    }
  }, {
    openValue: new BigNumber(0),
    total: new BigNumber(0)
  })
  const shortSums = shortPositions.reduce((total, position) => {
    return {
      openValue: total.openValue.plus(position.openValue),
      total: total.total.plus(position.total)
    }
  }, {
    openValue: new BigNumber(0),
    total: new BigNumber(0)
  })
  const { contractMultiplier } = symbolInfo.baseTokenFutures

  // 计算所有仓位净值之和 仓位净值=仓位total * 维持保证金率 * 合约价格futureRefPrice * 合约乘数contractMultiplier
  const netSum = crossPositions.reduce((total, position) => {
    const isLong = position.isLong === FuturePositionSide.LONG
    const orderSide = isLong ? FutureOrderType.BUY_OPEN : FutureOrderType.SELL_OPEN
    const riskLimit = orderSettingMap[position.symbolId].riskLimit.find((item) => {
      return item.side === orderSide
    }) ?? {} as RiskLimit
    const contractMultiplier = symbolMap[position.symbolId].baseTokenFutures.contractMultiplier
    const netNum = new BigNumber(position.total).multipliedBy(riskLimit?.maintainMargin ?? 0).multipliedBy(position.futureRefPrice).multipliedBy(contractMultiplier)

    return total.plus(netNum)
  }, new BigNumber(0)).toString()
  // 计算爆仓价格 全仓资产的(realAvailable + marginSum + pnlSum - longOpenValue + shortOpenValue - netSum) / ((shortTotal - longTotal) * contractMultiplier)
  const numberator = new BigNumber(available).plus(marginSum).plus(pnlSum).minus(longSums.openValue).plus(shortSums.openValue).minus(netSum)
  const denominator = shortSums.total.minus(longSums.total).multipliedBy(contractMultiplier)
  const { liquidationPriceMin, liquidationPriceMax } = symbolInfo
  const closePrice = saveDigital(numberator.div(denominator).toString(), DEPTH[symbolInfo.minPricePrecision] || 8)

  const total = isLong ? longSums.total.toString() : shortSums.total.toString()
  const reverseTotal = isLong ? shortSums.total.toString() : longSums.total.toString()
  // 检查上下限
  return checkClosePriceLimit(liquidationPriceMin, liquidationPriceMax, closePrice, total, reverseTotal, isLong)
}

/**
 * 下单时,预估强平价
 * 开仓价值 x =  (反向合约 ? 1/开仓价格 : 开仓价格) * 合约乘数 * 开仓手数
 * 起始保证金= cost
 * 正向合约
 * 开多：（开仓价值  - 起始保证金）/ （(1 - 维持保证金率）* 合约乘数 * 开仓手数）
 * 开空：（开仓价值  + 起始保证金）/ （(1 + 维持保证金率）* 合约乘数 * 开仓手数）
 * 反向合约
 * 开多：（(1 + 维持保证金率）* 合约乘数 * 开仓手数）/（开仓价值  + 起始保证金）
 * 开空：（(1 - 维持保证金率）* 合约乘数 * 开仓手数） / （开仓价值  - 起始保证金)
 */
export const closePrice = (
  orderSide: FutureOrderType,
  symbolInfo: FuturesSymbolWithTickerInfo,
  orderSetting: FutureOrderSetting,
  quantity: string,
  crncyType: FutureCrncyType,
  price: string,
  cost: string
) => {
  const orderMakerType = orderSide.includes('BUY')
    ? OrderSide.BUY
    : OrderSide.SELL // 买卖
  const orderChoose = orderSide.includes('OPEN')
    ? FutureOrderSide.OPEN
    : FutureOrderSide.CLOSE // 开仓，平仓
  if (orderChoose === FutureOrderSide.CLOSE)
    return ''

  let p = ''

  const riskLimit = orderSetting?.riskLimit?.find((item) => {
    return item.side === orderSide
  })

  if (!riskLimit)
    return ''

  const contractMultiplier = symbolInfo.baseTokenFutures.contractMultiplier
  const countQuantity = coinToCount(
    quantity,
    contractMultiplier,
    +contractMultiplier,
    crncyType,
    symbolInfo.tickerInfo.close
  )

  if (
    symbolInfo
    && orderMakerType === OrderSide.BUY
    && countQuantity
    && riskLimit?.maintainMargin
  ) {
    if (!price)
      return ''
    // 反向合约
    // 开多： （(1 + 维持保证金率）* 合约乘数 * 开仓手数）/（开仓价值  + 起始保证金）
    if (symbolInfo.isReverse) {
      price = `${1 / +price}`
      p = `${
        ((1 + Number(riskLimit.maintainMargin))
          * +symbolInfo.baseTokenFutures.contractMultiplier
          * +countQuantity)
        / (+price
          * +symbolInfo.baseTokenFutures.contractMultiplier
          * Number(countQuantity)
          + Number(cost))
      }`
    }
    else {
      // 正向合约
      // 开多： 开仓价值  - 起始保证金）/ （(1 - 维持保证金率）* 合约乘数 * 开仓手数）
      p = `${
        (+price
          * +symbolInfo.baseTokenFutures.contractMultiplier
          * +countQuantity
          - +cost)
        / ((1 - +riskLimit.maintainMargin)
          * +symbolInfo.baseTokenFutures.contractMultiplier
          * +countQuantity)
      }`
    }
  }
  if (
    symbolInfo
    && orderMakerType === OrderSide.SELL
    && countQuantity
    && riskLimit?.maintainMargin
  ) {
    // 反向合约
    // 开空：（(1 - 维持保证金率）* 合约乘数 * 开仓手数） / （开仓价值  - 起始保证金)
    if (symbolInfo.isReverse) {
      price = `${1 / +price}`
      p = `${
        ((1 - +riskLimit.maintainMargin)
          * +symbolInfo.baseTokenFutures.contractMultiplier
          * +countQuantity)
        / (+price
          * +symbolInfo.baseTokenFutures.contractMultiplier
          * +countQuantity
          - +cost)
      }`
    }
    else {
      // 正向合约
      // 开空：（开仓价值  + 起始保证金）/ （(1 + 维持保证金率）* 合约乘数 * 开仓手数）
      p = `${
        (+price
          * +symbolInfo.baseTokenFutures.contractMultiplier
          * Number(countQuantity)
          + Number(cost))
        / ((1 + Number(riskLimit.maintainMargin))
          * +symbolInfo.baseTokenFutures.contractMultiplier
          * +countQuantity)
      }`
    }
  }
  if (+p > 0) {
    if (`${p}`.includes('e'))
      p = new BigNumber(p).toString()

    p = saveDigital(p, DEPTH[symbolInfo.minPricePrecision] || 8)
  }
  if (+p <= 0)
    p = '0'

  return p
}
/**
 * 将值为--的占位符转成0
 * @param holder 占位字符串
 * @param replacer 替换字符串
 * @default '0'
 * @returns
 */
export const replacePlaceholder = (holder: string | number, replacer = '0') => {
  if (holder === '--')
    return replacer

  return holder
}

/**
 * 通过收益计算收益率
 */

export const calcProfitRateByProfit = (
  profit: string,
  margin: string
) => {
  if (!profit || !margin)
    return '--'

  const ret = new BigNumber(profit)
    .dividedBy(
      margin
    )
    .toString()

  return intToPercent(ret, 2, BigNumber.ROUND_HALF_CEIL)
}
