<script setup lang="ts">import { toRef as _toRef, computed as _computed } from 'vue';

import type { Component } from 'vue'
import Banner from './components/banner.vue'
import Download from './components/download.vue'
import Help from './components/help.vue'
import introduction from './components/introduction.vue'
import Market from './components/market.vue'
import Slogan from './components/slogan.vue'
// import Custom from './components/custom.vue'
import GlobalAdDialog from './components/globalAd.vue'
import VideoDesc from './components/videoDesc.vue'
import Partner from '~/pages/components/partner.vue'
import { useConfigStore } from '~/store/config'
import { NewIndexConfig } from '~/types'
import type { Nullable } from '~/types/utils'

const configStore = useConfigStore()
const __$temp_1 = (storeToRefs(configStore)),
  indexPageConfig = _toRef(__$temp_1, 'indexPageConfig');
const componentMap: Record<NewIndexConfig.ModuleType, Nullable<Component>> = {
  [NewIndexConfig.ModuleType.HEADER]: Slogan,
  [NewIndexConfig.ModuleType.REGISTER]: null,
  [NewIndexConfig.ModuleType.BANNER]: Banner,
  [NewIndexConfig.ModuleType.MARKET]: Market,
  [NewIndexConfig.ModuleType.ADVANTAGE]: introduction,
  [NewIndexConfig.ModuleType.HELP]: Help,
  [NewIndexConfig.ModuleType.DOWNLOAD]: Download,
  [NewIndexConfig.ModuleType.CUSTOM1]: null,
  [NewIndexConfig.ModuleType.CUSTOM2]: null,
  [NewIndexConfig.ModuleType.CUSTOM3]: null,
  [NewIndexConfig.ModuleType.VIDEO]: VideoDesc,
  [NewIndexConfig.ModuleType.PARTNER]: Partner
}
const componentList = _computed(() => {
  return indexPageConfig.value?.filter(item => componentMap[item.type])
})
</script>

<template>
  <div
    flex
    flex-col
  >
    <component
      :is="componentMap[item.type]"
      v-for="item in componentList"
      :key="item.type"
      :config="item"
      flex
      flex-col
      :style="{
        background:
          item?.contentList?.[0]?.content?.background || 'var(--ex-background)',
        order: item.sort,
      }"
    />
  </div>
  <div class="global-ad-wrapper">
    <global-ad-dialog style="z-index: 9998 !important" />
  </div>
</template>

<style lang="scss" scoped>
.global-ad-wrapper {
  :deep(.el-overlay) {
    z-index: 9998 !important;
  }
}

:deep(){
  *{
    font-family: 'mo-bold';
  }
}
</style>
